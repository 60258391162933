<template>
  <div class="light-mode revisable-ticket" v-if="revisable.id" :class="{ '--closed': closed }" :style="{ maxHeight }">
    <div class="panel-wrapper">
      <div class="panel-image">
        <router-link v-if="!relatedCard" class="tag" rel="nofollow" :to="toTag(relatedTag)">
          <svg><use xlink:href="#icons-tag"></use></svg>
        </router-link>
        <router-link v-else-if="revisable.foreignKey === 'illustrationId'" class="artwork" rel="nofollow" :to="toCard(relatedCard)">
          <img :src="relatedCard.artImageUrl" :alt="relatedCard.name">
        </router-link>
        <router-link v-else class="card" rel="nofollow" :to="toCard(relatedCard)">
          <img :src="relatedCard.cardImageUrl" :alt="relatedCard.name">
        </router-link>
      </div>
      <div class="panel-body">
        <revisable-header :revisableId="revisableId" :revisableType="revisableType" :author="true" context="revision"/>
        <revisable-review :revisableId="revisableId" :revisableType="revisableType" :revision="revision" @close="close"/>
      </div>
      <button class="button-close" type="button" @click.prevent="close('SKIP')">
        <svg aria-hidden="true"><use xlink:href="#icons-close"></use></svg>
        <span class="vh">Close</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Revisable from '../mixins/revisable';
import RevisableHeader from './revisable-header.vue';
import RevisableReview from './revisable-review.vue';

export default {
  mixins: [Revisable],
  components: {
    RevisableHeader,
    RevisableReview,
  },
  props: {
    revision: { type: Object, required: true },
  },
  emits: ['closed'],
  data() {
    return {
      closed: false,
      maxHeight: null,
    };
  },
  watch: {
    revisable(value) {
      // Record may be deleted while ticket is visible
      if (!value.id) {
        this.$emit('closed', this.revision.id);
      }
    }
  },
  methods: {
    close(action) {
      this.maxHeight = (this.$el.getBoundingClientRect().height + 200) + 'px';
      const skipped = (action === 'SKIP');
      setTimeout(() => {
        this.maxHeight = 0;
        this.closed = true;
        setTimeout(() => this.$emit('closed', this.revision.id, skipped), 550);
      }, skipped ? 10 : 750);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.revisable-ticket {
  background-color: white;
  border-radius: $box-corner;
  margin: spacing(4) 0;
  opacity: 1;
  overflow-y: hidden;
  transition: max-height 0.5s, margin 0.5s, opacity 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  &.--closed {
    margin-bottom: -#{spacing(4)};
    opacity: 0.5;
  }
}

.panel-wrapper {
  box-sizing: border-box;
  padding: spacing(3);
  position: relative;

  @media (min-width: $break-small) {
    display: grid;
    grid-gap: spacing(3);
    grid-template-columns: 100px auto;
  }

  @media (min-width: $break-medium) {
    display: grid;
    grid-gap: spacing(3);
    grid-template-columns: 200px auto;
  }
}

.panel-image {
  margin: 0 auto spacing(3);
  max-width: 150px;

  @media (min-width: $break-small) {
    margin: 0;
    max-width: none;
  }

  .tag {
    align-items: center;
    background-color: $c-gray-200;
    display: flex;
    justify-content: center;
    padding: spacing(5) 0;

    svg {
      fill: $c-gray-500;
      max-height: 50px;
      max-width: 50px;
    }
  }
}
</style>
