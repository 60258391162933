<template>
  <div id="app">
    <app-background/>
    <app-header/>
    <router-view :key="$route.path" class="app-viewport app-wrapper"/>
    <app-footer/>
    <app-notice/>
    <modal-dialog/>
    <hover-card/>
    <router-link class="admin-dash" :to="{ name: 'admin-dashboard' }" v-if="isAdmin">
      <svg aria-hidden="true" class="label-icon">
        <use xlink:href="#icons-settings"></use>
      </svg>
    </router-link>
  </div>
</template>

<script>
import pubsub from 'tiny-emitter/instance';
import { SET_BREAKOUT } from './store/commands';
import AppBackground from './components/app/app-background.vue';
import AppHeader from './components/app/app-header.vue';
import AppFooter from './components/app/app-footer.vue';
import AppNotice from './components/app/app-notice.vue';
import ModalDialog from './components/modal/modal-dialog.vue';
import HoverCard from './components/app/hover-card.vue';

export default {
  components: {
    AppBackground,
    AppHeader,
    AppFooter,
    AppNotice,
    ModalDialog,
    HoverCard,
  },
  computed: {
    isAdmin() {
      const g = this.$store.getters;
      return g.mayManageEdges || g.mayManageTags || g.mayManageUsers;
    }
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      // do nothing for fragment-only changes
      if (from && from.fullPath.split('#')[0] === to.fullPath.split('#')[0]) return;

      // cancel any existing breakout dialogs without callback events
      if (this.$store.state.currentBreakout) {
        this.$store.commit(SET_BREAKOUT, { cancel: true });
      }

      // clear the search bar
      pubsub.emit('search', '');
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/vars';

.app-viewport {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: $header-height;
}

.admin-dash {
  background-color: $cGrape;
  bottom: spacing(3);
  border-radius: $box-corner-sm;
  color: rgba(255, 255, 255, 0.3);
  display: block;
  height: 30px;
  right: spacing(3);
  position: fixed;
  transition: color 0.25s;
  width: 30px;

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }

  svg {
    box-sizing: border-box;
    fill: currentColor;
    height: 100%;
    padding: 6px;
    width: 100%;
  }
}
</style>
