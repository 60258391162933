<template>
  <div class="revisable-review" :class="{ child: !!revision.parentId }">
    <figure>
      <figcaption v-if="reporter.username">
        <tagging-icon :code="resolutionIcon"/>Reported {{ relativeTime(revision.createdAt) }}
        <span>by <router-link rel="nofollow" :to="toUser(reporter)">{{ reporter.username }}</router-link></span>
        <template v-if="reviewer">, {{ resolution }} {{ relativeTime(revision.updatedAt) }}
          <span>by <router-link rel="nofollow" :to="toUser(reviewer)">{{ reviewer.username }}</router-link></span>
          <span v-if="revision.reviewerNote">: "{{ revision.reviewerNote }}"</span>
        </template>
      </figcaption>
      <blockquote>
        <header>
          <tagging-icon :code="resolutionIcon" v-if="!reporter.username"/>
          <template v-if="revision.mutationName == 'alias'">
            Merge tag into:
            <tagging-icon :code="revision.targetTag.namespace"/>
            <router-link rel="nofollow" :to="toTag(revision.targetTag)">{{ revision.targetTag.name }}</router-link>
          </template>
          <template v-else>
            Change <em>{{ revision.mutationName }}</em> to:
            <strong class="admin-ref" :class="valueClasses" v-if="revision.mutationName == 'status'">{{ revision.mutationValue }}</strong>
          </template>
        </header>
        <q>{{ revision.reporterNote || revision.mutationValue }}</q>
      </blockquote>
    </figure>
    <form v-if="!isClosed && mayReview" @submit.prevent="submit">
      <div v-if="!reviewIntent" class="tag-form-row">
        <button class="button-action" type="button" @click.prevent="reviewIntent = 'accept'">{{ buttonIntents.accept.label }}</button>
        <button class="button-action" type="button" @click.prevent="reviewIntent = 'reject'">{{ buttonIntents.reject.label }}</button>
        <button class="button-action" type="button" @click.prevent="reviewIntent = 'admin'" v-if="maySendToAdmin">{{ buttonIntents.admin.label }}</button>
      </div>
      <template v-else>
        <div class="tag-form-row">
          <label>
            Reviewer note
            <input v-model="reviewerNote" class="tag-input-field"/>
          </label>
        </div>
        <div class="tag-form-row">
          <button class="button-action button-primary" type="submit">{{ buttonIntents[reviewIntent].label }}</button>
          <button class="button-action" type="button" @click.prevent="reviewIntent = null">Cancel</button>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Revisable from '../mixins/revisable';
import relativeTime from '../../lib/relative_time';
import { sluggify } from '../../lib/utils';
import { TAG_MERGE } from '../../lib/strings';

const buttonIntents = {
  accept: { label: 'Accept change', action: 'ACCEPT_CHANGE' },
  reject: { label: 'Dismiss change', action: 'REJECT_CHANGE' },
  admin: { label: 'Send to moderator', action: 'REQUEST_ADMIN_REVIEW' },
};

export default {
  mixins: [Revisable],
  props: {
    revision: { type: Object, required: true },
  },
  emits: ['close'],
  data() {
    return {
      buttonIntents,
      reviewerNote: '',
      reviewIntent: null,
    };
  },
  computed: {
    ...mapGetters([
      'mayManageTags',
      'mayManageEdges',
    ]),
    reporter() {
      return this.$store.state.userModels[this.revision.reporterId];
    },
    reviewer() {
      return this.$store.state.userModels[this.revision.reviewerId];
    },
    isClosed() {
      return this.reviewer || this.revision.status === 'CLOSED';
    },
    mayReview() {
      if (this.isTag && this.mayManageTags) return true;
      if (!this.isTag && this.mayManageEdges) return true;
      return this.revision.status === 'PEER_REVIEW' && this.revision.reporterId !== this.$store.state.user.id;
    },
    maySendToAdmin() {
      if (this.isTag && this.mayManageTags) return false;
      if (!this.isTag && this.mayManageEdges) return false;
      return true;
    },
    resolution() {
      return this.revision.changeWasAccepted ? 'accepted' : 'dismissed';
    },
    resolutionIcon() {
      return this.isClosed ? this.resolution : 'review';
    },
    valueClasses() {
      const classes = [this.revision.mutationName];
      if (classes[0] === 'status' && !this.isClosed) {
        classes.push(sluggify(this.revision.mutationValue));
      }
      return classes;
    },
  },
  methods: {
    relativeTime,
    ...mapActions([
      'resolveRevision',
    ]),
    submit() {
      if (!this.reviewIntent) return;
      if (this.revision.mutationName === 'alias' && !confirm(TAG_MERGE)) return;

      this.resolveRevision({
        id: this.revision.id,
        input: {
          currentStatus: this.revision.status,
          reviewerNote: this.reviewerNote,
          resolution: buttonIntents[this.reviewIntent].action,
        },
      }).then(() => this.$emit('close'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.revisable-review {
  background-color: $c-gray-200;
  border: 1px solid $c-gray-300;
  border-bottom-left-radius: $box-corner-sm;
  border-bottom-right-radius: $box-corner-sm;
  margin: 0;
  padding: spacing(3);

  + .revisable-review {
    margin-top: spacing(3);
  }
}

figure {
  margin: 0;
  margin-inline: 0;
}

blockquote {
  margin: 0;

  header {
    font-size: 1.1em;
  }

  em {
    font-style: normal;
    font-family: monospace;
  }
}

q {
  border-left: 3px solid $c-gray-400;
  display: block;
  font-style: italic;
  margin: spacing(3) 0 0;
  padding-left: spacing(2);
}

figcaption {
  font-size: 0.9em;
  margin: 0 0 spacing(3);
}

.admin-ref {
  background-color: rgba(255, 255, 255, 0.75);

  &.status {
    text-transform: uppercase;
  }
}

.tagging-icon {
  display: inline-block;
  position: relative;
  top: 0.125em;
}
</style>
