<template>
  <div class="tagging-submit-form">
    <label class="tagging-submit-label" :for="inputId">
      {{ String(infoPrompt).toUpperCase() }}
      <guide-link :slug="infoType"/>
    </label>
    <form class="divided-input-field tagging-submit" @submit.prevent="onSubmit" role="group" :aria-labelledby="subjectId + '-form-label'">
      <div :id="subjectId + '-form-label'" class="vh">Submit new {{subjectDomain.namespace}} tag</div>
      <label class="tag-input-field-label">
        <span class="vh">Tag or relationship type</span>
        <select v-show="!hasChoices" class="tag-input-field" v-model="operation">
          <option v-for="opt, value in currentOperations" :key="value" :value="value">{{ opt.label }}</option>
        </select>
      </label>
      <suggestion-input ref="input"
        v-show="!hasChoices"
        :id="inputId"
        :type="suggestType"
        :clearable="true"
        :descriptions="true"
        :taggableOnly="true"
        :focusEvent="`show-${subjectDomain.namespace}`"
        @submit="onSubmit"
        @input="reset"
      />
      <div v-if="hasChoices" class="tag-input-field fieldset">
        <label v-for="opt, index in choices">
          <input type="radio" :name="`${ subjectDomain.namespace }-choices`" :value="index" v-model.number="choicesIndex"/>
          {{ opt.tagName }}
        </label>
        <button class="tag-input-clear" type="button" tabindex="-1" @click.prevent="onCancelChoices">
          <svg aria-hidden="true"><use xlink:href="#icons-close"></use></svg>
        </button>
      </div>
      <button class="tag-input-field" ref="submit" type="submit">
        <svg v-show="!busy"><use xlink:href="#icons-submit"></use></svg>
        <div v-show="busy" class="hourglass">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
        </div>
        <span class="vh">Submit tagging</span>
      </button>
    </form>
    <p v-if="message">{{ message }}
      <button v-if="followup" class="button-action" type="button" @click.prevent="onFollowup">add info</button>
    </p>
  </div>
</template>

<script>
import pubsub from 'tiny-emitter/instance';
import { mapActions } from 'vuex';
import { debounce, uid } from '../../lib/utils';
import SubjectDomain from '../../lib/subject_domain';
import Undo from '../../lib/undo';
import SuggestionInput from '../ui/suggestion-input.vue';

const artworkRels = 'artwork-relationships';
const cardRels = 'card-relationships';

const operationsBySubjectType = {
  ILLUSTRATION: {
    TAGGING:       { label: 'Tag as', suggest: 'ILLUSTRATION_TAG', info: 'artwork-tags' },
    DEPICTS:       { label: 'Depicts', suggest: 'ARTWORK', info: artworkRels },
    DEPICTED_IN:   { label: 'Depicted in', suggest: 'ARTWORK', info: artworkRels },
    COMES_BEFORE:  { label: 'Seen before', suggest: 'ARTWORK', info: artworkRels },
    COMES_AFTER:   { label: 'Seen after', suggest: 'ARTWORK', info: artworkRels },
  },
  ORACLE_CARD: {
    TAGGING:       { label: 'Tag as', suggest: 'ORACLE_CARD_TAG', info: 'card-tags' },
    SIMILAR_TO:    { label: 'Similar to', suggest: 'CARD', info: cardRels },
    BETTER_THAN:   { label: 'Better than', suggest: 'CARD', info: cardRels },
    WORSE_THAN:    { label: 'Worse than', suggest: 'CARD', info: cardRels },
    REFERENCES_TO: { label: 'References', suggest: 'CARD', info: cardRels },
    REFERENCED_BY: { label: 'Referenced by', suggest: 'CARD', info: cardRels },
    COLORSHIFTED:  { label: 'Colorshifted', suggest: 'CARD', info: cardRels },
    MIRRORS:       { label: 'Mirrors', suggest: 'CARD', info: cardRels },
    WITH_BODY:     { label: 'With creature body', suggest: 'CARD', info: cardRels },
    WITHOUT_BODY:  { label: 'Without creature body', suggest: 'CARD', info: cardRels },
  },
  PRINTING: {
    TAGGING:       { label: 'Tag as', suggest: 'PRINTING_TAG', info: 'print-tags' },
  }
};

export default {
  components: {
    SuggestionInput
  },
  props: {
    subjectType: { type: String, required: true, validator: (val) => !!operationsBySubjectType[val] },
    subjectId:   { type: String, required: true },
  },
  data() {
    return {
      artworkOptions: null,
      busy: false,
      message: null,
      operation: Object.keys(operationsBySubjectType[this.subjectType])[0],
      choices: null,
      choicesIndex: 0,
      followup: null,
      suggestType: Object.values(operationsBySubjectType[this.subjectType])[0].suggest,
      uid: uid(),
    };
  },
  watch: {
    operation() {
      this.suggestType = this.currentOperations[this.operation].suggest;
      this.reset();
    }
  },
  computed: {
    subjectDomain() {
      return new SubjectDomain(this.subjectType);
    },
    currentOperations() {
      return operationsBySubjectType[this.subjectType];
    },
    currentChoice() {
      return this.choices ? this.choices[this.choicesIndex] : null;
    },
    hasChoices() {
      return this.choices && this.choices.length;
    },
    infoType() {
      return this.currentOperations[this.operation].info;
    },
    infoPrompt() {
      return this.infoType.replace('-', ' ').replace(/s$/, '');
    },
    inputId() {
      return `tagging-input-${ this.uid }`;
    }
  },
  methods: {
    ...mapActions([
      'submitRelationship',
      'submitTagging',
      'setDialog',
    ]),

    reset(opts={}) {
      this.message = null;
      this.setFollowup(null);
      if (!opts.silent) {
        pubsub.emit('reset-submits', { silent: true });
      }
    },

    submit: debounce(function(item) {
      if (this.busy) return;

      const choice = this.currentChoice;
      if (choice) {
        return this.createTagging(choice.tagName, choice.signature, choice.timestamp);
      }

      if (!item) return;
      if (/_TAG$/.test(this.suggestType)) {
        this.createTagging(item.term);
      } else if (['ARTWORK', 'CARD'].includes(this.suggestType)) {
        this.createRelationship(item.id);
      }
    }),

    async createTagging(tagName, signature=null, timestamp=null) {
      this.busy = true;
      const data = await this.submitTagging({
        tagName,
        signature,
        timestamp,
        subjectId: this.subjectId,
        classifier: this.suggestType,
      });

      this.reset();
      this.busy = false;
      this.message = data.message;

      if (data.choices.length) {
        this.choicesIndex = data.choices.length - 1;
        this.choices = data.choices;
        this.$nextTick(() => this.$refs.submit.focus());
      } else {
        this.choices = null;
        this.$nextTick(() => this.$refs.input.update(''));
      }

      if (data.tagging) {
        this.setFollowup({ revisableId: data.tagging.id, revisableType: 'TAGGING' });
        this.$refs.input.clear();
      }
    },

    async createRelationship(relatedId) {
      this.busy = true;
      const data = await this.submitRelationship({
        relatedId,
        subjectId: this.subjectId,
        classifier: this.operation,
      });

      this.reset();
      this.busy = false;
      this.message = data.message;

      if (data.relationship) {
        this.setFollowup({ revisableId: data.relationship.id, revisableType: 'RELATIONSHIP' });
        this.$refs.input.clear();
      }
    },

    setFollowup(item) {
      this.followup = item;
      Undo.set(item ? () => {
        if (this.followup) {
          this.$store.dispatch('deleteEdge', { id: this.followup.revisableId });
          this.reset();
        }
      } : null);
    },

    onSubmit() {
      if (this.currentChoice) {
        this.submit();
      } else {
        this.$refs.input.resolve().then(this.submit);
      }
    },

    onFollowup() {
      if (this.followup) {
        this.setDialog({ as: 'edge-settings', ...this.followup });
      }
    },

    onCancelChoices() {
      this.choices = [];
    }
  },
  mounted() {
    pubsub.on('reset-submits', this.reset);
  },
  beforeUnmount() {
    pubsub.off('reset-submits', this.reset);
    Undo.set(null);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

label.tagging-submit-label {
  align-items: center;
  color: $c-gray-600;
  display: inline-flex;
  font-size: font-size(-1);
}

:deep(.tagging-submit) {
  margin-top: spacing(2);
  position: relative;

  // hack suggestion input to expand to
  // full form width at mobile sizes
  @media (max-width: $break-mobile - 1px) {
    .suggestion-input-utils {
      right: 42px;
    }
    .suggestion-input {
      position: static;
    }
  }
}

.divided-input-field.tagging-submit label.tag-input-field-label {
  width: 30%;

  @media (min-width: $card-layout-1x3) {
    max-width: 50%;
    width: calc(33% - #{spacing(2)});
  }

  @media (min-width: $card-layout-2x2) {
    width: 30%;
  }

  @media (min-width: $card-layout-2x3) {
    width: calc(33% - #{spacing(2)});
  }
}

.hourglass {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 26px;
  width: 21px;

  > div {
    animation: boxwave 1.1s infinite ease-in-out;
    background-color: $cPlum;
    display: block;
    opacity: 0.3;
    height: 100%;
    width: 5px;
  }

  .rect2 {
    animation-delay: -1.0s;
  }

  .rect3 {
    animation-delay: -0.9s;
  }
}

@keyframes boxwave {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.button-action {
  background-color: $c-gray-200;
  border: none;
  margin: 0 0 0 spacing(1);
  padding: spacing(1) spacing(2);

  &:hover {
    color: $cGrape;
  }
}
</style>
