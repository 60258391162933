<template>
  <main>
    <page-status v-if="loading || error" :error="error"/>
    <template v-else>
      <h1>{{ humanize(revisableType) }}</h1>
      <div class="light-mode card-layout">
        <div v-if="!relatedCard" class="card-layout--image">
          <router-link class="tag" rel="nofollow" :to="toTag(relatedTag)">
            <svg><use xlink:href="#icons-tag"></use></svg>
          </router-link>
        </div>
        <card-image v-else class="card-layout--image" :card="relatedCard" :show="cardImageView"/>
        <div class="card-layout--tagging">
          <revisable-header :revisableId="revisableId" :revisableType="revisableType" :author="true" context="history"/>
          <p>Status: <span class="admin-ref" :class="sluggify(revisable.status)">{{ revisable.status }}</span></p>
          <h3>Change History</h3>
          <revisable-review v-for="rev in revisions" :key="rev.id"
            :revision="rev"
            :revisableId="revisableId"
            :revisableType="revisableType"/>
          <p v-if="revisions.length < 1">There are no past or pending changes for this {{ revisableType.toLowerCase() }}.</p>
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { revisionDefault } from '../store/models/defaults';
import { humanize, sluggify } from '../lib/utils';
import SubjectDomain from '../lib/subject_domain';
import Revisable from '../components/mixins/revisable';
import RevisableHeader from '../components/ui/revisable-header.vue';
import RevisableReview from '../components/ui/revisable-review.vue';
import CardImage from '../components/card/card-image.vue';

export default {
  mixins: [Revisable],
  components: {
    RevisableHeader,
    RevisableReview,
    CardImage,
  },
  data() {
    return {
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapGetters([
      'maySubmitContent',
      'mayManageEdges',
      'mayManageTags',
    ]),
    cardImageView() {
      return new SubjectDomain(this.revisable.foreignKey).namespace;
    },
    revisions() {
      return this.$store.state.currentPage.order.map(id => this.$store.state.revisionModels[id])
        .sort((a, b) => b.createdAt - a.createdAt);
    },
  },
  methods: {
    humanize,
    sluggify,
    ...mapActions([
      'fetchRevisableHistory',
      'setBackground',
    ]),
  },
  watch: {
    revisable(value) {
      // Record may be deleted while on page
      if (!value.id) {
        this.error = 'not found';
      }
    }
  },
  mounted() {
    this.fetchRevisableHistory({
      id: this.revisableId,
      type: this.revisableType,
    })
      .then(() => {
        this.loading = false;
        this.$router.pageview();
        this.setBackground(this.relatedCard ? { url: this.relatedCard.artImageUrl, full: true } : null);
      })
      .catch(err => {
        this.error = err.message;
      });
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

@media (min-width: $card-layout-2x2) {
  .card-layout {
    align-items: flex-start;
    display: flex;
  }

  .card-layout--image {
    margin-left: spacing(4);
    order: 2;
    max-width: 575px;
    width: 45%;
  }

  .card-layout--tagging {
    flex-grow: 1;
  }
}

.card-layout--image {
  .tag {
    align-items: center;
    background-color: $c-gray-200;
    border-radius: $box-corner;
    display: flex;
    justify-content: center;
    margin: 0 auto spacing(4);
    max-width: 375px;
    padding: spacing(5) 0;

    svg {
      fill: $c-gray-500;
      max-height: 50px;
      max-width: 50px;
    }
  }
}

.card-layout--tagging {
  background-color: white;
  border-radius: $box-corner;
  padding: spacing(3);
}

.revisable-review.child {
  margin-left: spacing(4);
  position: relative;

  &:before {
    content: "↳";
    font-size: 1.25em;
    left: -16px;
    position: absolute;
    top: spacing(1) * 1.5;
    transform: translateX(-50%);
  }
}
</style>
