const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 4; // << general reporting threshold.
const YEAR = DAY * 365;

function formatRelative(quantity, label, article) {
  var unit = (quantity === 1) ? (article || 'a') : quantity;
  var plural = (quantity === 1) ? '' : 's';
  return `${ unit } ${ label }${ plural } ago`;
}

/**
* Calculates relative time.
* @param {Number|Date|String} timestamp past time to relativize.
* @returns {String} relative time string.
*/
export default function relativeTime(timestamp) {
  timestamp = new Date(timestamp).getTime();
  const now = Date.now();
  const elapsed = now - timestamp;

  if (elapsed < SECOND) {
    return 'now';
  } else if (elapsed < MINUTE) {
    return formatRelative(Math.round(elapsed / SECOND), 'second');
  } else if (elapsed < HOUR) {
    return formatRelative(Math.round(elapsed / MINUTE), 'minute');
  } else if (elapsed < DAY) {
    return formatRelative(Math.round(elapsed / HOUR), 'hour', 'an');
  } else if (elapsed < WEEK) {
    return formatRelative(Math.round(elapsed / DAY), 'day');
  }

  var pres = new Date(now);
  var past = new Date(timestamp);
  var months = (pres.getMonth() - past.getMonth()) + (pres.getFullYear() - past.getFullYear()) * 12;

  if (elapsed < MONTH || months === 0) {
    return formatRelative(Math.round(elapsed / WEEK), 'week');
  } else if (elapsed < YEAR) {
    return formatRelative(months, 'month');
  } else {
    return formatRelative(Math.round(elapsed / YEAR), 'year');
  }
}