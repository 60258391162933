import { createRouter, createWebHistory } from 'vue-router';
import CardApp from './pages/card.vue';
import CardSearchApp from './pages/search.vue';
import DashboardApp from './pages/dashboard.vue';
import HistoryApp from './pages/history.vue';
import RevisionsApp from './pages/revisions.vue';
import TagApp from './pages/tag.vue';
import UserApp from './pages/user.vue';
import PageStatus from './components/ui/page-status.vue';
import config from './lib/config';
import { pageview } from './lib/analytics';

function adminFor(name) {
  return import(/* webpackChunkName: "admin" */ './packs/admin').then(a => Promise.resolve(a.default[name]))
}

function jobsFor(name) {
  return import(/* webpackChunkName: "jobs" */ './packs/jobs').then(a => Promise.resolve(a.default[name]))
}

let savedScrollPosition;

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    name: 'dashboard',
    component: DashboardApp
  },
  {
    path: '/search',
    name: 'search',
    component: CardSearchApp,
    meta: { title: 'Search Results' }
  },
  {
    path: '/card/:set/:number/:back?',
    name: 'card',
    component: CardApp,
    props: (route) => ({
      set: route.params.set,
      number: route.params.number,
      back: !!route.params.back,
    })
  },
  {
    path: '/tags/:type/:slug',
    name: 'tag',
    component: TagApp,
    props: (route) => ({
      type: route.params.type,
      slug: route.params.slug,
    })
  },
  {
    path: '/users/:username',
    name: 'user',
    component: UserApp,
    props: (route) => ({
      username: route.params.username,
    })
  },
  {
    path: '/change-requests',
    name: 'revisions',
    component: RevisionsApp,
    meta: { title: 'Change Requests' }
  },
  {
    path: '/history/:type(tag|tagging|relationship)/:id',
    name: 'history',
    component: HistoryApp,
    meta: { title: 'History', exitOnDelete: true },
    props: (route) => ({
      revisableId: route.params.id,
      revisableType: route.params.type.toUpperCase(),
    })
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => jobsFor('Jobs'),
    meta: { title: 'Jobs', auth: ['MANAGE_JOBS'] }
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: () => jobsFor('Job'),
    meta: { title: 'Job', auth: ['MANAGE_JOBS'] },
    props: (route) => ({
      id: route.params.id,
    })
  },
  {
    path: '/jobs/new/:type',
    name: 'job-new',
    component: () => jobsFor('Job'),
    meta: { title: 'Job', auth: ['MANAGE_JOBS'] },
    props: (route) => ({
      type: route.params.type,
    })
  },
  {
    path: '/random',
    name: 'random',
    component: DashboardApp,
  },
  {
    path: '/admin/repair',
    name: 'admin-repair',
    component: () => adminFor('Repair'),
    meta: { auth: ['MANAGE_TAGGINGS'] }
  },
  {
    path: '/admin/edges',
    name: 'admin-edges',
    component: () => adminFor('Edges'),
    meta: { auth: ['MANAGE_TAGGINGS'] }
  },
  {
    path: '/admin/revisions',
    name: 'admin-revisions',
    component: () => adminFor('Revisions'),
    meta: { auth: ['MANAGE_TAGGINGS', 'MANAGE_TAGS'] }
  },
  {
    path: '/admin/tags/:id',
    name: 'admin-tag',
    component: () => adminFor('Tag'),
    props: (route) => ({
      id: route.params.id
    }),
    meta: { auth: ['MANAGE_TAGS'] }
  },
  {
    path: '/admin/tags',
    name: 'admin-tags',
    component: () => adminFor('Tags'),
    meta: { auth: ['MANAGE_TAGS'] }
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => adminFor('Users'),
    meta: { auth: ['MANAGE_USERS'] }
  },
  {
    path: '/admin/wordbank',
    name: 'admin-wordbank',
    component: () => adminFor('Wordbank'),
    meta: { auth: ['MANAGE_TAGS'] }
  },
  {
    path: '/admin',
    name: 'admin-dashboard',
    component: () => adminFor('Dashboard'),
    meta: { auth: ['MANAGE_TAGGINGS', 'MANAGE_TAGS', 'MANAGE_TAGS'] }
  },
  {
    path: '/forbidden',
    name: '403',
    component: PageStatus,
    meta: { title: 'Not Authorized' },
    props: () => ({
      error: 'not authorized',
      static: true,
    })
  },
  {
    path: '/*',
    name: '404',
    component: PageStatus,
    meta: { title: 'Not Found' },
    props: () => ({
      error: 'not found',
      static: true,
    })
  }],
  scrollBehavior(to, from, savedPosition) {
    // do nothing unless path/query changes
    // (hash changes are ignored)
    if (to.fullPath.split('#')[0] !== from.fullPath.split('#')[0]) {
      savedScrollPosition = savedPosition;
      return { left: 0, top: 0 };
    }
  }
});

// Restore scroll position after back button.
// Must be called by a view after async data load.
router.restoreScroll = function() {
  requestAnimationFrame(() => {
    if (savedScrollPosition) {
      window.scrollTo(savedScrollPosition.left, savedScrollPosition.top);
    }
  });
};

router.setTitle = function(parts) {
  parts = parts || [];
  parts.push('Scryfall Tagger');
  document.title = parts.join(' • ');
};

router.pageview = function(opts={}) {
  if (opts.scroll) router.restoreScroll();
  if (opts.title) router.setTitle(opts.title);
  pageview();
};

// Set the page metadata
router.beforeEach((to, from, next) => {
  router.setTitle(to.meta.title ? [to.meta.title] : null);

  // set social media urls
  const url = `${window.location.protocol}//${window.location.host}${to.path}`;
  document.querySelector('link[rel="canonical"]').href = url;
  document.querySelector('meta[property="og:url"]').setAttribute('content', url);

  next();
});

// Run authorization checks
router.beforeEach((to, from, next) => {
  const authorization = to.meta.auth;
  if (authorization && !authorization.some(key => config.user.permissions.includes(key))) {
    next({ name: '403' });
  } else {
    next();
  }
});

// Handle random card requests
router.beforeEach(async (to, from, next) => {
  // continue past non-random requests
  if (to.name !== 'random') return next();

  // fetch from Scryfall, then reroute.
  const card = await fetch('https://api.scryfall.com/cards/random').then(res => res.json());

  next({
    name: 'card',
    params: {
      set: card.set,
      number: card.collector_number,
    }
  });
});

export default router;
