const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function graphqlFetch(query, variables={}) {
  let operationName = query.match(/^\s*(?:query|mutation)\s+([^(]+)\s*\(/);
  operationName = operationName ? operationName[1] : undefined;

  const fetchResult = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, operationName, variables }),
  });

  const { data, errors } = await fetchResult.json();
  return errors && errors.length ?  Promise.reject(errors[0]) : Promise.resolve({ data });
}

// replaces graphql-tag, yet still compressable via
// babel-plugin-transform-minify-gql-template-literals
export function gql(literals, ...args) {
  if (typeof literals === 'string') {
    literals = [literals];
  }

  let result = [literals[0]];
  args.forEach((arg, i) => result.push(arg, literals[i + 1]));
  return result.join('');
}
