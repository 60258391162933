<template>
  <div v-if="maySubmitContent" class="action-select">
    <button type="button" class="action-select-toggle" :class="{ '--is-active': opened, dark }" @click.prevent="toggle">
      <svg><use :xlink:href="`#icons-${ promoteReview ? 'disputed' : 'menu' }`"></use></svg>
    </button>
    <div v-if="opened" class="action-menu-wrapper" data-action-menu-overlay @click.prevent>
      <div class="action-menu pop-in" :class="align" @keydown="onKeyPress($event)" @mouseover="onEnter($event)">
        <button v-for="label, val, index in actions"
          :key="val"
          :value="val"
          :data-index="index"
          type="button"
          ref="actionMenuItems"
          class="action-menu-item"
          @click.prevent="trigger(val)">{{ capitalize(label) }}</button>

        <template v-if="weightOptions.length && !isRejected">
          <header>Relevance</header>
          <div class="weight">
            <button v-for="value, index in weightOptions"
              :class="weightActive(value)"
              :data-index="Object.keys(actions).length + index"
              type="button"
              ref="actionMenuItems"
              @click.prevent="trigger('weight', value)">
              <strong v-if="value === 'MEDIAN'">×</strong>
              <tagging-icon v-else :code="value" :tooltip="false"/>
              <span class="vh">{{ value }}</span>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalize, closestAttr } from '../../lib/utils';
import ActionSelectManager from '../../lib/action_select_manager';
import Revisable from '../mixins/revisable';

export default {
  mixins: [Revisable],
  props: {
    align: { type: String, default: 'below left' },
    context: { type: String, default: 'default' },
    dark: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false
    };
  },
  computed: {
    ...mapGetters([
      'maySubmitContent',
      'mayManageEdges',
      'mayManageTags',
    ]),
    actions() {
      switch(this.revisableType) {
        case 'TAG': return this.tagActions();
        case 'TAGGING': return this.taggingActions();
        case 'RELATIONSHIP': return this.relationshipActions();
      }
    },
    title() {
      return capitalize(this.revisableType);
    },
    isRejected() {
      return this.revisable.status === 'REJECTED';
    },
    promoteReview() {
      return this.revisable.pendingRevisions && ['card', 'grid', 'tag'].includes(this.context);
    },
    weightOptions() {
      const options = [];
      const weightEnabled = this.isTagging
        && /^(card|grid)$/.test(this.context)
        && /illustration/.test(this.revisable.foreignKey)
        && (this.mayManageEdges || this.userMayModify);

      if (weightEnabled) {
        if (this.mayManageEdges) {
          options.push('VERY_STRONG');
        }
        options.push('STRONG', 'MEDIAN', 'WEAK');
      }
      return options;
    }
  },
  methods: {
    capitalize,
    toggle() {
      if (!this.opened) {
        this.opened = true;
        ActionSelectManager.attach(this);
        //this.$nextTick(() => this.$refs.actionMenuItems[0].focus());
      } else {
        this.close();
      }
    },

    close() {
      if (this.opened) {
        ActionSelectManager.detach();
        this.opened = false;
      }
    },

    tagActions() {
      const actions = {};

      // all users: review pending dispute
      if (this.promoteReview) {
        actions.review = 'NEEDS REVIEW';
      }

      if (this.mayManageTags && this.context !== 'admin-tag') {
        actions.admin_tag = 'go to admin';
      }

      if (this.mayManageTags && !this.promoteReview && this.context !== 'history') {
        actions.review = 'go to history';
      }

      if (!this.isRejected && !this.revisable.pendingRevisions) {
        actions.revision_tag = 'request changes';
      }

      actions.hierarchy = 'hierarchy';
      return actions;
    },

    taggingActions() {
      const actions = {};

      // all users: review pending dispute
      if (this.promoteReview) {
        actions.review = 'NEEDS REVIEW';
      }

      // administrators: edit, delete, and history
      if (this.mayManageEdges || this.userMayModify) {
        actions.edge_settings = 'edit / delete';
      } else {
        // all users who can't edit: view tagging metadata
        actions.edge_info = 'view metadata';
      }

      // administrators: visit history
      if (this.mayManageEdges && !this.promoteReview && this.context !== 'history') {
        actions.review = 'go to history';
      }

      // all users: create dispute against good standing revisable
      if (!this.isRejected && !this.revisable.pendingRevisions) {
        actions.revision_edge = 'dispute tagging';
      }

      return actions;
    },

    relationshipActions() {
      const actions = {};

      // all users: review pending dispute
      if (this.promoteReview) {
        actions.review = 'NEEDS REVIEW';
      }

      // administrators: replace, delete, and history
      if (this.mayManageEdges || this.userMayModify) {
        actions.edge_settings = 'edit / delete';
      }

      // administrators: visit history
      if (this.mayManageEdges && !this.promoteReview && this.context !== 'history') {
        actions.review = 'go to history';
      }

      // all users: create dispute against good standing revisable
      if (!this.isRejected && !this.revisable.pendingRevisions) {
        actions.revision_edge = 'dispute relationship';
      }

      return actions;
    },

    onKeyPress(evt) {
      let currentIndex = Number(document.activeElement.dataset.index);
      if (!Number.isFinite(currentIndex)) return;

      const items = this.$refs.actionMenuItems;
      const LEFT_ARROW = 37;
      const UP_ARROW = 38;
      const RIGHT_ARROW = 39;
      const DOWN_ARROW = 40;
      const TAB_KEY = (event.which === 9);


      if ([LEFT_ARROW, UP_ARROW].includes(event.which) || (TAB_KEY && evt.shiftKey)) {
        currentIndex = (currentIndex - 1 < 0) ? items.length - 1 : currentIndex - 1;
      } else if ([RIGHT_ARROW, DOWN_ARROW].includes(event.which) || (TAB_KEY && !evt.shiftKey)) {
        currentIndex = (currentIndex + 1) % items.length;
      } else {
        return;
      }

      evt.preventDefault();
      items[currentIndex].focus();
    },

    onEnter(evt) {
      const el = closestAttr(evt.target, 'data-index');
      if (el && el !== document.activeElement) {
        el.focus();
      }
    },

    trigger(action, value) {
      this.opened = false;
      switch(action) {
        case 'review': return this.$router.push(this.toHistory(this.revisableType, this.revisableId));
        case 'weight': return this.$store.dispatch('updateEdge', { id: this.revisableId, weight: value });
        case 'hierarchy': return location.href = `/tags/${ this.revisable.namespace }/${ this.revisable.slug }/tree`;
      }

      action = action.replace(/_/g, '-');
      if (action.startsWith('admin-')) {
        this.$router.push({ name: action, params: { id: this.revisable.id } });
      } else {
        this.$store.dispatch('setDialog', { as: action, revisableType: this.revisableType, revisableId: this.revisableId });
      }
    },

    weightActive(value) {
      return this.revisable.weight === value ? '--is-active' : null;
    },
  },
  beforeUnmount() {
    this.close();
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.action-select {
  display: block;
  height: 28px;
  position: relative;
  width: 32px;

  &-toggle {
    background-color: transparent;
    border: none;
    border-radius: $box-corner-sm;
    color: $c-gray-400;
    display: block;
    height: 100%;
    outline-style: none;
    width: 100%;

    &.--is-active, &:focus {
      background-color: $c-gray-200;
      color: white;
    }

    &.dark.--is-active, &.dark:focus {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  svg {
    display: block;
    box-sizing: border-box;
    fill: currentColor;
    height: 100%;
    left: 0;
    padding: 6px 8px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.action-menu-wrapper {
  @media(max-width: $break-mobile - 1px) {
    align-items: center;
    background-color: $c-overlay;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $header-z + 2;
  }
}

.action-menu {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.95) 100%);
  border: 1px solid $c-gray-300;
  border-radius: $box-corner;
  box-shadow: $box-shadow;
  color: white;
  font-size: 17px;
  padding: spacing(2) 0;
  position: relative;
  min-width: 60%;

  @media(min-width: $break-mobile) {
    font-size: 15px;
    min-width: 150px;
    position: absolute;
    top: 28px;
    right: 0;
    z-index: $header-z - 1;
  }

  button {
    background-color: transparent;
    border: none;
    color: $c-gray-600;
    display: block;
    outline-style: none;
    width: 100%;
  }

  &-item {
    display: block;
    cursor: pointer;
    font-size: font-size(0);
    padding: spacing(2) spacing(3);
    text-align: left;
    white-space: nowrap;

    @media(min-width: $break-mobile) {
      padding: spacing(1) spacing(3);
    }

    &:focus {
      background-color: $cGrape;
      color: white;
    }
  }

  &:before {
    border: 10px solid transparent;
    border-bottom-color: $c-gray-300;
    display: block;
    height: 0;
    right: calc(16px - 10px);
    pointer-events: none;
    position: absolute;
    top: -20px;
    width: 0;
  }

  &:after {
    border: 8px solid transparent;
    border-bottom-color: white;
    display: block;
    height: 0;
    right: calc(16px - 8px);
    pointer-events: none;
    position: absolute;
    top: -16px;
    width: 0;
  }

  &.above {
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.95) 100%);
    bottom: 28px;
    top: auto;

    &:before {
      border-bottom-color: transparent;
      border-top-color: $c-gray-300;
      bottom: -20px;
      top: auto;
    }

    &:after {
      border-bottom-color: transparent;
      border-top-color: white;
      bottom: -16px;
      top: auto;
    }
  }

  &.right {
    left: 0;
    right: auto;

    &:before {
      left: calc(16px - 10px);
      right: auto;
    }

    &:after {
      left: calc(16px - 8px);
      right: auto;
    }
  }


  @media(min-width: $break-mobile) {
    &:before { content: '' }
    &:after { content: '' }
  }

  header {
    border-top: 1px solid $c-gray-300;
    color: $c-gray-500;
    margin: spacing(2) 0 0;
    padding: spacing(1) spacing(3);
  }

  .weight {
    display: flex;
    padding: 0 spacing(3);

    button {
      border: 1px solid $c-gray-200;
      border-radius: $box-corner-sm;
      box-sizing: border-box;
      cursor: pointer;
      flex: 1;
      padding: spacing(1) 0;

      + button {
        margin-left: spacing(2);

        @media(min-width: $break-mobile) {
          margin-left: spacing(1);
        }
      }

      > strong {
        font-size: font-size(2);
        font-weight: normal;
        position: relative;
        top: -0.1em;
      }

      &:focus {
        border-color: $c-gray-400;
      }

      &.--is-active {
        border: 3px solid tint($cGrape, 75%);

        &:focus {
          border-color: tint($cGrape, 50%);
        }
      }
    }

    .tagging-icon {
      margin: 0;
    }
  }
}
</style>
