import { graphqlFetch, gql } from '../../lib/graphql';
import { baseCardAttrs, baseTagAttrs, baseTaggingAttrs } from './schema';
import * as cmd from '../commands';

export default {
  async fetchTag({ state }, params) {
    // params.type
    // params.slug
    const { data } = await graphqlFetch(gql`
      query FetchTag($type:TagType!, $slug:String!, $page:Int=1, $descendants:Boolean=false) {
        tag: tagBySlug(type: $type, slug: $slug, aliasing: true) {
          ...TagAttrs
          scryfallUrl
          description
          ancestry {
            path
            tag {
              ...TagAttrs
            }
          }
          childTags {
            ...TagAttrs
          }
          taggings(page: $page, descendants: $descendants) {
            page
            perPage
            total
            results {
              relatedId
              ...TaggingAttrs
              card {
                ...CardAttrs
              }
            }
          }
        }
      }
      ${ baseCardAttrs }
      ${ baseTagAttrs }
      ${ baseTaggingAttrs }
    `, params);

    this.commit(cmd.REPLACE_MODELS, {
      cardModels: data.tag.taggings.results.map(t => t.card),
      taggingModels: data.tag.taggings.results,
      tagModels: data.tag,
      currentPage: {
        order: data.tag.taggings.results.map(t => t.id),
        page: data.tag.taggings.page,
        perPage: data.tag.taggings.perPage,
        total: data.tag.taggings.total,
        type: 'tagging',
      }
    });

    this.commit('setCurrentTag', data.tag);
    return Promise.resolve(data.tag);
  },

  async searchTags({ state }, input) {
    const { data } = await graphqlFetch(gql`
      query SearchTags($input: TagSearchInput!) {
        tags(input: $input) {
          page
          perPage
          results {
            ...TagAttrs
            taggingCount
          }
          total
        }
      }
      ${ baseTagAttrs }
    `, {
      input
    });

    this.commit(cmd.SET_MENU_TAGS, data.tags);
    return Promise.resolve(data.tags);
  },

  async fetchSampleTags({ state }) {
    const { data } = await graphqlFetch(gql`
      query FetchSampleTags {
        tags: sampleTags {
          ...TagAttrs
        }
      }
      ${ baseTagAttrs }
    `);

    return Promise.resolve(data.tags);
  },
};
