<template>
  <a :href="url" :title="title" target="_blank" class="scryfall-link">
    <svg><use :xlink:href="icon"></use></svg>
    <span class="vh">{{ title }}</span>
  </a>
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
    icon: { type: String, default: '#icons-external' },
    title: { type: String, default: 'View on Scryfall' },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.scryfall-link {
  color: white;
  margin-left: spacing(1);
  opacity: 0.6;
  position: relative;
  top: 0.1em;

  svg {
    box-sizing: border-box;
    fill: currentColor;
    height: 24px;
    padding: 5px;
    width: 24px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
