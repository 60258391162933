import { schemaMerge, schemaFilter, resolveObject } from '../../lib/utils';
import * as defs from './defaults';

function build(base, data, options) {
  return options.patch ? schemaFilter(base, data) : schemaMerge(base, data);
}

export function newCard(data, options={}) {
  const model = build(defs.cardDefault, data, options);
  if (model.scryfallUrl) model.scryfallUrl = model.scryfallUrl.replace('utm_source=api', 'utm_source=tagger');
  return Object.freeze(model);
}

export function newRelationship(data, options={}) {
  const model = build(defs.relationshipDefault, data, options);
  if (data.createdAt) model.createdAt = Date.parse(data.createdAt);
  if (data.createdBy) model.creatorId = data.createdBy.id;
  if (data.card) model.cardId = data.card.id;
  return Object.freeze(model);
}

export function newRevision(data, options={}) {
  const model = build(defs.revisionDefault, data, options);
  if (data.createdAt) model.createdAt = Date.parse(data.createdAt);
  if (data.reporter) model.reporterId = data.reporter.id;
  if (data.reviewer) model.reviewerId = data.reviewer.id;
  if (data.revisable) {
    model.revisableId = data.revisable.id;
    model.revisableType = data.revisable.revisableType;
  }
  return Object.freeze(model);
}

export function newTag(data, options={}) {
  const model = build(defs.tagDefault, data, options);
  if (data.createdAt) model.createdAt = Date.parse(data.createdAt);
  if (data.createdBy) model.creatorId = data.createdBy.id;
  return Object.freeze(model);
}

const taggingWeight = [
  'VERY_WEAK',
  'WEAK',
  'MEDIAN',
  'STRONG',
  'VERY_STRONG',
];

export function newTagging(data, options={}) {
  const model = build(defs.taggingDefault, data, options);
  if (data.createdAt) model.createdAt = Date.parse(data.createdAt);
  if (data.createdBy) model.creatorId = data.createdBy.id;
  if (data.card) model.cardId = data.card.id;
  if (data.tag) model.relatedId = data.tag.id;
  if (data.weight) model.weightSort = taggingWeight.indexOf(data.weight);
  return Object.freeze(model);
}

export function newUser(data, options={}) {
  const model = build(defs.userDefault, data, options);
  return Object.freeze(model);
}

const factoriesByModelSet = {
  cardModels: newCard,
  relationshipModels: newRelationship,
  revisionModels: newRevision,
  tagModels: newTag,
  taggingModels: newTagging,
  userModels: newUser,
};

export function buildModels(mapping, options={}) {
  const models = {};
  const ignore = ['data', 'currentPage'];

  for (let [key, value] of Object.entries(mapping)) {
    if (ignore.includes(key)) continue;
    const refs = Array.isArray(value) ? value : [value];
    const factory = factoriesByModelSet[key];
    if (!factory) throw 'invalid model namespace';

    for (let obj of refs) {
      if (typeof obj === 'string') {
        if (!mapping.data) throw 'string reference requires a data param';
        obj = resolveObject(mapping.data, obj);
      }
      if (obj) {
        if (!Array.isArray(obj)) obj = [obj];
        models[key] = models[key] || [];
        for (let o of obj) {
          models[key].push(factory(o, options));
        }
      }
    }
  }

  return models;
}

export function buildPatches(mapping) {
  return buildModels(mapping, { patch: true });
}
