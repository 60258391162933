<template>
  <div class="card-image">
    <div class="image --card" :class="cssState('card')" @click="toggle">
      <div class="wrapper card">
        <img :class="{'layout-rotated': card.rotatedLayout}" :src="card.cardImageUrl" alt=""/>
      </div>
    </div>
    <div class="image --artwork scale-in" :class="cssState('artwork')" @click="toggle">
      <div class="wrapper">
        <img :src="card.artImageUrl" alt=""/>
      </div>
    </div>
    <footer>
      <button class="mode-toggle" :class="cssState('artwork')" type="button" @click.prevent="select('artwork')">
        <svg><use xlink:href="#icons-artwork"></use></svg>
        <span class="vh">Show artwork</span>
      </button>
      <button class="mode-toggle" :class="cssState('card')" type="button" @click.prevent="select('card')">
        <svg><use xlink:href="#icons-card"></use></svg>
        <span class="vh">Show full card</span>
      </button>
    </footer>
  </div>
</template>

<script>
import pubsub from 'tiny-emitter/instance';

export default {
  props: {
    card: { type: Object, required: true },
    show: { type: String, default: 'card' },
  },
  data() {
    return {
      mode: this.show,
      locked: false,
      hasBeenFocused: false,
    }
  },
  methods: {
    toggle() {
      this.select(this.mode === 'card' ? 'artwork' : 'card');
    },
    select(mode, focus=false) {
      this.hasBeenFocused = this.hasBeenFocused || focus;
      if (!focus && this.hasBeenFocused) {
        this.locked = this.mode === mode ? false : true;
        this.mode = mode;
      } else if (!this.locked) {
        this.mode = mode;
      }
    },
    focusArt() {
      this.select('artwork', true);
    },
    focusCard() {
      this.select('card', true);
    },
    cssState(mode) {
      const match = this.mode === mode;
      return {
        '--active': match,
        '--locked': match && this.locked,
      };
    },
  },
  mounted() {
    pubsub.on('show-artwork', this.focusArt);
    pubsub.on('show-card', this.focusCard);
    pubsub.on('show-print', this.focusCard);
  },
  beforeUnmount() {
    pubsub.off('show-artwork', this.focusArt);
    pubsub.off('show-card', this.focusCard);
    pubsub.off('show-print', this.focusCard);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.card-image {
  position: relative;
}

.image {
  margin: 0 auto;
  text-align: center;

  &.--artwork {
    align-items: center;
    display: none;
    justify-content: center;
    min-height: 347px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media (min-width: $break-medium) {
      min-height: 486px;
    }

    @media (min-width: $break-large) {
      align-items: flex-start;
      min-height: 556px;
    }

    .wrapper {
      display: block;
      max-width: 575px;
      margin: 0 auto;
    }

    img {
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
      max-height: 347px;
      max-width: 100%;

      @media (min-width: $break-medium) {
        max-height: 486px;
      }

      @media (min-width: $break-large) {
        max-height: 556px;
      }
    }

    &.--active {
      display: flex;
    }
  }

  &.--card {
    max-width: 250px;
    opacity: 0.15;
    transition: opacity 0.5s;

    @media (min-width: $break-medium) {
      max-width: 350px;
    }

    @media (min-width: $break-large) {
      max-width: 400px;
    }

    &.--active {
      opacity: 1;
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  margin: spacing(3) 0;
}

.mode-toggle {
  border: 2px solid transparent;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  box-sizing: border-box;
  height: spacing(4);
  outline-style: none;
  padding: 0;
  width: spacing(4);

  &:focus {
    box-shadow: 0px 0px 15px 1px rgba(255, 255, 255, 0.6);
  }

  + .mode-toggle {
    margin-left: spacing(2);
  }

  svg {
    fill: white;
    max-height: spacing(3);
    max-width: spacing(3);
  }

  &.--active {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.--locked {
    border: 2px solid white;
  }
}
</style>
