<template>
  <nav class="adjacent-cards" v-if="adjacentCards.length">
    <div class="adjacent-cards-item" v-for="card, index in adjacentCards" :key="card.collectorNumber">
      <template v-if="card.collectorNumber">
        <svg v-if="index === 0" class="chevron bold left" aria-hidden="true"><use xlink:href="#icons-chevron"></use></svg>
        <router-link rel="nofollow" :to="toCard(card)"><strong>{{ card.name }}</strong></router-link>
        <svg v-if="index === 1" class="chevron bold" aria-hidden="true"><use xlink:href="#icons-chevron"></use></svg>
      </template>
      <template v-else>&nbsp;</template>
    </div>
  </nav>
</template>

<script>
import fetchAdjacentCards from '../../lib/adjacent_cards';

export default {
  props: {
    set: { type: String, required: true },
    number: { type: String, required: true },
  },
  data() {
    return {
      adjacentCards: []
    };
  },
  async mounted() {
    this.adjacentCards = await fetchAdjacentCards(this.set, this.number);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.adjacent-cards {
  display: flex;
  justify-content: space-between;
  margin-top: spacing(4);

  &-item {
    max-width: 48%;
  }

  a {
    color: white;
  }
}
</style>