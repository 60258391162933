import { gql } from '../../lib/graphql';

export const baseCardAttrs = gql`
  fragment CardAttrs on Card {
    artImageUrl
    backside
    cardImageUrl
    collectorNumber
    id
    illustrationId
    name
    oracleId
    printingId
    set
  }
`;

export const baseRelationshipAttrs = gql`
  fragment RelationshipAttrs on Relationship {
    classifier
    classifierInverse
    annotation
    subjectId
    subjectName
    createdAt
    creatorId
    foreignKey
    id
    name
    pendingRevisions
    relatedId
    relatedName
    status
    type
  }
`;

export const baseRevisionAttrs = gql`
  fragment RevisionAttrs on Revision {
    changeWasAccepted
    createdAt
    parentId
    reporterNote
    reporter {
      ...UserIdentityAttrs
    }
    reviewerNote
    reviewer {
      ...UserIdentityAttrs
    }
    mutationName
    mutationValue
    targetTag {
      ...TagAttrs
    }
    id
    status
    updatedAt
  }
`;

export const baseRevisableAttrs = gql`
  fragment RevisableAttrs on Revisable {
    revisableType
    createdBy { ...UserIdentityAttrs }
    ...on Tag { ...TagAttrs }
    ...on Tagging {
      ...TaggingAttrs
      card { ...CardAttrs }
      tag { ...TagAttrs }
    }
    ...on Relationship {
      ...RelationshipAttrs
      card { ...CardAttrs }
    }
  }
`;

export const baseTagAttrs = gql`
  fragment TagAttrs on Tag {
    category
    createdAt
    creatorId
    id
    name
    namespace
    pendingRevisions
    slug
    status
    type
  }
`;

export const baseTaggingAttrs = gql`
  fragment TaggingAttrs on Tagging {
    annotation
    subjectId
    createdAt
    creatorId
    foreignKey
    id
    pendingRevisions
    type
    status
    weight
  }
`;

export const baseUserIdentAttrs = gql`
  fragment UserIdentityAttrs on UserIdentity {
    id
    username
  }
`;
