<template>
  <div v-if="icon" class="tagging-icon" :class="classed" role="note" :tabindex="tabIndex">
    <span v-if="label" class="vh">Annotation: {{ label }}</span>
    <span v-if="tooltip && title" class="tooltip" :class="{ '--wrap': !!label && label.length > 25 }" aria-hidden="true">{{ title }}</span>
    <svg :style="styled" aria-hidden="true">
      <use :xlink:href="`#icons-${ icon }`"></use>
    </svg>
  </div>
</template>

<script>
const iconsByCode = {
  // relationships
  BETTER_THAN:   { i: 'rel-better' },
  COLORSHIFTED:  { i: 'rel-colorshifted' },
  COMES_AFTER:   { i: 'rel-before', s: '-1, 1' },
  COMES_BEFORE:  { i: 'rel-before' },
  DEPICTED_IN:   { i: 'rel-references', s: '-1, 1' },
  DEPICTS:       { i: 'rel-references' },
  MIRRORS:       { i: 'rel-mirrors' },
  REFERENCED_BY: { i: 'rel-references', s: '-1, 1' },
  REFERENCES_TO: { i: 'rel-references' },
  RELATED_TO:    { i: 'rel-related' },
  SIMILAR_TO:    { i: 'rel-similar' },
  WITH_BODY:     { i: 'rel-creature' },
  WITHOUT_BODY:  { i: 'rel-creature', s: '-1' },
  WORSE_THAN:    { i: 'rel-better', s: '-1, 1' },

  // relevance
  VERY_STRONG:   { i: 'star', c: '#dbab09', s: 0.72, t: 'exemplary' },
  STRONG:        { i: 'relevance', c: '#16b823', s: 0.7, t: 'good example' },
  WEAK:          { i: 'relevance', c: '#D42100', s: 0.7, r: 180, t: 'weak example' },
  VERY_WEAK:     { i: 'relevance', c: '#690415', s: 0.7, r: 180, t: 'weak example' },

  // Revisable status
  GOOD_STANDING: { i: 'good-standing', c: '#16b823', s: 0.7 },
  PENDING_MODERATION: { i: 'disputed', c: '#e26310' },
  REJECTED:      { i: 'close', c: '#D42100' },

  // Revision status
  PEER_REVIEW:   { i: 'circle-question', c: '#dbab09' },
  ADMIN_REVIEW:  { i: 'circle-question', c: '#e26310' },
  CLOSED:        { i: 'circle-check' },

  // types
  artwork:       { i: 'artwork', t: false },
  card:          { i: 'card', t: false },
  tag:           { i: 'tag', t: false },
  rel:           { i: 'rel', t: false },
  category:      { i: 'crown', t: 'tag category' },
  broken:        { i: 'broken', t: 'broken reference' },
  annotation:    { i: 'annotation', t: false, c: '#C2C2C4' },

  // change request reviews
  review:        { i: 'circle-question', t: 'needs review' },
  accepted:      { i: 'circle-check', t: 'change accepted' },
  dismissed:     { i: 'circle-x', t: 'change dismissed' },
};

export default {
  props: {
    tooltip: { type: Boolean, default: true },
    code: { type: String, default: 'BLAH' },
    type: { type: String },
    label: { type: String }
  },
  computed: {
    params() {
      return iconsByCode[this.code] || {};
    },
    icon() {
      return this.params.i;
    },
    title() {
      if (this.label) return this.label;
      const title = this.params.t;
      const formatted = title || this.code.toLowerCase().replace(/_/g, ' ');
      return (title === false) ? null : formatted;
    },
    classed() {
      return this.icon ? ['icon-'+this.icon, 'value-'+this.code.toLowerCase().replace(/_/g, '-')] : [];
    },
    styled() {
      const params = this.params;
      const styles = {};
      const transform = [];
      if (params.c) {
        styles.fill = params.c;
      }

      if (params.s) {
        transform.push(`scale(${ params.s })`);
      }

      if (params.r) {
        transform.push(`rotate(${ params.r }deg)`);
      }

      if (transform.length) {
        styles.transform = transform.join(' ');
      }

      return Object.keys(styles).length ? styles : null;
    },
    tabIndex() {
      // only make icon tabbable if it is has a tooltip to display
      if (this.title && this.tooltip) {
        return '0';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.tagging-icon {
  font-size: 16px;
  line-height: 1;
  margin-right: spacing(1);
  position: relative;

  svg {
    fill: currentColor;
    max-height: 15px;
    max-width: 20px;
  }

  &.icon-relevance {
    margin-left: -#{ spacing(1) };
  }

  &:focus .tooltip, &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  color: white;
  display: none;
  font-size: 0.8em;
  font-weight: normal;
  height: 25px;
  left: 50%;
  line-height: 25px;
  padding: 1px 7px;
  position: absolute;
  top: -35px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 100;

  &.--wrap {
    bottom: 30px;
    height: auto;
    line-height: 1.3;
    padding: 5px spacing(2);
    max-width: 150px;
    min-width: 100px;
    text-align: center;
    top: auto;
    white-space: normal;
  }

  &:after {
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: rgba(0, 0, 0, 0.8);
    bottom: -5px;
    content: '';
    display: block;
    left: calc(50% - 5px);
    height: 0;
    position: absolute;
    width: 0;
  }
}
</style>
