import ScryfallCard from './scryfall_card';

function queryKey(id, foreignKey) {
  return `${ foreignKey.toLowerCase() }:${ id }`;
}

export default class ScryfallCardFetcher {
  constructor(size=25) {
    this.awaiting = null;
    this.cacheSize = size;
    this.cache = [];
    this.promised = {};
  }

  cached(id, foreignKey) {
    const key = queryKey(id, foreignKey);
    const entry = this.cache.find(c => c.key === key);
    return entry ? entry.card : null;
  }

  fetch(id, foreignKey) {
    const key = queryKey(id, foreignKey);
    const cached = this.cached(id, foreignKey);
    if (cached) return Promise.resolve(cached);

    this.awaiting = key;

    if (!this.promised[key]) {
      this.promised[key] = new Promise(async (resolve) => {
        // fetch the card from Scryfall
        const { data } = await fetch('https://api.scryfall.com/cards/search?include_extras=true&q='+encodeURIComponent(key))
          .then(res => res.json())
          .catch(() => delete this.promised[key]);

        // abort if we got no data
        delete this.promised[key];
        if (!data || !data.length) return;

        // flip the card to match the requested id
        const card = new ScryfallCard(data[0], id);
        this.cache.unshift({ key, card });
        while (this.cache.length > this.cacheSize) this.cache.pop();

        // only resolve for the request that we're waiting on
        if (key === this.awaiting) resolve(card);
      });
    }

    return this.promised[key];
  }
}