import { createStore } from 'vuex';
import config from '../lib/config';
import cardActions from './actions/cards';
import edgeActions from './actions/edges';
import revisionActions from './actions/revisions';
import submitActions from './actions/submit';
import tagActions from './actions/tags';
import uiActions from './actions/ui';
import userActions from './actions/users';
import getters from './getters';
import mutations from './mutations';
import { pageDefault } from './models/defaults';

export default createStore({
  actions: {
    ...cardActions,
    ...edgeActions,
    ...revisionActions,
    ...submitActions,
    ...tagActions,
    ...uiActions,
    ...userActions,
  },
  getters,
  mutations,
  state: Object.assign({
    // model collections
    cardModels: {},
    relationshipModels: {},
    revisionModels: {},
    tagModels: {},
    taggingModels: {},
    userModels: {},

    // state properties
    backgroundImage: {
      url: null,
      full: false
    },
    currentBreakout: null,
    currentGuidePage: null,
    currentNotice: null,
    currentPage: Object.assign({}, pageDefault),
    currentTag: null,
    edgeDefs: {},
    edgePairs: [],
    skippedDisputeIds: [],
    skippedRevisionIds: [],
    tags: {
      mode: null,
      page: 1,
      perPage: 0,
      query: null,
      results: [],
      total: 0,
    }
  }, config, {
    user: Object.assign({
      id: null,
      permissions: [],
      reviewableRevisionsCount: 0,
      username: null,
    }, config.user || {})
  })
});
