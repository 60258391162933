import { closestAttr } from './utils';

let currentMenu = null;
let installed = false;
//let ticking = false;

function install() {
  if (installed) return;
  installed = true;

  // // maybe use this for dynamic viewport flipping?
  // window.addEventListener('scroll', () => {
  //   if (ticking) return;
  //   ticking = true;
  //   requestAnimationFrame(() => {
  //     ActionSelectManager.replot();
  //     ticking = false;
  //   });
  // });

  window.addEventListener('click', evt => {
    if (!currentMenu) return;
    if (!currentMenu.$el.contains(evt.target) || closestAttr(evt.target, 'data-action-menu-overlay')) {
      ActionSelectManager.close();
    }
  });
}

const ActionSelectManager = {
  attach(menu) {
    install();
    this.close();
    currentMenu = menu;
  },
  detach() {
    currentMenu = null;
  },
  close() {
    if (currentMenu) {
      currentMenu.close();
      this.detach();
    }
  }
};

export default ActionSelectManager;