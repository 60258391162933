const DOUBLE_SIDED_LAYOUTS = [
  'transform',
  'double_faced_token',
  'modal_dfc'
];

export default class ScryfallCard {
  constructor(card, facingId=null) {
    card.card_faces = card.card_faces || [{
      name: card.name,
      type_line: card.type_line,
      artist: card.artist
    }];

    card.card_faces.forEach(face => {
      face.image_uris = face.image_uris || card.image_uris;
      face.illustration_id = face.illustration_id || card.illustration_id;
    });

    this.layout = card.layout;
    this.isDoubleSided = DOUBLE_SIDED_LAYOUTS.includes(card.layout);
    this.back = this.isDoubleSided && facingId === (card.card_faces[1] || {}).illustration_id;
    this.rotatedLayout = card.rotatedLayout;

    const face = card.card_faces[this.back ? 1 : 0];
    const face2 = card.card_faces[this.back ? 0 : 1];

    this.printingId = card.id;
    this.oracleId = card.oracle_id;
    this.illustrationId = face.illustration_id;
    this.illustration2Id = this.isDoubleSided ? face2.illustration_id : null;
    this.artImageUrl = face.image_uris.art_crop;
    this.cardImageUrl = face.image_uris.normal;
    this.scryfallUrl = card.scryfall_uri;
  }

  imageFor(foreignKey) {
    return /illustration/i.test(foreignKey) ? this.artImageUrl : this.cardImageUrl;
  }
}