<template>
  <footer class="app-footer">
    <div class="app-wrapper">
      <nav>
        <span><a href="https://scryfall.com/docs/terms">Terms of Service</a></span>
        <span><a href="https://scryfall.com/docs/syntax">Search Syntax</a></span>
        <span v-if="$store.state.user.id">
          <a href="https://discord.com/channels/291498816459243521/375408241158520832" target="_blank">Community Discord</a>
        </span>
        <span><router-link rel="nofollow" :to="{ name: 'random' }">Random Card</router-link></span>
      </nav>
      <p>The literal and graphical information presented on this site about Magic: The Gathering, including card images, the mana symbols, and Oracle text, is copyright Wizards of the Coast, LLC, a subsidiary of Hasbro, Inc. This website is not produced by, endorsed by, supported by, or affiliated with Wizards of the Coast.</p>
      <p>Made with love by the Scryfall team.</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.app-footer {
  background-color: $c-bg;
  color: $c-gray-400;
  font-size: font-size(-1);
  padding: spacing(3) 0;

  .app-wrapper {
    margin: 0;
  }

  p {
    max-width: 1000px;
    width: 85%;
  }

  nav, p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: spacing(2);
    }
  }

  nav > *:not(:last-child) {
    margin-right: spacing(2);

    &:after {
      color: $c-gray-500;
      content: '//';
      margin-left: spacing(2);
    }
  }

  a {
    color: white;
  }
}
</style>
