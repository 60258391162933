import { graphqlFetch, gql } from '../../lib/graphql';
import * as frag from './schema';
import * as cmd from '../commands';

let disableLiveUpdates = null;

const revisableStatusAttrs = gql`
  fragment RevisableStatusAttrs on Revisable {
    id
    pendingRevisions
    revisableType
    status
  }
`;

export default {
  // Fetches reviewable revisions for the current user.
  // params.limit – number of revision records to fetch.
  // params.patch - merges fetched records into the existing pool.
  async fetchReviewableRevisions({ state }, params) {
    if (!state.user.id) return Promise.resolve([]);
    const excluding = (params.patch ? state.currentPage.order : []).concat(state.skippedRevisionIds);
    const { data } = await graphqlFetch(gql`
      query FetchReviewableRevisions($userId: ID!, $limit: Int=10, $excluding: [ID!]) {
        user(id: $userId) {
          reviewableRevisions(limit: $limit, excluding: $excluding) {
            ...RevisionAttrs
            revisable {
              ...RevisableAttrs
            }
          }
        }
      }
      ${ frag.baseCardAttrs }
      ${ frag.baseRevisionAttrs }
      ${ frag.baseRevisableAttrs }
      ${ frag.baseRelationshipAttrs }
      ${ frag.baseTagAttrs }
      ${ frag.baseTaggingAttrs }
      ${ frag.baseUserIdentAttrs }
    `, {
      userId: state.user.id,
      limit: params.limit || 10,
      excluding,
    });

    const revisions = data.user.reviewableRevisions;
    const taggings = revisions.filter(d => d.revisable.revisableType === 'TAGGING').map(d => d.revisable);
    const rels = revisions.filter(d => d.revisable.revisableType === 'RELATIONSHIP').map(d => d.revisable);
    const tags = revisions.filter(d => d.revisable.revisableType === 'TAG').map(d => d.revisable);
    const userModels = []
      .concat(revisions.map(d => d.reporter))
      .concat(revisions.map(d => d.reviewer))
      .concat(revisions.map(d => d.revisable.createdBy))
      .filter(u => !!u);

    this.commit(params.patch ? cmd.PATCH_MODELS : cmd.REPLACE_MODELS, {
      cardModels: taggings.map(t => t.card).concat(rels.map(t => t.card)),
      revisionModels: revisions,
      relationshipModels: rels,
      tagModels: taggings.map(t => t.tag).concat(tags),
      taggingModels: taggings,
      userModels,
      currentPage: {
        order: (params.patch ? state.currentPage.order : []).concat(revisions.map(t => t.id)),
        type: 'revision',
      }
    });

    return Promise.resolve(revisions);
  },

  cycleRevision({ state }, params) {
    this.commit(cmd.CYCLE_REVISION, params);

    if (state.currentPage.order.length <= params.min) {
      return this.dispatch('fetchReviewableRevisions', {
        limit: params.max - state.currentPage.order.length,
        patch: true,
      });
    }
    return Promise.resolve([]);
  },

  async fetchRevisableHistory(store, params) {
    const { data } = await graphqlFetch(gql`
      query FetchRevisableHistory($id: ID!, $type: RevisableType!) {
        revisable(id: $id, type: $type) {
          ...RevisableAttrs
          revisions {
            ...RevisionAttrs
          }
        }
      }
      ${ frag.baseCardAttrs }
      ${ frag.baseRevisionAttrs }
      ${ frag.baseRevisableAttrs }
      ${ frag.baseRelationshipAttrs }
      ${ frag.baseTagAttrs }
      ${ frag.baseTaggingAttrs }
      ${ frag.baseUserIdentAttrs }
    `, params);

    const d = data.revisable;
    const userModels = [d.createdBy]
      .concat(d.revisions.map(d => d.reporter))
      .concat(d.revisions.map(d => d.reviewer))
      .filter(u => !!u);

    this.commit(cmd.REPLACE_MODELS, {
      cardModels: d.card || [],
      relationshipModels: d.revisableType === 'RELATIONSHIP' ? d : [],
      revisionModels: d.revisions,
      tagModels: d.revisableType === 'TAG' ? d : (d.tag || []),
      taggingModels: d.revisableType === 'TAGGING' ? d : [],
      userModels,
      currentPage: {
        order: d.revisions.map(d => d.id),
        page: 1,
        perPage: d.revisions.length,
        total: d.revisions.length,
        type: 'revision',
      }
    });

    return Promise.resolve(data.revisable);
  },

  async fetchRevisable(store, params) {
    const { data } = await graphqlFetch(gql`
      query FetchRevisable($type: RevisableType!, $id: ID!) {
        revisable(type: $type, id: $id) {
          ...RevisableStatusAttrs
          revisions {
            id
            reporterNote
            status
          }
        }
      }
      ${ revisableStatusAttrs }
    `, params);

    return Promise.resolve(data.revisable);
  },

  async createRevision(store, input) {
    const { data } = await graphqlFetch(gql`
      mutation CreateRevision($input: RevisionCreateInput!) {
        result: revisionCreate(input: $input) {
          revision {
            ...RevisionAttrs
            revisable {
              ...RevisableStatusAttrs
            }
          }
        }
      }
      ${ frag.baseRevisionAttrs }
      ${ frag.baseUserIdentAttrs }
      ${ frag.baseTagAttrs }
      ${ revisableStatusAttrs }
    `, { input });

    const revision = patchRevision.call(this, data.result.revision);
    this.commit(cmd.SET_NOTICE, { message: 'change request was created' });
    return Promise.resolve(revision);
  },

  async resolveRevision(store, params) {
    const { data } = await graphqlFetch(gql`
      mutation ResolveRevision($id: ID!, $input: RevisionResolveInput!) {
        result: revisionResolve(id: $id, input: $input) {
          revision {
            ...RevisionAttrs
            revisable {
              ...RevisableStatusAttrs
            }
          }
        }
      }
      ${ frag.baseRevisionAttrs }
      ${ frag.baseUserIdentAttrs }
      ${ frag.baseTagAttrs }
      ${ revisableStatusAttrs }
    `, params);

    const revision = patchRevision.call(this, data.result.revision);
    this.commit(cmd.SET_NOTICE, { message: 'change was resolved' });
    return Promise.resolve(revision);
  },

  toggleLiveRevisions(store, enable) {
    if (enable && !disableLiveUpdates && typeof EventSource === 'function') {
      disableLiveUpdates = (() => {
        const liveSrc = new EventSource('/live');
        const liveHandler = (evt) => {
          const { data } = JSON.parse(evt.data);
          data.revision.resolvedLive = true;
          patchRevision.call(this, data.revision);
        };
        liveSrc.addEventListener('revision', liveHandler);
        return () => {
          liveSrc.close();
          liveSrc.removeEventListener('revision', liveHandler);
          disableLiveUpdates = null;
        };
      })();
    } else if (!enable && disableLiveUpdates) {
      disableLiveUpdates();
    }
  },
};

function patchRevision(revision) {
  const userModels = [revision.reporter, revision.reviewer].filter(u => !!u);
  this.commit(cmd.PATCH_MODELS, {
    userModels,
    revisionModels: revision,
    [`${ revision.revisable.revisableType.toLowerCase() }Models`]: revision.revisable,
  });
  return revision;
}
