<template>
  <main>
    <template v-if="loaded">
      <div class="ancestors" v-if="ancestors.length">
        <div v-for="path, index in ancestors" :key="index">
          <span v-for="tag in path" :key="tag.id">
            <router-link rel="nofollow" :to="toTag(tag)">{{ tag.name }}</router-link><svg class="chevron" aria-hidden="true"><use xlink:href="#icons-chevron"></use></svg>
          </span>
        </div>
      </div>

      <h1 class="section-title">
        <svg><use :xlink:href="`#icons-${ tag.namespace }`"></use></svg>
        <tagging-icon v-if="tag.category" code="category"/>
        <span>{{ tag.name }}</span>
        <scryfall-link :url="tag.scryfallUrl" v-if="tag.scryfallUrl" />
        <action-select revisableType="TAG" :revisableId="tag.id" align="below right" context="tag" :dark="true"/>
      </h1>

      <div class="tag-count">{{ thousands($store.state.currentPage.total) }} taggings</div>
      <p class="tag-description" v-if="tag.description" v-html="renderSafeHtml(tag.description)"></p>

      <section class="section-info" :class="{ 'light-mode': childrenExpanded }" v-if="children.length">
        <header>
          <a href="#" class="child-tags--title" @click="childrenExpanded=!childrenExpanded">
            <strong>child tags {{ childrenExpanded ? '–' : '+' }}</strong>
          </a>
          <label v-if="!tag.category">
            <input type="checkbox" v-model="descendants"/>
            include descendant tags
          </label>
        </header>
        <div class="child-tags-grid" v-if="childrenExpanded">
          <div class="tag-row" v-for="tag in children" :key="tag.id">
            <tagging-icon :code="tag.namespace"/>
            <router-link rel="nofollow" :to="toTag(tag)">{{ tag.name }}</router-link>
          </div>
        </div>
      </section>

      <card-grid :asArtwork="asArtwork" :taggingActions="enableCuration" :baseUrl="{ name: 'tag', params: { type, slug } }"/>
    </template>
    <page-status v-if="loading || !loaded" :error="error" :overlay="loaded"/>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { thousands, capitalize, renderSafeHtml } from '../lib/utils';
import SubjectDomain from '../lib/subject_domain';
import ActionSelect from '../components/ui/action-select.vue';
import CardGrid from '../components/ui/card-grid.vue';
import ScryfallLink from '../components/ui/scryfall-link.vue';

export default {
  components: {
    ActionSelect,
    CardGrid,
    ScryfallLink,
  },
  props: {
    type: { type: String, required: true },
    slug: { type: String, required: true },
  },
  data() {
    return {
      error: null,
      loading: true,
      loaded: false,
      perPage: 0,
      total: 0,
      tag: null,
      children: [],
      childrenExpanded: false,
      ancestry: [],
      descendants: true,
      page: 1,
    }
  },
  querySync: {
    params: {
      page: {
        read(query) {
          this.page = Number(query.page) || 1;
        },
        write(query) {
          if (this.page > 1) {
            query.page = this.page;
          }
        }
      },
      descendants: {
        read(query) {
          this.descendants = query.descendants !== 'off';
        },
        write(query) {
          if (!this.descendants) {
            query.descendants = 'off';
          }
        }
      }
    },
    afterWrite() {
      this.update();
    }
  },
  computed: {
    ...mapGetters([
      'firstCardOnPage'
    ]),
    asArtwork() {
      return this.tag && this.tag.type === 'ILLUSTRATION_TAG';
    },
    enableCuration() {
      // action select curation is enabled when:
      // - we're not showing descendants
      // - or when there are no descendants to show
      return !this.descendants || !this.children.length;
    },
    taggings() {
      return this.taggingIds.map(id => this.$store.state.taggingModels[id]);
    },
    ancestors() {
      const tagsById = this.ancestry.reduce((memo, node) => {
        memo[node.tag.id] = node.tag;
        return memo;
      }, {});

      const paths = this.ancestry
        .filter(node => !!node.path)
        .map(node => node.path);

      return paths
        .map(path => path.map(id => tagsById[id]).slice(0, path.length-1))
        .filter(path => !!path.length)
        .sort((a, b) => a[0].name.localeCompare(b[0].name) || a.length - b.length);
    }
  },
  methods: {
    thousands,
    renderSafeHtml,
    ...mapActions([
      'fetchTag',
      'setBackground',
    ]),
    update() {
      this.loading = true;
      this.fetchTag({
        type: new SubjectDomain(this.type).tagType,
        slug: this.slug,
        page: this.page,
        descendants: this.descendants,
      })
        .then(data => {
          if (data.slug !== this.slug || data.namespace !== this.type) {
            return this.$router.replace(this.toTag(data));
          }
          this.loading = false;
          this.loaded = true;
          this.tag = data;
          this.children = data.childTags.sort((a, b) => a.name.localeCompare(b.name));
          this.ancestry = data.ancestry;
          this.$router.pageview({
            title: [this.tag.name, `${ capitalize(this.type) } Tag`],
            scroll: true,
          });
        })
        .catch(err => {
          this.loading = false;
          this.error = err.message;
        });
    }
  },
  watch: {
    firstCardOnPage(card) {
      this.setBackground(card ? card.artImageUrl : null);
    }
  },
  beforeUnmount() {
    this.$store.commit('setCurrentTag', null);
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

.ancestors {
  margin: spacing(3) 0;

  > * {
    margin: spacing(1) 0;
  }

  .chevron {
    margin: 0 spacing(1);
  }
}

.section-info {
  &.light-mode {
    background-color: white;
  }
}

.tag-count {
  margin: spacing(3) 0;
  opacity: 0.65;
}

.tag-description {
  font-style: italic;
  font-size: font-size(1);
  margin: spacing(3) 0 spacing(4);
  max-width: 750px;
}

.child-tags-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 spacing(3);
  padding: spacing(3) 0 spacing(2);

  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1050px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
