<template>
  <div class="tagging-ancestors" v-if="tags.length"><em>Inherits</em> &mdash;
    <template v-for="tag, index in tags">
      <router-link :to="toTag(tag)">{{ tag.name }}</router-link>{{ index < tags.length-1 ? ', ' : '' }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tags: { type: Array, required: true },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.tagging-ancestors {
  margin-top: spacing(3);
}
</style>
