export const cardDefault = {
  _type: 'card',
  artImageUrl: null,
  artist: null,
  backside: false,
  cardImageUrl: null,
  collectorNumber: null,
  layout: null,
  id: null,
  illustrationId: null,
  name: null,
  oracleId: null,
  printingId: null,
  typeLine: null,
  twoSided: false,
  rotatedLayout: false,
  scryfallUrl: null,
  set: null,
  side: null,
  sideNames: [],
};

export const pageDefault = {
  order: [],
  page: 1,
  perPage: 0,
  total: 0,
  type: 'card',
};

export const relationshipDefault = {
  _type: 'relationship',
  annotation: null,
  classifier: null,
  classifierInverse: null,
  cardId: null,
  subjectId: null,
  subjectName: null,
  createdAt: null,
  creatorId: null,
  disputableType: null,
  foreignKey: null,
  id: null,
  name: null,
  namespace: null,
  pendingRevisions: false,
  relatedId: null,
  relatedName: null,
  status: null,
  type: null,
  userId: null,
};

export const revisionDefault = {
  _type: 'revision',
  changeWasAccepted: false,
  createdAt: null,
  parentId: null,
  revisableId: null,
  revisableType: null,
  reporterId: null,
  reporterNote: null,
  resolvedLive: false,
  reviewerId: null,
  reviewerNote: null,
  mutationName: null,
  mutationValue: null,
  targetTag: null,
  id: null,
  status: null,
  updatedAt: null,
};

export const tagDefault = {
  _type: 'tag',
  ancestorTags: [],
  category: false,
  createdAt: null,
  creatorId: null,
  description: null,
  disputableType: null,
  id: null,
  name: null,
  namespace: null,
  pendingRevisions: false,
  scryfallUrl: null,
  slug: null,
  status: null,
  type: null,
};

export const taggingDefault = {
  _type: 'tagging',
  annotation: null,
  cardId: null,
  subjectId: null,
  createdAt: null,
  creatorId: null,
  disputableType: null,
  foreignKey: null,
  id: null,
  metadata: null,
  name: null,
  pendingRevisions: false,
  relatedId: null,
  status: null,
  type: null,
  weight: null,
  weightSort: 2,
};

export const userDefault = {
  _type: 'user',
  contributionsCount: 0,
  contributionsPercentile: 0,
  id: null,
  permissions: [],
  role: null,
  username: null,
};

export const defaultsByModelSet = {
  cardModels: cardDefault,
  relationshipModels: relationshipDefault,
  revisionModels: revisionDefault,
  tagModels: tagDefault,
  taggingModels: taggingDefault,
  userModels: userDefault,
};
