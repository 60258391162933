const ILLUSTRATION = 'ILLUSTRATION';
const ORACLE_CARD = 'ORACLE_CARD';
const PRINTING = 'PRINTING';

const subjectTypeByAlias = {
  illustration: ILLUSTRATION, // IllustrationTag, illustration_id
  artwork: ILLUSTRATION, // /artwork/:tag
  oracle_card: ORACLE_CARD, // OracleCardTag
  oracle: ORACLE_CARD, // oracle_id
  card: ORACLE_CARD, // /card/:tag
  printing: PRINTING, // PrintingTag, printing_id
  print: PRINTING, // /print/:tag
};

const namespaceBySubjectType = {
  [ILLUSTRATION]: 'artwork',
  [ORACLE_CARD]: 'card',
  [PRINTING]: 'print',
};

export default class SubjectDomain {
  constructor(givenAlias) {
    const normalizedAlias = givenAlias
      .replace(/[a-z][A-Z]/g, str => [str[0], '_', str[1]].join(''))
      .toLowerCase()
      .replace(/_(id|tag)$/, '');

    if (subjectTypeByAlias[normalizedAlias]) {
      this.subjectType = subjectTypeByAlias[normalizedAlias];
    } else {
      throw new Error(`invalid subject reference "${givenAlias}"`);
    }
  }

  get namespace() {
    return namespaceBySubjectType[this.subjectType];
  }

  get tagType() {
    return `${this.subjectType}_TAG`;
  }

  get taggingType() {
    return `${this.subjectType}_TAG`;
  }

  get foreignKey() {
    return this.subjectType.replace(/_CARD$/, '').toLowerCase() + 'Id';
  }
};
