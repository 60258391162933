<template>
  <section>
    <div v-if="!loaded">Loading...</div>
    <form v-else @submit.prevent="submit">
      <revisable-header prefix="Revise" :revisableId="revisableId" :revisableType="revisableType"/>

      <div class="tag-form-row">
        <label>Action <guide-link slug="change-requests"/>
          <select class="tag-input-field" v-model="mutationName">
            <option value="description">Modify this tag's description</option>
            <option value="status">Reject this tag and hide it</option>
            <option value="alias">Merge this tag INTO another tag, making THIS tag a hidden alias</option>
          </select>
        </label>
      </div>

      <div class="tag-form-row" v-if="mutationName === 'description'">
        <label>Proposed description for the tag
          <textarea class="tag-input-field" v-model="mutationValue"></textarea>
        </label>
      </div>

      <div class="tag-form-row" v-if="mutationName === 'alias'">
        <label>Tag to merge INTO
          <suggestion-input ref="taxonomy"
            :type="this.revisable.type"
            :taggableOnly="true"
            :softSubmit="true"
            @submit="mutationValue = $event.id"
          />
        </label>
      </div>

      <div class="tag-form-row" v-if="/status|alias/.test(mutationName)">
        <label>Describe your reason for this change
          <textarea class="tag-input-field" v-model="reporterNote"></textarea>
        </label>
      </div>

      <div v-if="error" class="tag-form-row errors">{{ error }}</div>

      <div class="tag-form-row">
        <button class="button-action button-primary" type="submit" @click.prevent="submit">Create change request</button>
        <button class="button-action" type="button" @click.prevent="close()">Cancel</button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions} from 'vuex';
import Revisable from '../mixins/revisable';
import RevisableHeader from '../ui/revisable-header.vue';
import SuggestionInput from '../ui/suggestion-input.vue';
import { renderError } from '../../lib/utils';

const defaultValues = {
  status: 'rejected',
};

export default {
  mixins: [Revisable],
  components: {
    RevisableHeader,
    SuggestionInput,
  },
  data() {
    return {
      error: '',
      loaded: false,
      mutationName: 'description',
      mutationValue: '',
      reporterNote: '',
    };
  },
  methods: {
    ...mapActions([
      'createRevision',
      'fetchRevisable',
    ]),
    submit() {
      this.error = '';
      this.createRevision({
        mutationName: this.mutationName,
        mutationValue: this.mutationValue || defaultValues[this.mutationName],
        reporterNote: this.reporterNote || undefined,
        revisableId: this.revisableId,
        revisableType: this.revisableType,
        status: 'ADMIN_REVIEW',
      })
        .then(() => this.close())
        .catch(err => this.error = renderError(err.message));
    }
  },
  watch: {
    mutationName() {
      this.mutationValue = '';
      this.reporterNote = '';
    }
  },
  mounted() {
    this.fetchRevisable({
      type: this.revisableType,
      id: this.revisableId,
    })
      .then(() => this.loaded = true)
      .catch(err => this.error = err);
  }
};
</script>
