export default {
  methods: {
    toCard(card, tagging) {
      // broken content reference:
      if (card.set === 'MISSING') {
        return { name: 'admin-repair' };
      }

      const params = {
        set: card.set,
        number: card.collectorNumber
      };

      if (card.backside) {
        params.back = 'back';
      }

      return {
        name: 'card',
        params
      };
    },
    toTag(tag) {
      return {
        name: 'tag',
        params: {
          type: tag.namespace,
          slug: tag.slug
        }
      };
    },
    toArtTag(slug) {
      return this.toTag({ namespace: 'artwork', slug });
    },
    toCardTag(slug) {
      return this.toTag({ namespace: 'card', slug });
    },
    toRelated(rel, id=null) {
      return {
        name: 'search',
        query: {
          q: id || rel.relatedId,
          mode: rel.foreignKey.replace(/Id$/, '')
        }
      };
    },
    toHistory(type, id) {
      return {
        name: 'history',
        params: {
          id,
          type: type.toLowerCase()
        }
      };
    },
    toUser(user) {
      return {
        name: 'user',
        params: {
          username: user.username
        }
      };
    },
    toEdges(id) {
      const q = (typeof id === 'object') ? `${ Object.keys(id)[0] }:${ Object.values(id)[0] }` : `ref:${ id }`;
      return {
        name: 'admin-edges',
        query: { q }
      };
    },
    toHover(edge, id) {
      return `${ edge.foreignKey.toLowerCase() }:${ id || edge.subjectId }`;
    },
    signInLink() {
      return `/signin?return_to=${encodeURI(this.$route.path)}`;
    }
  }
};
