import { graphqlFetch, gql } from '../../lib/graphql';
import { baseCardAttrs, baseRelationshipAttrs, baseTagAttrs, baseTaggingAttrs } from './schema';
import * as cmd from '../commands';

export default {
  async fetchCard({ state }, params) {
    // params.set
    // params.number
    // params.back (optional)
    const { data } = await graphqlFetch(gql`
      query FetchCard($set: String!, $number: String!, $back: Boolean=false, $moderatorView: Boolean=false) {
        card: cardBySet(set: $set, number: $number, back: $back) {
          ...CardAttrs
          backside
          layout
          scryfallUrl
          sideNames
          twoSided
          rotatedLayout
          taggings(moderatorView: $moderatorView) {
            ...TaggingAttrs
            tag {
              ...TagAttrs
              ancestorTags {
                ...TagAttrs
              }
            }
          }
          relationships(moderatorView: $moderatorView) {
            ...RelationshipAttrs
          }
        }
      }
      ${ baseCardAttrs }
      ${ baseRelationshipAttrs }
      ${ baseTagAttrs }
      ${ baseTaggingAttrs }
    `, params);

    this.commit(cmd.REPLACE_MODELS, {
      cardModels: data.card,
      relationshipModels: data.card.relationships,
      tagModels: data.card.taggings.map(t => t.tag),
      taggingModels: data.card.taggings,
    });

    return Promise.resolve(data.card);
  },

  async searchCards({ state }, input) {
    // params.query
    // params.page
    // params.mode
    const { data } = await graphqlFetch(gql`
      query SearchCards($input: CardSearchInput!) {
        cards(input: $input) {
          page
          perPage
          total
          results {
            ...CardAttrs
          }
        }
      }
      ${ baseCardAttrs }
    `, { input });

    this.commit(cmd.REPLACE_MODELS, {
      cardModels: data.cards.results,
      currentPage: {
        order: data.cards.results.map(t => t.id),
        page: data.cards.page,
        perPage: data.cards.perPage,
        total: data.cards.total,
        type: 'card',
      }
    });

    return Promise.resolve(data.cards);
  }
};
