<template>
  <div class="progress-bar">
    <div class="inner-progress" :style="innerProgressStyle">
      <em class="inner-label" :class="progressBarLabelClass">{{label}}</em>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    percentage: { type: Number, default: 0 }
  },
  computed: {
    innerProgressStyle() {
      const style = {
        width: `${this.percentage}%`
      };

      if (this.percentage >= 100) {
        // round out the whole thing if percentage is full
        style.borderRadius = '10px';
      }

      return style;
    },
    progressBarLabelClass() {
      let className = 'inner-label-';
      const direction = this.percentage > 50 ? 'left' : 'right';

      className += direction;

      return {
        [className]: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.progress-bar {
  border: 1px solid white;
  border-radius: 11px;
  height: 22px;
  position: relative;
}

.inner-progress {
  background: white;
  border-radius: 10px 0 0 10px;
  height: 18px;
  margin: 2px;
}

.inner-label {
  display: block;
  height: 22px;
  line-height: 20px;
  position: absolute;
  top: 0;
}

.inner-label-right {
  right: spacing(3);
  color: white;
}

.inner-label-left {
  left: spacing(3);
  color: $c-gray-600;
}
</style>
