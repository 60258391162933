import { cardDefault, tagDefault } from '../../store/models/defaults';

export default {
  props: {
    revisableType: { type: String, required: true },
    revisableId: { type: String, required: true },
  },
  data() {
    return {
      accessUpdate: Date.now()
    };
  },
  computed: {
    isTag() {
      return this.revisableType === 'TAG';
    },
    isTagging() {
      return this.revisableType === 'TAGGING';
    },
    isRelationship() {
      return this.revisableType === 'RELATIONSHIP';
    },
    revisable() {
      const modelCollection = {
        TAG: this.$store.state.tagModels,
        TAGGING: this.$store.state.taggingModels,
        RELATIONSHIP: this.$store.state.relationshipModels,
      }[this.revisableType] || {};
      return modelCollection[this.revisableId] || {};
    },
    revisableLabel() {
      return this.revisableType.toLowerCase();
    },
    relatedTag() {
      if (this.isTag) return this.revisable;
      if (this.isRelationship) return tagDefault;
      return this.$store.state.tagModels[this.revisable.relatedId] || tagDefault;
    },
    relatedCard() {
      if (this.isTag) {
        return null;
      } else if (this.revisable.cardId) {
        return this.$store.state.cardModels[this.revisable.cardId] || cardDefault;
      }

      return Object.values(this.$store.state.cardModels)
        .find(card => card[this.revisable.foreignKey] === this.revisable.subjectId) || cardDefault;
    },
    limitTimeout() {
      return this.revisable.createdAt + (1000 * 60 * 5);
    },
    timeLimit() {
      const time = this.timeToModify(this.accessUpdate);
      return isFinite(time) && time > 0;
    },
    userMayModify() {
      const time = this.timeToModify(this.accessUpdate);
      return !isFinite(time) || time > 0;
    },
  },
  methods: {
    close(data) {
      this.$emit('close', data || null);
    },
    timeToModify(timestamp) {
      timestamp = timestamp || Date.now();
      if (this.isTag && this.$store.getters.mayManageTags) return Infinity;
      if (!this.isTag && this.$store.getters.mayManageEdges) return Infinity;
      if (this.revisable.creatorId === this.$store.state.user.id) return Math.max(0, this.limitTimeout - timestamp);
      return 0;
    },
    userMayReview(revision) {
      let authorized = false;
      if (/(PEER|ADMIN)_REVIEW/.test(revision.status)) {
        const isReporter = this.$store.state.user.id === revision.reporterId;
        authorized = authorized || (revision.status === 'PEER_REVIEW' && this.$store.getters.maySubmitContent && !isReporter);
        authorized = authorized || (revision.revisableType === 'TAG' && this.$store.getters.mayManageTags);
        authorized = authorized || this.$store.getters.mayManageEdges;
      }
      return authorized;
    }
  },
};
