/**
* Merges data into a schema of whitelisted keys, creating a new object.
* builds an object with keys of the first (schema) combined with values of the second (data).
* @param schema Object. A schema of keys to build, with values set to EMPTY defaults.
* @param data Object. A set of data to apply. Schema will be filled in with values from this object.
*/
export function schemaMerge(schema, data) {
  return Object.keys(schema).reduce((memo, key) => {
    memo[key] = data.hasOwnProperty(key) ? data[key] : schema[key];
    return memo;
  }, {});
}

/**
* Filters a blob of data down to whitelisted fields.
* builds an object with values of the second (data) for keys in the first (schema).
*/
export function schemaFilter(schema, data) {
  return Object.keys(schema).reduce((memo, key) => {
    if (data.hasOwnProperty(key)) memo[key] = data[key];
    return memo;
  }, {});
}

/**
* Resolves an object from a deeply-nested structure by namespace string.
* Namespace is a dot-notation string of fields to follow through data.
*/
export function resolveObject(data, ns) {
  const path = ns.split('.');
  let obj = data;

  for (let p of path) {
    obj = obj[p];
    if (!obj) break;
  }
  return obj || null;
}

/**
 * @param {number} fn - function to debounce.
 * @param {number} delay - milliseconds to wait after last call before running.
 * @param {number} delay - function invocation context.
 * @return {Function} debounced function that will only run after it has stopped being called.
 */
export function debounce(fn, delay=0, options={}) {
  let timer;
  return function() {
    const callTarget = options.context || this;
    const callArgs = arguments;
    clearTimeout(timer);
    if (options.immediate && !timer) fn.apply(callTarget, callArgs);
    timer = setTimeout(function() {
      fn.apply(callTarget, callArgs);
      timer = null;
    }, delay);
  };
}

/**
 * Finds the closest DOM element with the given attribute.
 * @param {number} target element to start from.
 * @return {number} attribute to search for.
 */
export function closestAttr(el, attr) {
  while (el && el.hasAttribute) {
    if (el.hasAttribute(attr)) return el;
    el = el.parentNode;
  }
  return null;
}

const empty = [undefined, null, ''];

export function mutableHasChanged(mutable, source) {
  return Object.keys(mutable).some(key => {
    return mutable[key] !== source[key];
  });
}

export function Deferred() {
  this.promise = new Promise((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });
}

export function unique(arr) {
  return arr.filter((v, i, a) => a.indexOf(v) === i);
}

export function capitalize(str) {
  str = str.toLowerCase();
  return str[0].toUpperCase() + str.slice(1);
}

export function humanize(str) {
  return capitalize(str.replace(/_/g, ' ').toLowerCase());
}

export function sluggify(str) {
  return str.replace(/[^\w]|_/g, '-').toLowerCase();
}

export function thousands(num) {
  return typeof num === 'number' ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num;
}

export function ordinal(num) {
  const onesDigit = num % 10;

  if (onesDigit < 4 && !(num > 10 && num < 14)) {
    switch (onesDigit) {
      case 1: return `${ num }st`;
      case 2: return `${ num }nd`;
      case 3: return `${ num }rd`;
    }
  }

  return `${ num }th`;
}

export function uid() {
  return btoa(Math.random().toString()).slice(3, 11);
}

/**
* Formats an absolute timestamp.
* @param {Number|Date|String} timestamp to format.
* @returns {String} absolute time string.
*/
export function datestamp(timestamp) {
  var d = new Date(timestamp);
  return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
}

// markdown link formatting, see https://rubular.com/r/4DHzBcjbsHWY9O
const markdownLink = /\[([^\]]+)\]\(([^\)]+)\)/g;

// escape dangerous characters and then render in supported tags
export function renderSafeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/\//g, '&#x2F;')
    .replace(markdownLink, '<a href="$2" rel="nofollow">$1</a>');
}

// remove rich text formatting to render as plain text
export function renderPlainText(rich) {
  return rich.replace(markdownLink, '$1');
}

export function renderError(message) {
  return (message || 'An error occurred').replace(/^(Error:\s*)?GraphQL error:\s*/i, '');
}

export function parseSearch(str, fields, defaultKey=null) {
  if (Array.isArray(fields)) {
    fields = fields.reduce((memo, key) => {
      memo[key] = key;
      return memo;
    }, {});
  }

  const inputs = str.split(new RegExp(`(${ Object.keys(fields).join('|') }):`));
  const leftovers = [];
  const params = {};

  for (let i=0; i < inputs.length; i+=1) {
    const field = fields[inputs[i]];
    if (field) {
      params[field] = String(inputs[i+1]).trim();
      i += 1;
    } else {
      leftovers.push(inputs[i]);
    }
  }

  const leftover = leftovers.join(' ').trim();
  if (!Object.keys(params).length && defaultKey && leftover) {
    params[defaultKey] = leftover;
  }

  return params;
}
