export default {
  maySubmitContent(state) {
    return state.user.permissions.includes('SUBMIT_CONTENT');
  },
  maySubmitAdvancedData(state) {
    return state.user.permissions.includes('SUBMIT_PRINT_TAGS');
  },
  mayManageUsers(state) {
    return state.user.permissions.includes('MANAGE_USERS');
  },
  mayManageTags(state) {
    return state.user.permissions.includes('MANAGE_TAGS');
  },
  mayManageEdges(state) {
    return state.user.permissions.includes('MANAGE_TAGGINGS');
  },
  firstCardOnPage(state) {
    let id = state.currentPage.order[0];
    if (state.currentPage.type === 'tagging') {
      id = (state.taggingModels[id] || {}).cardId;
    } else if (state.currentPage.type === 'revision') {
      const edgeRev = state.currentPage.order
        .map(id => state.revisionModels[id])
        .find(rev => ['TAGGING', 'RELATIONSHIP'].includes(rev.revisableType));

      if (edgeRev) {
        id = (state[edgeRev.revisableType.toLowerCase() + 'Models'][edgeRev.revisableId] || {}).cardId;
      }
    }
    return state.cardModels[id] || null;
  }
};