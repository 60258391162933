<template>
  <div v-if="enabled" class="blurry-background">
    <div class="blurry-background-art" :style="cssStyles"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    const el = document.createElement('div');
    el.style.cssText = 'filter:blur(2px)';

    return {
      supportsBlur: el.style.length && (document.documentMode === undefined || document.documentMode > 9)
    };
  },
  computed: {
    ...mapState([
      'backgroundImage',
    ]),
    enabled() {
      return this.supportsBlur && this.backgroundImage.url;
    },
    cssStyles() {
      const styles = {};
      const url = this.backgroundImage.url;

      if (url && url[0] === '#') {
        styles.backgroundColor = url;
      } else if (url) {
        styles.backgroundImage = `url(${ url })`;
      }

      if (!this.backgroundImage.full) {
        styles.bottom = '75vh';
      }
      return styles;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

$blur: 15px;

.blurry-background {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  top: $header-height;
  z-index: -1;
}

.blurry-background-art {
  background-color: #222;
  background-position: center top;
  background-repeat: none;
  background-size: cover;
  position: absolute;
  bottom: -($blur * 2);
  left: -($blur * 2);
  right: -($blur * 2);
  top: -($blur * 2);

  // blur filter with forced caching
  -webkit-backface-visibility: hidden;
  -webkit-filter: blur($blur);
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0,0,0);
  backface-visibility: hidden;
  filter: blur($blur);
  perspective: 1000;
  transform: translate3d(0,0,0);

  &:before {
    background: linear-gradient(to bottom, rgba(25,24,31,0.5) 0%, rgba(25,24,31,0.85) 50%, rgba(25,24,31,1) 100%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>