import * as cmd from './commands';
import { buildModels, buildPatches } from './models/factories';
import { defaultsByModelSet, pageDefault } from './models/defaults';
import { Deferred } from '../lib/utils';
import scrollLock from '../lib/scroll_lock';
import ActionSelectManager from '../lib/action_select_manager';

let noticeTimer;
let noticeId = 0;

export default {
  setCurrentTag(state, data) {
    state.currentTag = data;
  },

  [cmd.SET_NOTICE](state, data) {
    clearTimeout(noticeTimer);

    if (!data) {
      state.currentNotice = null;
      return;
    }

    state.currentNotice = {
      id: noticeId++,
      message: data.message,
      type: data.type || 'info',
    };

    const duration = Math.max(2750, 1000 + data.message.length * 50);
    noticeTimer = setTimeout(() => this.commit(cmd.SET_NOTICE, null), duration);
  },

  [cmd.SET_MENU_TAGS](state, data) {
    state.tags = {
      page: data.page || 1,
      perPage: data.perPage || 0,
      results: data.results || [],
      total: data.total || 0,
    };
  },

  [cmd.SET_BREAKOUT](state, params) {
    ActionSelectManager.close();

    // resolve any existing callback, unless cancelled
    const existing = state.currentBreakout;
    if (existing && existing.deferred && !params.cancel) {
      existing.deferred.resolve(params.return || null);
    }

    // open a new dialog
    let dialog = params.dialog;
    if (dialog && dialog.as) {
      dialog.type = 'dialog';
      dialog.deferred = new Deferred();
      state.currentBreakout = dialog;
    }
    // open a new sidebar
    else if (params.sidebar) {
      let sidebar = params.sidebar;

      // store guide page as a separate persistent field
      // allowing the guide to reopen to where it was closed.
      if (sidebar && sidebar.view === 'guide' && sidebar.slug) {
        state.currentGuidePage = sidebar.slug;
      }

      state.currentBreakout = {
        type: 'sidebar',
        view: sidebar.view,
        slug: sidebar.slug,
      };
    }
    // close any existing breakout object
    else {
      state.currentBreakout = null;
    }

    // lock body scrolling when breakout UI is open
    scrollLock(!!state.currentBreakout);
  },

  [cmd.PATCH_USER](state, settings) {
    state.user = Object.assign({}, state.user, settings);
  },

  [cmd.CYCLE_REVISION](state, { id, skipped=false }) {
    state.currentPage.order = state.currentPage.order.filter(i => i !== id);

    if (skipped) {
      // store the most recent skipped revisions so that we don't show them again for a while.
      state.skippedRevisionIds = state.skippedRevisionIds.concat(id).slice(0, 100);
    } else {
      // Otherwise, resolutions do a faux decrement on the user's notification count.
      state.user.reviewableRevisionsCount = Math.max(0, state.user.reviewableRevisionsCount - 1);
    }
  },

  [cmd.REPLACE_MODELS](state, mapping) {
    state.currentPage = mapping.currentPage || Object.assign({}, pageDefault);
    mapping = buildModels(mapping);
    for (let key of Object.keys(defaultsByModelSet)) {
      if (!mapping.hasOwnProperty(key)) mapping[key] = [];
      const modelSet = {};
      let models = mapping[key];
      if (!Array.isArray(models)) models = [models];

      for (let model of models) {
        modelSet[model.id] = model;
      }
      state[key] = modelSet;
    }
  },

  [cmd.SET_MODELS](state, mapping) {
    state.currentPage = mapping.currentPage || Object.assign({}, pageDefault);
    mapping = buildModels(mapping);
    for (let key of Object.keys(defaultsByModelSet)) {
      if (mapping.hasOwnProperty(key)) {
        const modelSet = Object.assign({}, state[key]);
        let models = mapping[key];
        if (!Array.isArray(models)) models = [models];

        for (let model of models) {
          modelSet[model.id] = model;
        }
        state[key] = modelSet;
      }
    }
  },

  [cmd.PATCH_MODELS](state, mapping) {
    if (mapping.currentPage) {
      state.currentPage = mapping.currentPage;
    }
    mapping = buildPatches(mapping);
    for (let key of Object.keys(defaultsByModelSet)) {
      if (mapping.hasOwnProperty(key)) {
        const modelSet = Object.assign({}, state[key]);
        let patches = mapping[key];
        if (!Array.isArray(patches)) patches = [patches];

        for (let patch of patches) {
          const base = modelSet[patch.id] || defaultsByModelSet[key];
          modelSet[patch.id] = Object.assign({}, base, patch);
        }
        state[key] = modelSet;
      }
    }
  },

  [cmd.REMOVE_MODELS](state, mapping) {
    for (let key of Object.keys(defaultsByModelSet)) {
      if (mapping.hasOwnProperty(key)) {
        const modelSet = Object.assign({}, state[key]);
        let refs = mapping[key];
        if (!Array.isArray(refs)) refs = [refs];

        // remove ids from current page
        if (state.currentPage.type === key.split('Models')[0]) {
          const purge = refs.map(ref => ref.id);
          const order = state.currentPage.order.filter(id => !purge.includes(id));
          state.currentPage['order'] = order;
        }

        // then delete model references
        for (let ref of refs) {
          delete modelSet[ref.id];
        }
        state[key] = modelSet;
      }
    }
  }
};
