import * as cmd from '../commands';
import { Deferred } from '../../lib/utils';

export default {
  setBackground({ state }, params) {
    if (!params || typeof params === 'string') {
      params = { url: params };
    }
    state.backgroundImage = {
      url: params.url,
      full: params.full || false,
    };
  },
  setDialog({ state }, dialog) {
    this.commit(cmd.SET_BREAKOUT, 'return' in dialog ? dialog : { dialog });
    const breakout = state.currentBreakout;
    return breakout ? breakout.deferred.promise : null;
  },
  setSidebar(store, sidebar) {
    this.commit(cmd.SET_BREAKOUT, { sidebar });
  },
  setNotice(store, message) {
    this.commit(cmd.SET_NOTICE, { message });
  },
};
