<template>
  <div class="countdown" v-if="remaining">{{ message }}</div>
</template>

<script>
function formatHMS(secs) {
  secs = Math.round(secs);
  const hours   = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - (hours * 3600)) / 60);
  const seconds = `00${ secs - (hours * 3600) - (minutes * 60) }`;
  return [minutes, seconds.substr(seconds.length-2)].join(':');
}

export default {
  props: {
    prefix: { type: String },
    postfix: { type: String },
    timeout: { type: Number, required: true },
  },
  data() {
    return {
      remaining: this.timeout,
      timeoutId: null,
    };
  },
  computed: {
    message() {
      return [this.prefix, formatHMS(this.remaining / 1000), this.postfix].filter(label => !!String(label)).join(' ');
    }
  },
  methods: {
    update() {
      this.remaining = Math.max(0, this.timeout - Date.now());

      if (this.remaining > 0) {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.update, 1000);
      } else {
        this.$emit('timeout', Date.now());
      }
    }
  },
  mounted() {
    this.update();
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.countdown {
  color: $c-gray-500;
  font-size: font-size(-1);
  font-style: italic;
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>