import { graphqlFetch, gql } from './graphql';

const minQueryLength = 2;

const facetOrder = [
  'ILLUSTRATION_TAG',
  'ORACLE_CARD_TAG',
  'PRINTING_TAG',
  'CARD',
  'COLOR',
];

// utility for faceting results
function facet(results) {
  let unclaimed = -1;

  // map matching terms into buckets by type
  const filtered = results.reduce((memo, r) => {
    memo[r.type] = memo[r.type] || [];
    memo[r.type].push(r);
    return memo;
  }, {});

  // assemble counts allocated to each bucket (allowed two each)
  // overages are contributed back to the unclaimed pool
  const counts = facetOrder.reduce((memo, type) => {
    filtered[type] = filtered[type] || []
    memo[type] = Math.min(2, filtered[type].length);
    unclaimed += (2 - memo[type]);
    return memo;
  }, {});

  // Redistribute the unclaimed slots to other facets.
  while (unclaimed > 0) {
    let allocated = false;
    facetOrder.forEach(type => {
      if (unclaimed > 0 && counts[type] < filtered[type].length && counts[type] < 5) {
        counts[type] += 1;
        unclaimed -= 1;
        allocated = true;
      }
    });
    if (!allocated) break;
  }

  // Return a compiled list of the distributed counts for each facet.
  return facetOrder.reduce((memo, type) => {
    return memo.concat(filtered[type].slice(0, counts[type]));
  }, []);
}

// builds a pattern matcher for filtering start-of-word anchors
function matcher(query) {
  query = query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  return new RegExp('(^|\\s)'+query, 'i');
}

// Sorts a cache by descending key length
// then finds an exact key match,
// or longest radix with exhaustive results
function searchCache(cache, key, hitsLimit) {
  return cache.slice()
    .sort((a, b) => b.key.length - a.key.length)
    .find(c => c.key === key || (key.indexOf(c.key) === 0 && c.hits < hitsLimit));
}

export default class SuggestionCache {
  constructor(opts) {
    this._id = 0;
    this.awaitingId = 0;
    this.fulfilledId = 0;
    this.cardCache = [];
    this.taxonomyCache = [];
    this.promised = {};
    this.options = opts || {};
  }

  // Loads and selects cards from Scryfall
  fetchCards(query) {
    query = query.toLowerCase();

    if (query.length < minQueryLength || query.indexOf(':') >= 0) {
      return Promise.resolve([]);
    }

    const scryfallMaxResults = 20;
    const key = `CARD::${ query }`;
    const cached = searchCache(this.cardCache, key, scryfallMaxResults);
    if (cached) {
      return Promise.resolve(cached.results);
    }

    if (!this.promised[key]) {
      this.promised[key] = new Promise(async (resolve, reject) => {
        const { data } = await fetch('https://api.scryfall.com/cards/autocomplete?include_extras=true&q='+encodeURIComponent(query))
          .then(res => res.json())
          .catch(() => {
            delete this.promised[key];
            reject();
          });

        const results = data.filter(name => {
          // filters out art series cards as well
          // as the double sided cards like
          // "Zndrsplt, Eye of Wisdom // Zndrsplt, Eye of Wisdom"
          // that have the same card printed on both
          // sides with different art. The unique art on these
          // can still be selected in the art picker
          // and as a separate print on the search results
          const [first, second] = name.split(" // ");
          return first !== second;
        }).map(n => ({ term: n, type: 'CARD' }));

        this.cardCache.unshift({ key, results, hits: data.length });
        while (this.cardCache.length > 10) this.cardCache.pop();
        delete this.promised[key];
        resolve(results);
      });
    }

    return this.promised[key];
  }

  // Loads a chunk of tagger data for a base type and radix
  fetchTaxonomy(query, type) {
    query = query.toLowerCase();

    if (query.length < minQueryLength) {
      return Promise.resolve([]);
    }

    const taggerMaxResults = 50;
    const key = `${ type }::${ query }`;
    const cached = searchCache(this.taxonomyCache, key, taggerMaxResults);
    if (cached) {
      return Promise.resolve(cached.results);
    }

    if (!this.promised[key]) {
      this.promised[key] = new Promise(async (resolve, reject) => {
        const { data } = await graphqlFetch(gql`
          query Suggest($input: SuggestionInput!) {
            results: suggest(input: $input) {
              aliasOf
              description
              id
              term
              type
              uri
            }
          }
        `, {
          input: {
            query,
            type,
            taggableOnly: this.options.taggableOnly || false
          }
        }).catch(() => {
          delete this.promised[key];
          reject();
        });

        const hits = data.results.filter(r => /_TAG/.test(r.type)).length;
        this.taxonomyCache.unshift({ key, results: data.results, hits });
        while (this.taxonomyCache.length > 5) this.taxonomyCache.pop();
        delete this.promised[key];
        resolve(data.results);
      });
    }

    return this.promised[key];
  }

  // fetches suggestions for a given search string
  // requests are sequenced so results get progressively newer
  // outdated fulfillments are discarded
  fetch(query, type, callback) {
    if (query.length < minQueryLength) {
      this.fulfilledId = this.awaitingId;
      return callback([], true, query);
    }

    this._id += 1;
    const id = this._id;
    const srcs = [];
    this.awaitingId = id;

    if (['ALL', 'ARTWORK', 'CARD'].includes(type)) {
      srcs.push(this.fetchCards(query));
    }

    if (!['ARTWORK', 'CARD'].includes(type)) {
      srcs.push(this.fetchTaxonomy(query, type));
    }

    Promise.all(srcs).then(results => {
      // skip requests older than the last fulfillment
      if (id <= this.fulfilledId) return;
      this.fulfilledId = id;

      const pattern = matcher(query);
      results = results.reduce((memo, res) => memo.concat(res.filter(r => pattern.test(r.term))), []);

      if (type === 'ALL') {
        results = facet(results);

        if (query.length >= minQueryLength) {
          results.unshift({
            term: /^#[a-f0-9]+/i.test(query) ? 'Search hex color' : 'Search Scryfall...',
            type: 'SEARCH',
            uri: query,
            placeholder: true,
          });
        }
      } else {
        results = results.slice(0, 5);
      }

      // detect when we're all caught up,
      // then reset the promise cache.
      const finished = (id === this.awaitingId);
      if (finished) this.promised = {};

      callback(results, finished, query);
    });
  }

  async fetchVariants(name, type) {
    const asArt = type === 'ARTWORK';
    const options = {};
    const addOption = (obj, card) => {
      const id = asArt ? obj.illustration_id : card.oracle_id;
      options[id] = {
        id,
        type,
        term: `${ obj.name } (${ card.set.toUpperCase() })`,
        uri: obj.image_uris[asArt ? 'art_crop' : 'normal'],
        url: card.scryfall_uri,
      };
    };

    const uniq = asArt ? 'art' : 'cards';
    const url = `https://api.scryfall.com/cards/search?q=${ encodeURIComponent(`!"${name}"`) }&unique=${ uniq }&include_extras=true&include_multilingual=true`;
    const { data } = await fetch(url).then(res => res.json()).catch(() => null);
    (data || []).forEach(card => {
      // we don't want to display art series cards as variants
      if (card.layout === 'art_series') {
        return;
      }

      if (card.image_uris) {
        addOption(card, card);
      } else if (card.card_faces && card.card_faces[0].image_uris) {
        card.card_faces.forEach(face => addOption(face, card));
      }
    });

    return Promise.resolve(Object.values(options));
  }
}
