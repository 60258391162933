<template>
  <div class="pagination" v-if="lastPage > 1">
    <span v-for="ref, index in paginatedSet" :key="index">
      <strong v-if="ref === page">{{ thousands(ref) }}</strong>
      <router-link v-else-if="Number.isInteger(ref) && baseUrl" rel="nofollow" :to="pageLink(ref)">{{ thousands(ref) }}</router-link>
      <a v-else-if="Number.isInteger(ref)" href="#" @click.prevent="$emit('update:modelValue', ref)">{{ thousands(ref) }}</a>
      <span v-else>{{ ref }}</span>
    </span>
  </div>
</template>

<script>
import { thousands } from '../../lib/utils';

export default {
  props: {
    baseUrl: { type: Object },
    page: { type: Number, default: 1 },
    modelValue: { type: Number, default: 1 },
    perPage: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
  },
  emit: ['update:modelValue'],
  computed: {
    paginatedSet() {
      return this.pagination(this.page, this.total, this.perPage);
    },
    lastPage() {
      return Math.ceil(this.total / this.perPage) || 0;
    }
  },
  methods: {
    thousands,
    pageLink(ref) {
      const query = Object.assign({}, this.baseUrl.query || {});
      if (ref > 1) {
        query.page = ref;
      } else {
        delete query.page;
      }
      return Object.assign({}, this.baseUrl, { query });
    },
    // unceremoniously ripped off from
    // https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
    pagination(current, total, perPage) {
      var last = this.lastPage,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.pagination {
  display: flex;
  justify-content: center;

  > span {
    margin: 0 spacing(1);
  }

  a, strong {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: $box-corner-sm;
    display: block;
    padding: 2px spacing(2);
  }

  a {
    border: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.5);
      color: white;
    }
  }

  strong {
    border: 1px solid white;
  }

  &.light-mode {
    strong {
      background-color: rgba($cGrape, 0.5);
      border: 1px solid rgba($cGrape, 0.75);
      color: white;
    }

    a {
      background-color: rgba($cGrape, 0.05);
      border: 1px solid rgba($cGrape, 0.2);
      &:hover {
        border: 1px solid rgba($cGrape, 0.5);
        color: $cGrape;
      }
    }
  }
}
</style>
