import { debounce } from './utils';

const configProp = '$_querySync';

const defaultSyncConfig = {
  passthroughQuery: false,
  params: {},
  beforeInit() {},
  beforeRead() {},
  afterRead() {},
  beforeWrite() {},
  afterWrite() {},
};

const defaultParamConfig = {
  read(query) {},
  write(query) {},
};

function computedQueryParams() {
  const config = this[configProp];
  if (!config) return {};

  let params = {};
  for (let key of Object.keys(config.params)) {
    config.params[key].write.call(this, params);
  }

  return params;
}

export default {
  install(app) {
    const globalProperties = app.config.globalProperties;

    for (let key of ['$router', '$route']) {
      if (!globalProperties[key]) {
        throw('Vue Router must be installed before query sync');
      }
    }

    globalProperties[configProp] = null;

    // Reads fields from the query and assigns them to the view.
    globalProperties.$readQuery = function() {
      const config = this[configProp];
      if (config && config.active) {
        config.beforeRead.call(this);

        for (let key of Object.keys(config.params)) {
          config.params[key].read.call(this, this.$route.query);
        }

        config.afterRead.call(this);
      }
    };

    // Writes fields from the view into the query.
    globalProperties.$writeQuery = debounce(function() {
      const config = this[configProp];
      if (config && config.active) {
        let query = config.passthroughQuery ? Object.assign({}, this.$route.query) : {};
        for (let key of Object.keys(config.params)) {
          config.params[key].write.call(this, query);
        }

        config.beforeWrite.call(this);
        this.$router.replace({ query }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
        config.afterWrite.call(this);
      }
    });

    app.mixin({
      // Read configuration from querySync options,
      // build operations with defaulted presents.
      beforeCreate() {
        let options = this.$options.querySync;
        if (options) {
          const config = Object.assign({}, defaultSyncConfig, options);
          for (let key of Object.keys(config.params)) {
            config.params[key] = Object.assign({}, defaultParamConfig, config.params[key]);
          }

          this[configProp] = config;

          // add a "queryParams" computed that provides rendered data
          this.$options.computed = this.$options.computed || {};
          if (!this.$options.computed.hasOwnProperty('queryParams')) {
            this.$options.computed.queryParams = computedQueryParams;
          }
        }
      },

      // read initial configuration from the URL
      created() {
        const config = this[configProp];
        if (config) {
          config.active = true;
          config.beforeInit.call(this);
          this.$readQuery();
        }
      },

      // Setup watchers and pull initial route.
      mounted() {
        const config = this[configProp];
        if (config) {
          for (let key of Object.keys(config.params)) {
            this.$watch(key, this.$writeQuery);
          }

          this.$watch('$route.query', this.$readQuery);
          this.$writeQuery();
        }
      },

      // Setup watchers and pull initial route.
      beforeUnmount() {
        const config = this[configProp];
        if (config) {
          config.active = false;
        }
      }
    });
  }
};
