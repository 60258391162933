export const SET_BREAKOUT = 'setBreakout';
export const SET_NOTICE = 'setNotice';
export const SET_MODELS = 'setModels';
export const SET_MENU_TAGS = 'setMenuTags';
export const PATCH_USER = 'patchUser';
export const PATCH_MODELS = 'patchModels';
export const DELETE_MODELS = 'deleteModels';
export const REPLACE_MODELS = 'replaceModels';
export const REMOVE_MODELS = 'removeModels';
export const CYCLE_REVISION = 'cycleRevision';
