// fetches a complete paginated set list from the Scryfall API
async function fetchSetList(uri, list=[]) {
  uri = /^https:/.test(uri) ? uri : 'https://api.scryfall.com/cards/search?order=set&q='+encodeURIComponent('++e:'+uri);
  const res = await fetch(uri).then(res => res.json());
  const cards = res.data.map(c => [c.name, c.collector_number]);
  list = list.concat(cards);
  return res.next_page ? fetchSetList(res.next_page, list) : Promise.resolve(list);
}

// fetches a set list from local cache, or seeds from Scryfall API
async function cachedSetList(setCode) {
  const cachedNS = 'scryfall.tagger.setlist';
  const cachedRaw = localStorage.getItem(cachedNS);
  const cachedData = /^\{.+\}$/.test(cachedRaw) ? JSON.parse(cachedRaw) : {};
  let list = cachedData[setCode];

  if (!list) {
    list = await fetchSetList(setCode);
    localStorage.setItem(cachedNS, JSON.stringify({ [setCode]: list }));
  }

  return Promise.resolve(list);
}

function cardRef(setCode, ref) {
  return {
    set: setCode,
    collectorNumber: ref ? ref[1] : null,
    name: ref ? ref[0] : null,
  };
}

// provides the two adjacent card references for a set code and number
export default async function(setCode, currentNum) {
  const list = await cachedSetList(setCode).catch(e => []);
  for (let i=0; i < list.length; i += 1) {
    if (currentNum === list[i][1]) {
      return Promise.resolve([
        cardRef(setCode, list[i-1]),
        cardRef(setCode, list[i+1])
      ]);
    }
  }

  return Promise.resolve([]);
};