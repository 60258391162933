<template>
  <div class="suggestion-input">
    <label :for="uid" v-if="!id" class="vh">{{ placeholder }}</label>
    <div class="vh" role="status" aria-live="polite">{{ screenreaderMessage }}</div>
    <input class="tag-input-field" type="text" ref="input"
      v-model="queryInput"
      @keydown="onKeyDown"
      @focus="onFocus"
      :id="id || uid"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ '--has-input': !!query }"
      name="suggestion"
      autocapitalize="none"
      autocorrect="off"
      autocomplete="off">
    <div class="suggestion-input-utils">
      <span class="loading" v-if="loading"></span>
      <button class="tag-input-clear" v-if="clearable && queryInput" type="button" tabindex="-1" @click.prevent="onClear">
        <svg aria-hidden="true"><use xlink:href="#icons-close"></use></svg>
      </button>
    </div>
    <ul ref="menuOptions" class="suggestion-menu" v-if="menuOpen" @mouseover="onFocusItem" @click.prevent="onSelectItem">
      <li v-if="!results.length"><em>No results</em></li>
      <li v-for="(item, index) in results" :key="index" :class="{ '--is-active': focusedIndex === index }" :data-index="index">
        <div class="suggestion-row">
          <svg v-if="facetedSearch"><use :xlink:href="`#icons-${ iconFor(item) }`"></use></svg>
          <span class="label">{{ item.term }} <em v-if="item.aliasOf">&mdash; {{ item.aliasOf }}</em></span>
          <em class="type">{{ labelFor(item) }}</em>
        </div>
        <div class="suggestion-row" v-if="descriptions && item.description">
          <em class="description">{{ renderPlainText(item.description) }}</em>
        </div>
      </li>
    </ul>
    <div class="suggestion-menu variant-menu" v-if="variantOptions">
      <div class="variant-roll" :style="{ width: variantRollWidth }">
        <div v-for="opt in variantOptions" :class="`${ opt.type.toLowerCase() }-item`">
          <a :class="opt.type.toLowerCase()" :href="opt.url" :title="opt.term" @click.prevent="onSelectVariant(opt)">
            <img :src="opt.uri" :alt="opt.term">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pubsub from 'tiny-emitter/instance';
import { closestAttr, renderPlainText, Deferred, uid } from '../../lib/utils';
import SuggestionCache from '../../lib/suggestion';

const settingsByFacet = {
  ARTWORK:          { p: 'related artwork’s card name', i: 'artwork',  l: 'artwork' },
  CARD:             { p: 'related card’s name',         i: 'card',     l: 'card' },
  COLOR:            { p: 'color name',                  i: 'palette',  l: 'color' },
  ILLUSTRATION_TAG: { p: 'artwork tag name',            i: 'tag',      l: 'artwork tag' },
  ORACLE_CARD_TAG:  { p: 'card tag name',               i: 'tag',      l: 'card tag' },
  PRINTING_TAG:     { p: 'printing tag name',           i: 'tag',      l: 'edition tag' },
  SEARCH:           { p: 'search tags or scryfall',     i: 'scryfall', l: 'search syntax' },
};

export default {
  props: {
    type:  { type: String, required: true },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    descriptions: { type: Boolean, default: false },
    taggableOnly: { type: Boolean, default: false },
    softSubmit: { type: Boolean, default: false },
    focusEvent: { type: String },
    id: { type: String },
  },
  emits: ['submit', 'input', 'clear'],
  data() {
    return {
      suggest: new SuggestionCache({ taggableOnly: this. taggableOnly }),
      menuOpen: false,
      loading: false,
      query: '',
      screenreaderMessage: '',
      results: [],
      focusedIndex: -1,
      variantOptions: null,
      variantSelection: null,
      uid: uid(),
    };
  },
  watch: {
    type() {
      this.update('');
    },
    focusedItem(item) {
      let message = '';

      if (item) {
        message = item.term;

        if (item.aliasOf) {
          message += ` (alias for ${item.aliasOf})`;
        }

        message += ` - ${this.labelFor(item)} (${this.focusedIndex + 1}/${this.results.length})`;

        if (this.focusedIndex === 0) {
          message = `There are ${this.results.length} suggestions. The currently selected tag is ${message}, press return to select it or continue typing to display new sugestions. Use the up and down arrows to browse. Touch device users, explore by touch or with swipe gestures.`;
        }
      }

      this.screenreaderMessage = message;
    }
  },
  computed: {
    queryInput: {
      get() {
        return this.query;
      },
      set(val) {
        this.update(val);
        this.$emit('input', this.query);
      }
    },
    facetedSearch() {
      return this.type === 'ALL';
    },
    focusedItem() {
      return this.results[this.focusedIndex] || null;
    },
    placeholder() {
      const type = (this.type === 'ALL') ? 'SEARCH' : this.type;
      return settingsByFacet[type].p;
    },
    isOpen() {
      return this.menuOpen || this.variantOptions;
    },
    variantRollWidth() {
      if (this.variantOptions) {
        const itemW = this.type === 'ARTWORK' ? 175 : 115;
        return (this.variantOptions.length * (itemW + 8)) + 'px';
      }
      return 0;
    }
  },
  methods: {
    renderPlainText,

    iconFor(item) {
      return settingsByFacet[item.type].i;
    },

    labelFor(item) {
      return settingsByFacet[item.type].l;
    },

    focusItemByIndex(index) {
      this.focusedIndex = Math.max(0, Math.min(index, this.results.length-1));
    },

    focus() {
      this.$refs.input.focus();
    },

    clear() {
      this.update(null);
      this.$emit('clear');
    },

    update(val, suggest=true) {
      val = val || '';

      // abort if nothing changed
      if (this.query === val) return;

      // assign the new value
      this.variantOptions = null;
      this.variantSelection = null;
      this.query = val;
      if (!suggest) return;

      this.loading = true;
      this.suggest.fetch(val, this.type, (results, allclear) => {
        this.loading = !allclear;
        this.results = results;
        this.focusedIndex = results.length ? 0 : -1;
        this.menuOpen = !!results.length;
      });
    },

    // resolves a selection value for the input.
    // - ARTWORK and CARD selections open a variant resolution process.
    // - All other types lookup an option for the current query, or build a custom result.
    resolve() {
      if (['ARTWORK', 'CARD'].includes(this.type) && !this.variantSelection) {
        this.variantSelection = new Deferred();
        this.loading = true;
        this.suggest.fetchVariants(this.query, this.type).then(options => {
          this.loading = false;
          if (options.length > 1) {
            this.variantOptions = options;
          } else if (options.length === 1) {
            this.variantSelection.resolve(options[0]);
          } else {
            this.variantSelection.reject(`no Scryfall card named "${ this.query }"`);
          }
        });
      }

      if (this.variantSelection) {
        return this.variantSelection.promise;
      }

      return Promise.resolve(this.results.find(r => r.term === this.query) || {
        id: null,
        term: this.query,
        type: this.type,
        uri: null,
      });
    },

    submit() {
      if (this.facetedSearch) {
        this.$emit('submit', this.focusedItem);
        this.close();
        if (this.focusedItem.type !== 'SEARCH') {
          this.update('');
        }
        return;
      }

      if (this.menuOpen && this.focusedItem) {
        this.query = this.focusedItem.term;
        this.close(true);
        this.screenreaderMessage = `Selected ${this.query}. Press return to confirm.`;
        if (!this.softSubmit) {
          return this.resolve();
        }
      }

      this.resolve().then(value => {
        if (value) {
          this.$emit('submit', value);
          this.close(true);
        }
      });
    },

    close(refocus=false) {
      this.menuOpen = false;
      this.variantOptions = null;
      if (refocus) {
        this.focus();
      }
    },

    onFocusItem(evt) {
      const el = closestAttr(evt.target, 'data-index');
      if (el) this.focusItemByIndex(Number(el.getAttribute('data-index')));
    },

    onSelectItem(evt) {
      this.onFocusItem(evt);
      this.submit();
    },

    onSelectVariant(evt) {
      if (this.variantSelection) {
        this.variantSelection.resolve(evt);
        this.submit();
      }
    },

    onKeyDown(evt) {
      const halt = () => evt.preventDefault();

      switch (evt.which) {
        case 9: // TAB
          return this.close();
        case 13: // ENTER
          halt();
          return this.submit();
        case 27: // ESC
          halt();
          return this.close();
        case 38: // UP
          halt();
          return this.focusItemByIndex(this.focusedIndex - 1);
        case 40: // DOWN
          halt();
          return this.focusItemByIndex(this.focusedIndex + 1);
      }
    },

    onClickOut(evt) {
      if (this.isOpen && !this.$el.contains(evt.target)) {
        this.close();
      }
    },

    // hack for app responding to deeply-nested input focus.
    // facilitates card page header matching focused form type.
    onFocus(evt) {
      if (this.focusEvent) {
        pubsub.emit(this.focusEvent);
      }
    },

    onClear() {
      this.clear();
      this.focus();
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOut);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClickOut);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.suggestion-input {
  position: relative;

  input.--has-input {
    padding-right: 35px;
  }

  input:disabled {
    cursor: not-allowed;
  }

  &-utils {
    position: absolute;
    right: spacing(2);
    top: 50%;
    transform: translateY(-50%);
  }

  .loading {
    font-size: 14px;
  }
}

.suggestion-menu {
  background-color: white;
  border: 1px solid $c-gray-400;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  left: 0;
  list-style: none;
  line-height: 1.25em;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 10000;

  .label, .description {
    flex-grow: 1;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    em {
      padding-left: 5px;
    }
  }

  .type {
    white-space: nowrap;
  }

  em {
    color: #777;
    font-size: font-size(-1);
    font-weight: normal;
  }

  svg {
    fill: #777;
    flex: none;
    height: 1em;
    margin-right: 0.5em;
    width: 1em;
  }

  li {
    .suggestion-row {
      display: flex;
      align-items: center;

      + .suggestion-row {
        margin-top: spacing(1);
      }
    }

    border: 1px solid transparent;
    border-bottom-color: #efefef;
    color: $cBody;
    justify-content: space-between;
    font-weight: bold;
    padding: 0.5em 1em;
  }

  li.--is-active {
    background-color: rgba($cGrape, 0.12);
    border-color: rgba($cGrape, 0.4);
    color: $cGrape;

    svg {
      fill: $cGrape;
    }
  }
}

.variant-menu {
  overflow-x: scroll;
  overflow-y: hidden;

  .variant-roll {
    display: flex;
    padding: spacing(2) 0;
    padding-right: spacing(2);

    .card-item {
      margin-left: spacing(2);
      width: 115px;
    }

    .artwork-item {
      margin-left: spacing(2);
      width: 175px;
    }
  }

  a:hover, a:focus {
    box-shadow: 0 0 3px 1px $cGrape;
  }
}
</style>
