<template>
  <main>
    <page-status v-if="loading" :error="error"/>
    <template v-else>
      <h1>
        <template v-if="card.twoSided && !card.backside">
          <span>{{ card.sideNames[0] }}</span> //
          <router-link rel="nofollow" :to="{ name: 'card', params: { set: card.set, number: card.collectorNumber, back: 'back' } }">{{ card.sideNames[1] }}</router-link>
        </template>
        <template v-else-if="card.twoSided && card.backside">
          <router-link rel="nofollow" :to="{ name: 'card', params: { set: card.set, number: card.collectorNumber } }">{{ card.sideNames[0] }}</router-link> //
          <span>{{ card.sideNames[1] }}</span>
        </template>
        <template v-else>
          {{ card.name }}
        </template>
        <div class="scryfall-link-wrapper">
          <scryfall-link :url="card.scryfallUrl" />
        </div>
      </h1>

      <div class="light-mode card-layout">
        <card-image class="card-layout--image" :card="card"/>
        <div class="card-layout--tagging">
          <access-banner v-if="!maySubmitContent"/>

          <section v-if="card.illustrationId">
            <h2 class="section-title"><svg><use xlink:href="#icons-artwork"></use></svg> Artwork</h2>
            <router-link v-if="mayManageEdges" class="admin-ref" :to="toEdges(card.illustrationId)">
              {{ card.illustrationId.split('-').shift() }}
            </router-link>
            <tagging-form v-if="maySubmitContent" subjectType="ILLUSTRATION" :subjectId="card.illustrationId"/>
            <tagging-list subjectType="ILLUSTRATION" :taggings="artworkTaggings" :relationships="artworkRelationships"/>
            <tagging-ancestors :tags="artworkAncestorTags"/>
            <tagging-list
              class="card-layout--hidden" v-if="mayManageEdges && (hiddenArtworkTaggings.length || hiddenArtworkRelationships.length)"
              subjectType="ILLUSTRATION" :taggings="hiddenArtworkTaggings" :relationships="hiddenArtworkRelationships"/>
          </section>
          <section v-else>
            Artwork tags are not applicable for this card. If you believe this to be an error, <a :href="feedbackLink" target="_blank">report it</a>.
          </section>

          <section>
            <h2 class="section-title"><svg><use xlink:href="#icons-card"></use></svg> Card</h2>
            <router-link v-if="mayManageEdges" class="admin-ref" :to="toEdges(card.oracleId)">
              {{ card.oracleId.split('-').shift() }}
            </router-link>
            <tagging-form v-if="maySubmitContent" subjectType="ORACLE_CARD" :subjectId="card.oracleId"/>
            <tagging-list subjectType="ORACLE_CARD" :taggings="cardTaggings" :relationships="cardRelationships"/>
            <tagging-ancestors :tags="cardAncestorTags"/>
            <tagging-list
              class="card-layout--hidden" v-if="mayManageEdges && (hiddenCardTaggings.length || hiddenCardRelationships.length)"
              subjectType="ORACLE_CARD" :taggings="hiddenCardTaggings" :relationships="hiddenCardRelationships"/>
          </section>

          <section v-if="showPrintTaggings">
            <h2 class="section-title"><svg><use xlink:href="#icons-print"></use></svg> Printing</h2>
            <tagging-form v-if="maySubmitAdvancedData" subjectType="PRINTING" :subjectId="card.id"/>
            <tagging-list subjectType="PRINTING" :taggings="printTaggings" :relationships="[]"/>
            <tagging-ancestors :tags="printAncestorTags"/>
          </section>
          <section v-else-if="maySubmitAdvancedData">
            <a href="#" @click.prevent="printsExpanded=true">+ printing tags</a>
          </section>
        </div>
      </div>

      <adjacent-cards :set="set" :number="number"/>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccessBanner from '../components/ui/access-banner.vue';
import AdjacentCards from '../components/card/adjacent-cards.vue';
import CardImage from '../components/card/card-image.vue';
import ScryfallLink from '../components/ui/scryfall-link.vue';
import TaggingForm from '../components/card/tagging-form.vue';
import TaggingList from '../components/card/tagging-list.vue';
import TaggingAncestors from '../components/card/tagging-ancestors.vue';

function mapRelationship(rel, ids) {
  if (ids.includes(rel.subjectId)) {
    return rel;
  } else if (ids.includes(rel.relatedId)) {
    return Object.assign({}, rel, {
      classifier: rel.classifierInverse,
      subjectId: rel.relatedId,
      relatedId: rel.subjectId,
      subjectName: rel.relatedName,
      relatedName: rel.subjectName,
      name: rel.subjectName
    });
  }
  return null;
}

// hidden tags are rejected or require moderation
const visibleStatus = /^GOOD/;
const hiddenStatus = /^(PENDING|REJECTED)/;

export default {
  components: {
    AccessBanner,
    AdjacentCards,
    CardImage,
    ScryfallLink,
    TaggingForm,
    TaggingList,
    TaggingAncestors,
  },
  props: {
    set: { type: String, required: true },
    number: { type: String, required: true },
    back: { type: Boolean },
  },
  data() {
    return {
      error: null,
      id: null,
      printsExpanded: false,
    }
  },
  computed: {
    ...mapGetters([
      'mayManageEdges',
      'maySubmitContent',
      'maySubmitAdvancedData',
    ]),
    loading() {
      return !this.id || !this.card;
    },
    showPrintTaggings() {
      return this.printTaggings.length || (this.maySubmitAdvancedData && this.printsExpanded);
    },
    card() {
      return this.$store.state.cardModels[this.id];
    },
    relationships() {
      const ids = [this.card.oracleId, this.card.illustrationId];
      return Object.values(this.$store.state.relationshipModels)
        .map(r => mapRelationship(r, ids))
        .filter(r => !!r)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    taggings() {
      const ids = [this.card.id, this.card.oracleId, this.card.illustrationId];
      return Object.values(this.$store.state.taggingModels)
        .filter(t => ids.includes(t.subjectId))
        .map(t => ({ tagging: t, tag: this.$store.state.tagModels[t.relatedId] }))
        .filter(t => !!t.tag);
    },
    artworkTaggings() {
      return this.collectTaggings('illustrationId');
    },
    hiddenArtworkTaggings() {
      return this.collectTaggings('illustrationId', true);
    },
    cardTaggings() {
      return this.collectTaggings('oracleId');
    },
    hiddenCardTaggings() {
      return this.collectTaggings('oracleId', true);
    },
    printTaggings() {
      return this.collectTaggings('printingId');
    },
    artworkRelationships() {
      return this.collectRelationships('illustrationId');
    },
    hiddenArtworkRelationships() {
      return this.collectRelationships('illustrationId', true);
    },
    cardRelationships() {
      return this.collectRelationships('oracleId');
    },
    hiddenCardRelationships() {
      return this.collectRelationships('oracleId', true);
    },
    artworkAncestorTags() {
      return this.collectAncestorTags(this.artworkTaggings);
    },
    cardAncestorTags() {
      return this.collectAncestorTags(this.cardTaggings);
    },
    printAncestorTags() {
      return this.collectAncestorTags(this.printTaggings);
    },
    feedbackLink() {
      return `https://scryfall.com/contact?topic=tagger&problem_uri=${window.location.href}&title="${this.card.name}"%20missing%20art%20tags`
    }
  },
  methods: {
    ...mapActions([
      'fetchCard',
      'setBackground'
    ]),
    collectTaggings(key, hidden=false) {
      return this.taggings.filter(t => t.tagging.foreignKey === key)
        .filter(t => {
          return hidden ?
            hiddenStatus.test(t.tagging.status) || hiddenStatus.test(t.tag.status) :
            visibleStatus.test(t.tagging.status) && visibleStatus.test(t.tag.status);
        })
        .sort((a, b) => a.tag.name.localeCompare(b.tag.name));
    },
    collectRelationships(key, hidden=false) {
      return this.relationships.filter(r => r.foreignKey === key)
        .filter(r => hidden ? hiddenStatus.test(r.status) : visibleStatus.test(r.status));
    },
    collectAncestorTags(collection) {
      const tags = collection
        .map(({ tag }) => tag.ancestorTags)
        .flat()
        .reduce((acc, tag) => {
          acc[tag.id] = acc[tag.id] || tag;
          return acc;
        }, {});

      return Object.values(tags).sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    this.fetchCard({
      set: this.set,
      number: this.number,
      back: this.back,
      moderatorView: this.mayManageEdges,
    })
      .then(data => {
        this.id = data.id;
        this.setBackground({ url: this.card.artImageUrl, full: true });
        this.$router.pageview({
          title: [`${ this.card.name } (${ this.set.toUpperCase() } #${ this.number })`, 'Card'],
          scroll: true,
        });
      })
      .catch(err => {
        this.error = err.message;
      });
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

@media (min-width: $card-layout-2x2) {
  .card-layout {
    align-items: flex-start;
    display: flex;
  }

  .card-layout--image {
    margin-left: spacing(4);
    order: 2;
    max-width: 575px;
    width: 45%;
  }

  .card-layout--tagging {
    flex-grow: 1;
  }
}

.card-layout--tagging {
  background-color: white;
  border-radius: $box-corner;
  padding: spacing(3);

  section + section {
    margin-top: spacing(5);
  }
}

.card-layout--hidden {
  background-color: $c-danger-100;
  border-radius: $box-corner-sm;
  margin-top: spacing(4);
  padding: spacing(1);

  :deep(.tag-row) {
    border-bottom-color: shade($c-danger-100, 8%);
  }
}

h2 {
  display: inline-block;
  margin: 0 0 spacing(3);

  + .admin-ref {
    margin-left: spacing(2);
    position: relative;
    top: -0.25em;
  }
}

.scryfall-link-wrapper {
  display: inline-block;
}
</style>
