<template>
  <section>
    <div v-if="!loaded">Loading...</div>
    <form v-else @submit.prevent="submit">
      <revisable-header prefix="Revise" :revisableId="revisableId" :revisableType="revisableType"/>

      <div class="tag-form-row">
        <label>Reason <guide-link slug="change-requests"/>
          <select class="tag-input-field" v-model="revisionStatus">
            <option value="PEER_REVIEW">This {{ revisableType.toLowerCase() }} is inaccurate</option>
            <option value="ADMIN_REVIEW">This {{ revisableType.toLowerCase() }} is offensive or inappropriate</option>
            <option value="ADMIN_REVIEW:tag" v-if="isTagging">Tag as a whole (all taggings) requires administrative action</option>
          </select>
        </label>
      </div>

      <div class="tag-form-row">
        <label>Describe the issue
          <textarea class="tag-input-field" v-model="reporterNote"></textarea>
        </label>
      </div>

      <div v-if="error" class="tag-form-row errors">{{ error }}</div>

      <div class="tag-form-row">
        <button class="button-action button-primary" type="submit" @click.prevent="submit">Create change request</button>
        <button class="button-action" type="button" @click.prevent="close()">Cancel</button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapActions} from 'vuex';
import Revisable from '../mixins/revisable';
import RevisableHeader from '../ui/revisable-header.vue';
import { renderError } from '../../lib/utils';

export default {
  mixins: [Revisable],
  components: {
    RevisableHeader
  },
  data() {
    return {
      error: '',
      loaded: false,
      revisionStatus: 'PEER_REVIEW',
      reporterNote: '',
    };
  },
  computed: {
    card() {
      return this.$store.state.cardModels[this.revisable.cardId] || {};
    },
    tag() {
      return this.isTag ? this.revisable : this.$store.state.tagModels[this.revisable.relatedId] || {};
    }
  },
  methods: {
    ...mapActions([
      'createRevision',
      'fetchRevisable',
    ]),
    submit() {
      this.error = '';
      const [status, scope] = this.revisionStatus.split(':');
      const isTag = scope === 'tag';
      this.createRevision({
        mutationName: 'status',
        mutationValue: 'rejected',
        reporterNote: this.reporterNote,
        revisableId: isTag ? this.tag.id : this.revisableId,
        revisableType: isTag ? 'TAG' : this.revisableType,
        status,
      })
        .then(() => this.close())
        .catch(err => this.error = renderError(err.message));
    }
  },
  mounted() {
    this.fetchRevisable({
      type: this.revisableType,
      id: this.revisableId,
    })
      .then(() => this.loaded = true)
      .catch(err => this.error = err);
  }
};
</script>
