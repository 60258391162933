import { graphqlFetch, gql } from '../../lib/graphql';
import { baseCardAttrs } from './schema';
import * as cmd from '../commands';

export default {
  async fetchEdge({ state }, params) {
    // params.id
    const { data } = await graphqlFetch(gql`
      query FetchEdge($id: ID!) {
        edge(id: $id) {
          annotation
          id
          metadata
          type
          weight
        }
      }
    `, params);

    const namespace = `${ data.edge.type.toLowerCase() }Models`;
    this.commit(cmd.PATCH_MODELS, { [namespace]: data.edge });
    return Promise.resolve(data.edge);
  },

  async updateEdge({ state }, params) {
    const { id, ...input } = params;
    const { data } = await graphqlFetch(gql`
      mutation UpdateEdge($id: ID!, $input: EdgeUpdateInput!) {
        result: edgeUpdate(id: $id, input: $input) {
          edge {
            annotation
            id
            metadata
            type
            weight
          }
        }
      }
    `, { id, input });

    const edge = data.result.edge;
    this.commit(cmd.PATCH_MODELS, { [`${ edge.type.toLowerCase() }Models`]: edge });
    this.commit(cmd.SET_NOTICE, { message: `${ edge.type.toLowerCase() } was updated` });
    return Promise.resolve(edge);
  },

  async deleteEdge({ state }, params) {
    // params.id
    const { data } = await graphqlFetch(gql`
      mutation DeleteEdge($id: ID!) {
        result: edgeDelete(id: $id) {
          edge {
            id
            type
          }
        }
      }
    `, { id: params.id });

    if (params.beforeCommit) {
      params.beforeCommit();
    }

    const edge = data.result.edge;
    this.commit(cmd.REMOVE_MODELS, { [`${ edge.type.toLowerCase() }Models`]: edge });
    this.commit(cmd.SET_NOTICE, { message: `${ edge.type.toLowerCase() } was deleted` });
    return Promise.resolve(edge);
  },

  async searchEdges(store, input) {
    const { data } = await graphqlFetch(gql`
      query SearchEdges($input: EdgeSearchInput!) {
        edges(input: $input) {
          page
          perPage
          results {
            classifier
            subjectId
            subjectName
            foreignKey
            id
            namespace
            relatedId
            relatedName
            type
          }
          total
        }
      }
    `, { input });

    this.commit(cmd.SET_MENU_TAGS, data.edges);
    return Promise.resolve(data.edges);
  },

  async fetchEdgeCard({ state }, params) {
    // params.id
    const { data } = await graphqlFetch(gql`
      query FetchEdgeCard($id: ID!) {
        edge(id: $id) {
          classifier
          card {
            ...CardAttrs
          }
          name
        }
      }
      ${ baseCardAttrs }
    `, params);

    return Promise.resolve(data.edge);
  }
};
