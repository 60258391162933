<template>
  <main class="--narrow">
    <page-status v-if="!loaded" :error="error"/>
    <template v-else>
      <h1>{{ username }}</h1>
      <p>{{ user.role }} <span v-if="loggedInUser.id === user.id">| <a href="/signout" rel="nofollow">signout</a></span></p>

      <form v-if="$store.getters.mayManageUsers" class="permissions">
        <strong>Permissions</strong>
        <div><label><input type="checkbox" v-model="permissions" value="MANAGE_TAGS"> Manage tags and heirarchies</label></div>
        <div><label><input type="checkbox" v-model="permissions" value="MANAGE_TAGGINGS"> Manage taggings and relationships</label></div>
        <div><label><input type="checkbox" v-model="permissions" value="MANAGE_JOBS"> Manage jobs</label></div>
        <div><label><input type="checkbox" v-model="permissions" value="SUBMIT_PRINT_TAGS"> Add printing tags</label></div>
        <div><label><input type="checkbox" v-model="permissions" value="SUBMIT_CONTENT"> Submit user-generated content</label></div>
      </form>

      <access-banner v-if="!$store.getters.maySubmitContent"/>
      <template v-else>
        <h2>Contributions</h2>

        <p><em>{{ thousands(user.contributionsCount) }} submissions</em></p>
        <p><progress-bar :percentage="user.contributionsPercentile" :label="`${ ordinal(user.contributionsPercentile) } percentile`"></progress-bar></p>

        <div class="light-mode tagging-card" v-for="t in groupedEdges" :key="t.id">
          <router-link class="card" rel="nofollow" :to="toCard(t.card)">
            <img :src="t.card.cardImageUrl" :alt="t.card.name">
          </router-link>
          <div class="tagging-tags">
            <strong>{{ t.card.name }}</strong> <em>&mdash; {{ relativeTime(t.edges[0].createdAt) }}</em>
            <div class="taggings">
              <div class="tag-row" v-for="(edge, index) in t.edges" :key="index">
                <template v-if="edge.type === 'RELATIONSHIP'">
                  <tagging-icon :code="edge.classifier"/>
                  <router-link rel="nofollow" :to="toRelated(edge)" :data-hovercard="toHover(edge, edge.relatedId)">{{ edge.name }}</router-link>
                </template>
                <template v-else>
                  <tagging-icon :code="getTag(edge).namespace"/>
                  <router-link rel="nofollow" :to="toTag(getTag(edge))">{{ getTag(edge).name }}</router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
        <pagination-nav :page="currentPage.page" :perPage="currentPage.perPage" :total="currentPage.total" :baseUrl="toUser(user)"/>
      </template>

    </template>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ordinal, thousands } from '../lib/utils';
import relativeTime from '../lib/relative_time';
import AccessBanner from '../components/ui/access-banner.vue';
import ProgressBar from '../components/ui/progress-bar.vue';

const edgeTypeOrder = {
  TAGGING: 0,
  RELATIONSHIP: 1,
};

export default {
  components: {
    AccessBanner,
    ProgressBar,
  },
  props: {
    username: { type: String, required: true },
  },
  data() {
    return {
      error: null,
      loaded: false,
      id: null,
      permissions: null,
      page: 1,
    }
  },
  querySync: {
    params: {
      page: {
        read(query) {
          this.page = Number(query.page) || 1;
        },
        write(query) {
          if (this.page > 1) {
            query.page = this.page;
          }
        }
      }
    },
    afterWrite() {
      this.update();
    }
  },
  computed: {
    ...mapState(['currentPage']),
    user() {
      return this.$store.state.userModels[this.id];
    },
    loggedInUser() {
      return this.$store.state.user;
    },
    groupedEdges() {
      let currentGroup = null;
      const groups = [];
      this.currentPage.order.map(id => this.$store.state.taggingModels[id] || this.$store.state.relationshipModels[id]).forEach(e => {
        if (currentGroup && currentGroup.card.id !== e.cardId) {
          groups.push(currentGroup);
          currentGroup = null;
        }

        currentGroup = currentGroup || {
          id: e.id,
          edges: [],
          card: this.$store.state.cardModels[e.cardId],
        };

        currentGroup.edges.push(e);
      });

      if (currentGroup) {
        groups.push(currentGroup);
      }

      groups.forEach(group => {
        group.edges.sort((a, b) => edgeTypeOrder[a.type] - edgeTypeOrder[b.type] || a.name.localeCompare(b.name));
      });

      return groups;
    }
  },
  methods: {
    ordinal,
    thousands,
    relativeTime,
    ...mapActions([
      'fetchUser',
      'updateUser',
      'setBackground',
    ]),
    getTag(edge) {
      return this.$store.state.tagModels[edge.relatedId];
    },
    update() {
      this.loaded = false;
      this.fetchUser({
        name: this.username,
        page: this.page,
      })
        .then(data => {
          this.loaded = true;
          this.id = data.id;
          this.permissions = data.permissions;
        })
        .catch(err => {
          this.error = err.message;
        });
    },
    save() {
      this.updateUser({
        id: this.id,
        permissions: this.permissions
      });
    }
  },
  watch: {
    permissions(current, previous) {
      if (previous !== null && this.$store.getters.mayManageUsers) {
        this.save();
      }
    }
  },
  mounted() {
    this.$router.pageview({ title: [this.username, 'User'] });
    this.setBackground('#CBAAF5');
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

.tagging-card {
  background-color: white;
  border-radius: $box-corner;
  display: grid;
  grid-gap: spacing(3);
  grid-template-columns: 100px auto;
  padding: spacing(3);
  margin-bottom: spacing(3);
  max-width: 800px;
}

.tagging-tags {
  em {
    color: $c-gray-500;
    font-size: font-size(-1);
  }
}

.taggings {
  display: grid;
  grid-gap: 0 spacing(3);
  grid-template-columns: 1fr;

  @media (min-width: $break-small + 100px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $break-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
