let observer;
let loadedUrls;

function load(img) {
  img.src = img.getAttribute('data-src');
  loadedUrls[img.src] = true;
}

function reset() {
  observer = null;
  loadedUrls = null;
  if (!window['IntersectionObserver']) return;

  loadedUrls = {};
  observer = new IntersectionObserver(entries => {
    for (let entry of entries) {
      if (entry.isIntersecting && !entry.target.src) {
        load(entry.target);
      }
    }
  }, { root: null });
}

reset();

export default {
  reset,

  loaded(url) {
    return !!loadedUrls[url];
  },

  beforeMount(img) {
    if (!img.src && img.getAttribute('data-src')) {
      observer ? observer.observe(img) : load(img);
    }
  },

  unmounted(img) {
    observer && observer.unobserve(img);
  }
};
