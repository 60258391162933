<template>
  <div class="edge-info-panel">
    <revisable-header postfix="Info" :revisableId="revisableId" :revisableType="revisableType"/>

    <p v-if="hasRelevance"><strong>Relevance:</strong> {{ weight }}</p>
    <p><strong>Annotation:</strong> {{ annotation || 'none' }}</p>
    <p><strong>Metadata:</strong> {{ metadata || 'none' }}</p>
    <div class="tag-form-row">
      <button class="button-action" type="button" @click.prevent="close()">Okay</button>
    </div>
  </div>
</template>

<script>
import Revisable from '../mixins/revisable';
import RevisableHeader from '../ui/revisable-header.vue';

export default {
  mixins: [Revisable],
  components: {
    RevisableHeader
  },
  data() {
    return {
      loaded: true,
      annotation: '',
      metadata: '',
      metadataError: null,
      weight: 'MEDIAN',
    };
  },
  computed: {
    hasRelevance() {
      return this.revisable.type === 'ILLUSTRATION_TAG';
    }
  },
  async mounted() {
    if (this.isTag || this.isRelationship) {
      return this.close();
    }

    const data = await this.$store.dispatch('fetchEdge', { id: this.revisableId });
    this.annotation = data.annotation;
    this.metadata = data.metadata;
    this.weight = data.weight;
    this.loaded = true;
  }
};
</script>
