<template>
  <div class="light-mode access-banner">
    <strong>You must have a Scryfall supporter account to submit tags. <a href="https://scryfall.com/register/pledge">Learn more</a>.</strong>
    <span>Please <a href="https://scryfall.com/register">register</a> or <a :href="signInLink()" title="Sign in">sign in</a> to continue.</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.access-banner {
  background-color: tint($cGrape, 90);
  border-radius: $box-corner;
  padding: spacing(3);
  margin-bottom: spacing(3);
}
</style>
