<template>
  <div v-if="visible" class="hover-tooltip" :class="plotClass" :style="tooltipStyle">
    <img v-if="src" :src="src" :class="imageClass" :style="imageStyle" @load="onImage"/>
    <span v-if="!imageH" class="spinner" :class="spinnerClass"></span>
  </div>
</template>

<script>
import ScryfallCardFetcher from '../../lib/scryfall_card_fetcher';
import { closestAttr } from '../../lib/utils';

export default {
  data() {
    return {
      fetcher: new ScryfallCardFetcher(),
      hovercard: null,
      imageClass: null,
      spinnerClass: null,
      src: null,
      delay: null,
      plotX: 0,
      plotY: 0,
      imageW: 0,
      imageH: 0,
      winW: 1,
      winH: 1,
      visible: false,
    }
  },
  computed: {
    plotClass() {
      return this.plotX < this.winW - 300 ? 'right' : 'left';
    },
    tooltipStyle() {
      return {
        left: this.plotX + 'px',
        top: this.plotY + 'px',
      };
    },
    imageStyle() {
      const offsetY = Math.min(this.winH - this.plotY - this.imageH, 0);
      return {
        // translate tooltip to stay within visible screen bounds
        transform: offsetY < 0 ? `translateY(${ Math.round(offsetY) }px)` : null,
        // keep the image hidden until there's a height reading on it
        visibility: !this.imageH ? 'hidden' : null,
      };
    }
  },
  watch: {
    hovercard(slug) {
      if (slug) {
        this.delay = setTimeout(() => this.load(slug), 350);
      } else {
        this.reset();
      }
    }
  },
  methods: {
    reset() {
      clearTimeout(this.delay);
      this.fetcher.awaiting = null;
      this.visible = false;
      this.imageW = 0;
      this.imageH = 0;
      this.src = null;
    },
    load(slug) {
      const [foreignKey, id] = slug.split(':');
      const isArtwork = /illustration/i.test(foreignKey);
      this.spinnerClass = Math.random() > 0.6 ? 'alt' : null;
      this.imageClass = isArtwork ? 'artwork-image' : 'card-image';
      this.visible = true;

      const card = this.fetcher.cached(id, foreignKey);
      if (card) {
        this.src = card.imageFor(foreignKey);
        this.imageH = isArtwork ? 180 : 280; // guestimate
        return;
      }

      const startAt = Date.now();
      this.fetcher.fetch(id, foreignKey)
        .then(card => {
          const finishAt = Date.now();
          const duration = finishAt - startAt;
          const minimumLatency = 400;

          if (duration < minimumLatency) {
            setTimeout(() => this.src = card.imageFor(foreignKey), minimumLatency - duration);
          } else {
            this.src = card.imageFor(foreignKey);
          }
        });
    },
    onMove(evt) {
      this.plotX = evt.clientX;
      this.plotY = evt.clientY;
      this.winW = window.innerWidth;
      this.winH = window.innerHeight;
    },
    onHit(evt) {
      const el = closestAttr(evt.target, 'data-hovercard');
      this.hovercard = el ? el.getAttribute('data-hovercard') : null;
    },
    onImage(evt) {
      this.imageW = evt.target.width;
      this.imageH = evt.target.height;
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.onMove);
    document.addEventListener('mouseover', this.onHit);
    document.addEventListener('mouseout', this.onHit);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.hover-tooltip {
  // border: 1px solid red; // << draw origin
  height: 0;
  position: fixed;
  top: -0.3em;
  transform: translateX(50px);
  width: 0;
  z-index: $header-z + 1;

  img {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    left: 0;
    max-height: 280px;
    position: absolute;
    top: -30px;
    width: 240px;

    &.artwork-image {
      border-radius: 8px;
    }

    &.card-image {
      border-radius: 4.75% / 3.5%;
      width: 200px;
    }
  }

  img.left {
    left: auto;
    right: -50px;
  }

  &.left {
    transform: translateX(-50px);

    img {
      left: auto;
      right: 0;
    }
  }
}

.spinner {
  animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation-delay: -0.6s;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 2px;
  display: block;
  height: 30px;
  position: absolute;
  top: -15px;
  width: 24px;

  .left & {
    right: 0;
  }

  &.alt { animation-delay: -0.3s; }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
</style>
