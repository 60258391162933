<template>
  <div class="taggings">
    <template v-if="taggings.length || relationships.length">
      <div class="tag-row" v-for="t in taggings" :key="t.tagging.id">
        <tagging-icon :code="subjectDomain.namespace" :type="t.tagging.type"/>
        <span class="tag-row-flex"><router-link rel="nofollow" :to="toTag(t.tag)">{{ t.tag.name }}</router-link></span>
        <tagging-icon v-if="isHidden(t.tagging.status)" :code="t.tagging.status"/>
        <template v-else>
          <tagging-icon :code="t.tagging.weight"/>
          <tagging-icon v-if="t.tagging.annotation" code="annotation" :label="t.tagging.annotation"/>
        </template>
        <action-select revisableType="TAGGING" :revisableId="t.tagging.id" context="card"/>
      </div>
      <div class="tag-row" v-for="r in relationships" :key="r.id">
        <tagging-icon :code="r.classifier" :type="r.type"/>
        <span class="tag-row-flex">
          <router-link
            rel="nofollow"
            :to="toRelated(r)"
            :data-hovercard="toHover(r, r.relatedId)">
            {{ relationshipLabel(r) }}
          </router-link>
        </span>
        <tagging-icon v-if="isHidden(r.status)" :code="r.status"/>
        <tagging-icon v-else-if="r.annotation" code="annotation" :label="r.annotation"/>
        <action-select revisableType="RELATIONSHIP" :revisableId="r.id" context="card"/>
      </div>
    </template>
    <template v-else>
      <em>No {{ subjectDomain.namespace }} taggings</em>
    </template>
  </div>
</template>

<script>
import ActionSelect from '../ui/action-select.vue';
import SubjectDomain from '../../lib/subject_domain';

export default {
  components: {
    ActionSelect,
  },
  props: {
    subjectType: { type: String },
    taggings: { type: Array, required: true },
    relationships: { type: Array, required: true }
  },
  computed: {
    subjectDomain() {
      return new SubjectDomain(this.subjectType);
    },
    // counts the number of relationships with a given type/name.
    // oracle cards should always be unique, but illustrations
    // may relate to multiple cards with the same name.
    relationshipCounts() {
      return this.relationships.reduce((memo, rel) => {
        const key = this.relKey(rel);
        memo[key] = memo[key] || 0;
        memo[key] += 1;
        return memo;
      }, {});
    }
  },
  methods: {
    isHidden(status) {
      return ['PENDING_MODERATION', 'REJECTED'].includes(status);
    },
    relKey(rel) {
      return `${ rel.type }::${ rel.classifier }::${ rel.name }`;
    },
    relationshipLabel(rel) {
      // For duplicate card names with the same relationship,
      // obviate the difference by adding the id prefix in parens.
      if (this.relationshipCounts[this.relKey(rel)] > 1) {
        return `${ rel.name } (${ rel.relatedId.split('-').shift() })`;
      }
      return rel.name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.taggings {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 spacing(3);
  margin-top: spacing(4);

  @media (min-width: $break-small) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $break-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: $break-large) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
