<template>
  <main class="--narrow">
    <page-status v-if="!loaded" :error="error"/>
    <template v-else>
      <h1 class="section-title">
        Change Requests <guide-link slug="change-requests"/>
      </h1>
      <revisable-ticket v-for="revision in revisionTickets"
        :key="revision.id"
        :revisableType="revision.revisableType"
        :revisableId="revision.revisableId"
        :revision="revision"
        @closed="cycleTicket"/>
      <p v-if="!revisionTickets.length">Nothing to review!</p>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RevisableTicket from '../components/ui/revisable-ticket.vue';

export default {
  components: {
    RevisableTicket,
  },
  data() {
    return {
      bufferCount: 5,
      visibleCount: 10,
      error: null,
      loaded: false,
    };
  },
  computed: {
    ...mapState([
      'currentPage',
    ]),
    ...mapGetters([
      'firstCardOnPage',
    ]),
    revisionTickets() {
      return this.currentPage.order
        .map(id => this.$store.state.revisionModels[id])
        .slice(0, this.visibleCount);
    },
  },
  methods: {
    ...mapActions([
      'cycleRevision',
      'fetchReviewableRevisions',
      'toggleLiveRevisions',
      'setBackground',
    ]),
    cycleTicket(id, skipped) {
      this.cycleRevision({
        id,
        skipped,
        max: this.visibleCount + this.bufferCount,
        min: this.visibleCount,
      });
    },
  },
  watch: {
    firstCardOnPage(card) {
      this.setBackground(card ? card.artImageUrl : null);
    },
  },
  async mounted() {
    await this.fetchReviewableRevisions({
      limit: this.visibleCount + this.bufferCount
    }).catch(err => {
      this.error = err.message;
    });

    if (!this.error) {
      this.loaded = true;
      this.$router.pageview();
      this.toggleLiveRevisions(true);
      this.setBackground(this.firstCardOnPage ? this.firstCardOnPage.artImageUrl : null);
    }
  },
  beforeUnmount() {
    this.toggleLiveRevisions(false);
  },
};
</script>
