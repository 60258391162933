<template>
  <main class="--narrow">
    <h1>A crowdsourced tag catalog for Magic:&nbsp;The&nbsp;Gathering cards & illustrations</h1>

    <div class="utilities">
      <a href="https://scryfall.com/docs/syntax">Search Syntax</a>
      <router-link rel="nofollow" :to="{ name: 'random' }"><u>R</u>andom Card</router-link>
    </div>

    <div class="light-mode sample-tags">
      <div class="tag-row" v-for="tag in tags" :key="tag.id">
        <tagging-icon :code="tag.namespace"/>
        <router-link rel="nofollow" :to="{ name: 'tag', params: { type: tag.namespace, slug: tag.slug } }">{{ tag.name }}</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      tags: []
    }
  },
  methods: {
    ...mapActions([
      'fetchSampleTags',
      'setBackground',
    ]),
  },
  mounted() {
    this.setBackground('#CBAAF5');
    this.fetchSampleTags().then(data => {
      this.tags = data.sort((a, b) => a.name.localeCompare(b.name));
      this.$router.pageview();
    });
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';

h1 {
  font-size: font-size(2);
  text-align: center;
  margin: spacing(5) spacing(3);

  @media (min-width: $break-mobile) {
    font-size: font-size(3);
    margin: spacing(5) 15%;
  }
}

.utilities {
  display: flex;
  justify-content: center;
  margin: spacing(5) 0;

  a {
    border: 1px solid white;
    border-radius: $box-corner-sm;
    color: white;
    display: block;
    padding: spacing(1) spacing(3);
    margin: 0 spacing(2);
  }
}

.sample-tags {
  background-color: white;
  border-radius: $box-corner;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 spacing(3);
  min-height: 195px;
  padding: spacing(3);

  @media (min-width: $break-small) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $break-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
