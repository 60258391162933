<template>
  <a :href="`#${ slug }`" :title="linkDescription" @click.prevent="link" class="guide-link" :class="{ info: !this.$slots.default }">
    <div class="vh">{{linkDescription}}</div>
    <slot>
      <svg>
        <use xlink:href="#icons-info"></use>
      </svg>
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    slug: { type: String, required: true }
  },
  computed: {
    name() {
      return this.slug.replace(/-/g, ' ');
    },
    linkDescription() {
      return `learn more about ${this.name}`;
    }
  },
  methods: {
    link() {
      // note: $nextTick doesn't work here due to conditional views
      // not timing well with the click-out handler closing the sidebar.
      setTimeout(() => this.$store.dispatch('setSidebar', { view: 'guide', slug: this.slug }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

$info-size: 14px + (spacing(1) * 2);

.guide-link {
  &.info {
    display: inline-block;
    box-sizing: border-box;
    height: $info-size;
    margin: -#{ spacing(1) } 0 -#{ spacing(1) } spacing(1);
    padding: spacing(1);
    width: $info-size;

    svg {
      display: block;
      fill: $c-gray-500;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
