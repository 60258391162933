import { graphqlFetch, gql } from '../../lib/graphql';
import { baseCardAttrs, baseRelationshipAttrs, baseTagAttrs, baseTaggingAttrs } from './schema';
import * as cmd from '../commands';

export default {
  async fetchUser({ state }, params) {
    // params.username
    // params.page
    const { data } = await graphqlFetch(gql`
      query FetchUser($name: String!, $page: Int=1) {
        user: userByName(name: $name) {
          contributionsCount
          contributionsPercentile
          id
          permissions
          role
          username
          edges(page: $page) {
            page
            perPage
            total
            results {
              id
              card {
                ...CardAttrs
              }
              ...on Tagging {
                ...TaggingAttrs
                name
                tag {
                  ...TagAttrs
                }
              }
              ...on Relationship {
                ...RelationshipAttrs
              }
            }
          }
        }
      }
      ${ baseCardAttrs }
      ${ baseRelationshipAttrs }
      ${ baseTagAttrs }
      ${ baseTaggingAttrs }
    `, params);

    const taggings = data.user.edges.results.filter(e => e.type === 'TAGGING');

    this.commit(cmd.REPLACE_MODELS, {
      cardModels: data.user.edges.results.map(t => t.card),
      tagModels: taggings.map(t => t.tag),
      taggingModels: taggings,
      relationshipModels: data.user.edges.results.filter(e => e.type === 'RELATIONSHIP'),
      userModels: data.user,
      currentPage: {
        order: data.user.edges.results.map(t => t.id),
        page: data.user.edges.page,
        perPage: data.user.edges.perPage,
        total: data.user.edges.total,
        type: 'edge',
      }
    });

    return Promise.resolve(data.user);
  },

  async fetchUserContext({ state }) {
    const { data } = await graphqlFetch(gql`
      query FetchUserSettings($id: ID!) {
        user(id: $id) {
          reviewableRevisionsCount
        }
      }
    `, { id: state.user.id });

    this.commit(cmd.PATCH_USER, data.user);
    return Promise.resolve(data.user);
  },

  async updateUser({ state }, params) {
    const { id, ...input } = params;
    const { data } = await graphqlFetch(gql`
      mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
        result: userUpdate(id: $id, input: $input) {
          user {
            id
            permissions
            role
          }
        }
      }
    `, { id, input });

    if (state.user.id === data.result.user.id) {
      this.commit(cmd.PATCH_USER, data.result.user);
    }

    this.commit(cmd.PATCH_MODELS, { userModels: data.result.user });
    return Promise.resolve(data.result.user);
  }
};
