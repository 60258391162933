<template>
  <div>
    <template v-if="models.length">
      <pagination-nav :page="currentPage.page" :perPage="currentPage.perPage" :total="currentPage.total" :baseUrl="baseUrl"/>

      <div class="card-grid">
        <div class="card-grid-item" v-for="m, index in models" :key="m.key">
          <template v-if="asArtwork">
            <router-link class="artwork" rel="nofollow" :to="toCard(m.card, m.tagging)">
              <img :src="lazyUrl(m.card.artImageUrl, index)" :data-src="m.card.artImageUrl" :alt="m.card.name" :key="m.card.artImageUrl" v-lazyload>
            </router-link>
            <div class="tag-row">
              <span class="tag-row-flex"><router-link rel="nofollow" :to="toCard(m.card, m.tagging)">{{ m.card.name }}</router-link></span>
              <template v-if="m.tagging">
                <tagging-icon class="icon" :code="m.tagging.weight"/>
                <tagging-icon v-if="m.tagging.annotation" code="annotation" :label="m.tagging.annotation"/>
                <action-select class="menu" v-if="taggingActions" revisableType="TAGGING" :revisableId="m.tagging.id" context="grid" :dark="true"/>
              </template>
            </div>
          </template>
          <template v-else>
            <router-link class="card" rel="nofollow" :to="toCard(m.card, m.tagging)">
              <img :src="lazyUrl(m.card.cardImageUrl, index)" :data-src="m.card.cardImageUrl" :alt="m.card.name" :key="m.card.cardImageUrl" v-lazyload>
            </router-link>
            <div class="tag-row" v-if="m.tagging">
              <action-select v-if="taggingActions" revisableType="TAGGING" :revisableId="m.tagging.id" context="grid" align="below right" :dark="true"/>
              <tagging-icon v-if="m.tagging.annotation" code="annotation" :label="m.tagging.annotation"/>
              <tagging-icon class="icon" :code="m.tagging.weight"/>
            </div>
          </template>
          <router-link v-if="withTags && m.tag"
            class="admin-ref"
            :class="m.tagging.status.toLowerCase().replace(/_/g, '-')"
            :to="toTag(m.tag)"
          >{{m.tag.name}}</router-link>
        </div>
      </div>

      <pagination-nav :page="currentPage.page" :perPage="currentPage.perPage" :total="currentPage.total" :baseUrl="baseUrl"/>
    </template>
    <div v-else-if="emptyMessage"><em>{{ emptyMessage }}</em></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LazyLoad from '../../lib/lazyload';
import ActionSelect from './action-select.vue';

export default {
  components: {
    ActionSelect,
  },
  props: {
    emptyMessage: { type: String },
    taggingActions: { type: Boolean, default: false },
    asArtwork: { type: Boolean, default: false },
    withTags: { type: Boolean, default: false },
    baseUrl: { type: Object },
  },
  computed: {
    ...mapState([
      'currentPage'
    ]),
    models() {
      let models;
      if (this.currentPage.type === 'tagging') {
        models = this.currentPage.order.map((id, index) => {
          const tagging = this.$store.state.taggingModels[id];
          return {
            key: index,
            tagging,
            tag: this.$store.state.tagModels[tagging.relatedId],
            card: this.$store.state.cardModels[tagging.cardId],
          };
        });
      } else {
        models = this.currentPage.order.map((id, index) => {
          return { key: index, card: this.$store.state.cardModels[id] };
        });
      }

      // filter out missing card references for non-admins
      if (!this.$store.getters.mayManageEdges) {
        models = models.filter(m => m.card.set !== 'MISSING');
      }
      return models;
    }
  },
  methods: {
    lazyUrl(url, index) {
      return index <= 10 || LazyLoad.loaded(url) ? url : null;
    }
  },
  watch: {
    currentPage() {
      LazyLoad.reset();
    }
  },
  beforeUnmount() {
    LazyLoad.reset();
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 10px;
  margin: spacing(4) 0;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 950px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .tag-row {
    border-bottom: none;
  }

  .admin-ref {
    display: inline-block;
    margin-bottom: spacing(1);

    &.good-standing {
      background-color: #067a10;
      &:before { content: '+ '; }
    }

    &.rejected {
      background-color: #991700;
      &:before { content: '- '; }
    }
  }
}

.card-grid-item {
  position: relative;

  .admin-ref {
    left: spacing(1);
    position: absolute;
    top: spacing(1);
  }
}
</style>
