<template>
  <div v-if="message" class="notice" :class="anim" @mouseenter="onOver" @mouseleave="onOut" @animationend="onHide" @click.prevent="close">
    {{ message }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      id: null,
      message: null,
      undoable: false,
      hover: false,
      anim: null,
    };
  },
  computed: {
    ...mapState(['currentNotice'])
  },
  watch: {
    currentNotice() {
      setTimeout(this.update, 50);
    }
  },
  methods: {
    update() {
      const notice = this.currentNotice;
      if (notice && notice.id !== this.id) {
        this.id = notice.id;
        this.anim = 'enter';
        this.message = notice.message;
      } else if (!notice && !this.hover) {
        this.id = null;
        this.anim = 'exit';
      }
    },
    close(evt) {
      this.hover = false;
      if (this.currentNotice) {
        this.$store.commit('setNotice', null);
      } else {
        this.update();
      }
    },
    onOut(evt) {
      this.hover = false;
      this.update();
    },
    onOver(evt) {
      this.hover = true;
    },
    onHide(evt) {
      if (/^exit/.test(evt.animationName)) {
        this.message = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.notice {
  background-color: $cGrape;
  border-radius: $box-corner-sm;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  bottom: 0;
  box-shadow: $box-shadow;
  cursor: default;
  left: 50%;
  padding: spacing(2) spacing(3);
  position: fixed;
  z-index: $header-z + 2;

  &.enter {
    animation-name: enter;
    animation-duration: .25s;
    transform: translate(-50%, 0);
  }

  &.exit {
    animation-name: exit;
    animation-duration: .25s;
    transform: translate(-50%, 50px);
  }
}

@keyframes enter {
  0% {
    transform: translate(-50%, 50px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes exit {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 50px);
  }
}
</style>