<template>
  <main>
    <page-status v-if="loading" :error="error"/>
    <template v-else>
      <h1 class="section-title">Search</h1>
      <section class="section-info">
        <header>
          <span>Results for {{ query }}</span>
          <label><input type="checkbox" v-model="asArtwork"> as artwork</label>
        </header>
      </section>
      <card-grid :asArtwork="asArtwork" :baseUrl="{ name: 'search', query: urlParams }" emptyMessage="No results found"/>
    </template>
  </main>
</template>

<script>
import pubsub from 'tiny-emitter/instance';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '../lib/utils';
import CardGrid from '../components/ui/card-grid.vue';

const defaultMode = 'AUTO';

export default {
  components: {
    CardGrid,
  },
  data() {
    return {
      asArtwork: false,
      error: null,
      loading: true,
      mode: defaultMode,
      page: 1,
      query: '',
    }
  },
  computed: {
    ...mapGetters([
      'firstCardOnPage'
    ]),
    urlParams() {
      const params = {};
      if (this.mode !== defaultMode) params.mode = this.mode.toLowerCase();
      if (this.page > 1) params.page = this.page;
      if (this.query) params.q = this.query;
      return params;
    },
  },
  watch: {
    page() {
      this.refresh();
    },
    mode() {
      this.asArtwork = /color|artist/i.test(this.mode);
      this.refresh();
    },
    query() {
      this.refresh();
    },
    '$route.query'() {
      this.pullRoute();
    },
    firstCardOnPage(card) {
      this.setBackground(card ? card.artImageUrl : null);
    }
  },
  methods: {
    ...mapActions([
      'searchCards',
      'setBackground',
    ]),
    refresh: debounce(function() {
      this.pushRoute();
      this.search();
    }),
    pullRoute() {
      const params = this.$route.query;
      this.page = Number(params.page) || 1;
      this.mode = (params.mode || defaultMode).toUpperCase();
      this.query = params.q || '';

      if (this.query) {
        pubsub.emit('search', this.query);
      }
    },
    pushRoute() {
      this.$router.replace({ query: this.urlParams });
    },
    search() {
      if (!this.query) {
        this.loading = false;
        this.page = 1;
        return;
      }

      this.loading = true;
      this.searchCards({
        query: this.query,
        mode: this.mode,
        page: this.page,
      })
        .then(data => {
          this.loading = false;
          if (data.results.length === 1 && this.page === 1) {
            return this.$router.replace(this.toCard(data.results[0]));
          }
          this.$router.pageview({
            title: [`${ this.query } (${ this.mode })`, 'Search Results'],
            scroll: true,
          });
        })
        .catch(err => {
          this.error = err.message;
        });
    }
  },
  mounted() {
    this.pullRoute();
    this.refresh();
  }
};
</script>
