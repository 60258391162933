<template>
  <div class="app-header">
    <nav class="app-wrapper">
      <router-link class="navigation home" title="Tagger Home" :to="{ name: 'dashboard' }">
        <svg aria-hidden="true"><use xlink:href="#icons-tagger"></use></svg>
        <span class="vh">Scryfall Tagger</span>
      </router-link>
      <suggestion-input ref="search" :clearable="true" class="app-search" type="ALL" @submit="onSearch"/>
      <a class="navigation" :class="{ active: isActive('guide') }" href="#" @click.prevent="toggle('guide')">
        <svg aria-hidden="true" class="label-icon"><use xlink:href="#icons-map"></use></svg>
        <span class="label-text">Guide</span>
      </a>
      <a class="navigation" :class="{ active: isActive('tags'), notify: hasTagNotifications }" href="#" @click.prevent="toggle('tags')">
        <svg aria-hidden="true" class="label-icon"><use xlink:href="#icons-tag"></use></svg>
        <span class="label-text">Tags<span class="vh">{{tagLinkDescription}}</span></span>
      </a>
      <router-link v-if="user.id" rel="nofollow" class="navigation" :title="user.username" :to="toUser(user)" @click="toggle(null)">
        <svg aria-hidden="true"><use xlink:href="#icons-user"></use></svg>
        <span class="vh">User Profile: {{ user.username }}</span>
      </router-link>
      <a v-else class="navigation" :href="signInLink()" title="Sign in">
        <svg aria-hidden="true"><use xlink:href="#icons-signin"></use></svg>
        <span class="vh">Sign in</span>
      </a>
    </nav>
    <div v-if="currentSidebar" class="light-mode sidebar" @click.prevent="onSidebarOut">
      <sidebar-tags data-sidebar class="scrollbar sidebar-panel" v-if="isActive('tags')"/>
      <sidebar-guide data-sidebar class="scrollbar sidebar-panel" v-if="isActive('guide')"/>
    </div>
  </div>
</template>

<script>
import pubsub from 'tiny-emitter/instance';
import { defineAsyncComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { closestAttr } from '../../lib/utils';
import Undo from '../../lib/undo';
import SuggestionInput from '../ui/suggestion-input.vue';
import SidebarTags from './sidebar-tags.vue';

export default {
  components: {
    SidebarGuide: defineAsyncComponent(() => import(/* webpackChunkName: "guide" */ './sidebar-guide.vue')),
    SidebarTags,
    SuggestionInput,
  },
  data() {
    return { query: '' };
  },
  computed: {
    ...mapState(['user']),
    currentSidebar() {
      const breakout = this.$store.state.currentBreakout;
      return breakout && breakout.type === 'sidebar' ? breakout : null;
    },
    hasTagNotifications() {
      return this.user.reviewableRevisionsCount > 0;
    },
    tagLinkDescription() {
      return !this.hasTagNotifications ? '' : ` (${this.user.reviewableRevisionsCount} revisions to review)`;
    }
  },
  methods: {
    ...mapActions([
      'fetchUserContext',
      'setSidebar',
    ]),
    isActive(view) {
      const sidebar = this.currentSidebar;
      return sidebar && sidebar.view === view;
    },
    toggle(view) {
      const activate = view && !this.isActive(view);
      this.setSidebar(activate ? { view } : null);
    },
    onSidebarOut(evt) {
      if (!closestAttr(evt.target, 'data-sidebar')) {
        this.setSidebar(null);
      }
    },
    onSearch(evt) {
      const path = String(evt.uri).split('/');
      if (/_TAG/.test(evt.type)) {
        this.$router.push({ name: 'tag', params: { type: path[0], slug: path[1] } });
      } else if (evt.type === 'SEARCH') {
        this.$router.push({ name: 'search', query: { q: evt.uri } });
      } else {
        this.$router.push({ name: 'search', query: { q: evt.term, mode: evt.type } });
      }
    },
    async onHotKey(evt) {
      // CMD+Z -> Undo action
      if ((evt.ctrlKey || evt.metaKey) && evt.which === 90 && Undo.perform()) {
        return evt.preventDefault();
      }

      // ignore all input paired with operational keys.
      if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) return;

      // ignore all input that falls within a form field.
      let el = evt.target;
      while (el) {
        if (/input|textarea/i.test(el.tagName) || el.contentEditable === 'true') return;
        el = el.parentNode;
      }

      const SLASH_KEY = 191;
      const G_KEY = 71;
      const R_KEY = 82;
      const ESCAPE_KEY = 27;

      const stop = () => evt.preventDefault();
      switch(evt.which) {
        case SLASH_KEY:
          this.$refs.search.focus();
          return stop();
        case G_KEY:
          this.toggle('guide');
          return stop();
        case R_KEY:
          this.$router.push({ name: 'random' });
          return stop();
        case ESCAPE_KEY:
          this.setSidebar(null);
          return stop();
      }
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.setSidebar({ view: 'guide', slug: this.$route.hash.replace(/^#/, '') });
    }

    // fetch pending reviews notification for contributors
    if (this.$store.getters.maySubmitContent) {
      setTimeout(() => {
        this.fetchUserContext();
        setInterval(this.fetchUserContext, 1000 * 60 * 2);
      }, 1000 * 3);
    }

    // populate search field in response to root events
    pubsub.on('search', val => this.$refs.search.update(val, false));

    // listen for hotkey presses
    window.addEventListener('keydown', this.onHotKey);
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.app-header {
  background-color: #2B253A;
  height: $header-height;
  min-height: $header-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $header-z;

  nav {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    z-index: 10001;
  }

  a {
    color: white;
  }
}

.app-search {
  flex-grow: 1;
  margin: 0 spacing(2);
}

.navigation {
  display: inline-block;
  line-height: $header-height;
  padding: 0 spacing(2) + spacing(1);

  @media (min-width: $break-mobile) {
    padding: 0 spacing(3);
  }

  &.active {
    background-color: white;
    color: $cPlum;
  }

  &:last-child {
    padding-right: 0;
  }

  svg {
    fill: currentColor;
    height: 16px;
    opacity: 0.55;
    position: relative;
    top: 0.2em;
    transition: opacity 0.3s;
    width: 16px;
  }

  &:hover svg {
    opacity: 1 !important;
  }

  .label-text {
    display: none;
  }

  @media (min-width: $break-mobile) {
    .label-icon { display: none; }
    .label-text { display: inline; }
  }
}

.navigation.home {
  padding: 0;

  svg {
    height: 26px;
    opacity: 0.85;
    top: 0.5em;
    width: 26px;
  }
}

.sidebar {
  bottom: 0;
  background-color: $c-overlay;
  content: '';
  left: 0;
  position: fixed;
  right: 0;
  top: $header-height;
  z-index: $header-z;
}

.sidebar-panel {
  background-color: white;
  bottom: 0;
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: spacing(4) spacing(3);
  position: absolute;
  right: 0;
  top: 0;
  width: 85%;

  @media(min-width: $break-mobile) {
    width: 500px;
  }

  // really f'ing wide screens max out
  // with sidebar floating below the nav tabs
  @media(min-width: 1930px) {
    right: 50%;
    transform: translateX(965px);
  }
}

input {
  margin-right: auto;
}

.notify {
  position: relative;

  &:after {
    background-color: $cGrape;
    border: 1px solid white;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 8px;
    margin-left: spacing(1);
    position: absolute;
    right: 5px;
    top: 11px;
    width: 8px;

    @media (min-width: $break-mobile) {
      right: 7px;
      top: 15px;
    }
  }
}

// search input style overrides

:deep(.app-search) {
  .suggestion-menu {
    left: -36px;
    min-width: calc(100vw - 30px); // (padding + logo + padding) * 2

    @media (min-width: $break-small) {
      left: auto;
      min-width: calc(100vw - 132px); // (padding + logo + padding) * 2
    }

    @media (min-width: $break-mobile) {
      min-width: unset;
    }
  }

  .tag-input-field {
    background-color: $cPlum !important;
    border-color: lighten($cRaisin, 10) !important;
    color: white;

    &:focus {
      box-shadow: 0 0 2px 2px $cRaisin;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .tag-input-clear {
    background-color: rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.9);
  }
}
</style>
