import { graphqlFetch, gql } from '../../lib/graphql';
import { baseTagAttrs, baseTaggingAttrs, baseRelationshipAttrs } from './schema';
import * as cmd from '../commands';

export default {
  async submitTagging({ state }, input) {
    const { data } = await graphqlFetch(gql`
      mutation SubmitTagging($input: TaggingCreateInput!) {
        result: taggingCreate(input: $input) {
          message
          choices {
            signature
            tagName
            timestamp
          }
          tagging {
            ...TaggingAttrs
            tag {
              ...TagAttrs
            }
          }
          valid
        }
      }
      ${ baseTagAttrs }
      ${ baseTaggingAttrs }
    `, { input });

    const tagging = data.result.tagging;
    if (tagging) {
      this.commit(cmd.PATCH_MODELS, { tagModels: tagging.tag, taggingModels: tagging });
      this.commit(cmd.SET_NOTICE, { message: 'tagging added' });
    }

    return Promise.resolve(data.result);
  },

  async submitRelationship({ state }, input) {
    const { data } = await graphqlFetch(gql`
      mutation RelationshipCreate($input: RelationshipCreateInput!) {
        result: relationshipCreate(input: $input) {
          message
          relationship {
            ...RelationshipAttrs
          }
          valid
        }
      }
      ${ baseRelationshipAttrs }
    `, { input });

    const rel = data.result.relationship;
    if (rel) {
      this.commit(cmd.PATCH_MODELS, { relationshipModels: rel });
      this.commit(cmd.SET_NOTICE, { message: 'relationship added' });
    }

    return Promise.resolve(data.result);
  }
};
