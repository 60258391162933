<template>
  <div class="modal-overlay" v-if="currentDialog" @mousedown="onTouch" @click="onRelease">
    <input class="vh" type="text" @focus.prevent="onTopWrap"/>
    <div class="light-mode modal-dialog scale-in" :class="{ fullscreen: currentDialog.fullscreen }" role="dialog" aria-modal="true">
      <button class="button-close" ref="closeButton" type="button" @click.prevent="close()">
        <svg aria-hidden="true"><use xlink:href="#icons-close"></use></svg>
        <span class="vh">Close</span>
      </button>
      <div ref="contentWrapper">
        <component
          :is="`dialog-${ currentDialog.as }`"
          :revisableType="currentDialog.revisableType"
          :revisableId="currentDialog.revisableId"
          :config="currentDialog"
          @close="close"/>
      </div>
    </div>
    <input class="vh" type="text" @focus.prevent="onBottomWrap"/>
  </div>
</template>

<script>
import { closestAttr } from '../../lib/utils';
import DialogEdgeInfo from './dialog-edge-info.vue';
import DialogEdgeSettings from './dialog-edge-settings.vue';
import DialogRevisionEdge from './dialog-revision-edge.vue';
import DialogRevisionTag from './dialog-revision-tag.vue';

export default {
  components: {
    DialogEdgeInfo,
    DialogEdgeSettings,
    DialogRevisionEdge,
    DialogRevisionTag,
  },
  data() {
    return {
      dialogClickOrigin: false,
    };
  },
  computed: {
    currentDialog() {
      const breakout = this.$store.state.currentBreakout;
      return breakout && breakout.type === 'dialog' ? breakout : null;
    }
  },
  watch: {
    currentDialog(current, previous) {
      // start newly-opened dialogs with close button focused.
      if (current && !previous) {
        this.$nextTick(() => this.$refs.closeButton.focus());
      }
    }
  },
  methods: {
    close(data) {
      this.$store.dispatch('setDialog', { return: data || null });
    },
    onTouch(evt) {
      // tracks when mousedown originated within the dialog.
      // useful for ignoring click/drag sequences that extend outside of the modal
      this.dialogClickOrigin = !!closestAttr(evt.target, 'aria-modal');
    },
    onRelease(evt) {
      if (!closestAttr(evt.target, 'aria-modal') && !this.dialogClickOrigin) {
        this.close();
      }
      this.dialogClickOrigin = false;
    },
    onTopWrap() {
      const form = this.$refs.contentWrapper.querySelector('form');
      // if modal has an inner form, use the element list from that
      // otherwise, assume modal only has confirmation buttons
      const elements = form ? form.elements : this.$refs.contentWrapper.querySelectorAll('button');
      const lastFocusableElement = Array.from(elements).reverse().find(el => !el.disabled && el.type !== 'hidden');

      // wrap to last element, or back to origin (close button)
      if (lastFocusableElement) {
        lastFocusableElement.focus();
      } else {
        this.$refs.closeButton.focus();
      }
    },
    onBottomWrap() {
      // focus on the first button available (always the close
      // button) when tabbing out of the modal from the last element
      this.$refs.closeButton.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.modal-overlay {
  align-items: center;
  background-color: $c-overlay;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: $header-z + 1;
}

.modal-dialog {
  background-color: white;
  border-radius: $box-corner;
  box-shadow: $box-shadow;
  max-width: 650px;
  padding: spacing(3);
  position: relative;
  width: 75%;

  &.fullscreen {
    border-radius: 0;
    bottom: 0;
    overflow: auto;
    position: absolute;
    top: 0;
  }
}
</style>
