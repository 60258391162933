<template>
  <div class="tags-menu">
    <router-link rel="nofollow" class="review-link" :to="{ name: 'revisions' }">
      <strong><span class="badge">{{ user.reviewableRevisionsCount }}</span>Review pending changes</strong>
    </router-link>

    <div class="divided-input-field" role="group" aria-labelledby="tag-filter-label">
      <label class="tag-input-field-label">
        <span class="vh" id="tag-filter-label">Tag filter</span>
        <select class="tag-input-field" v-model="modeInput">
          <option v-for="value, label in tagOptions" :key="value" :value="value">{{ label }}</option>
          <option disabled>──────────</option>
          <option v-for="value, label in relOptions" :key="value" :value="value">{{ label }}</option>
        </select>
      </label>
      <input class="tag-input-field" type="text" v-model.lazy="queryInput" @keyup.enter="update" autocapitalize="none" autocorrect="off" autocomplete="off" placeholder="Tag name to filter"/>
    </div>

    <pagination-nav class="light-mode" v-model="page" :page="tags.page" :perPage="tags.perPage" :total="tags.total"/>

    <div class="search-desc" v-if="query && tags.results.length">
      <em v-if="query.length > 1">Tags containing "{{ query }}"</em>
      <em v-else>Tags beginning with "{{ query }}"</em>
    </div>

    <div class="tag-row" v-for="tag in tags.results" :key="tag.id">
      <template v-if="tag.type === 'RELATIONSHIP'">
        <tagging-icon :code="tag.namespace"/>
        <router-link rel="nofollow" :to="toRelated(tag, tag.subjectId)" :data-hovercard="toHover(tag)">{{ tag.subjectName }}</router-link>
        <span class="divider">→</span>
        <router-link rel="nofollow" :to="toRelated(tag)" :data-hovercard="toHover(tag, tag.relatedId)">{{ tag.relatedName }}</router-link>
      </template>
      <template v-else>
        <tagging-icon :code="tag.namespace"/>
        <span class="tag-row-flex"><router-link rel="nofollow" :to="toTag(tag)">{{ tag.name }}</router-link></span>
        <tagging-icon v-if="tag.category" code="category"/>
        <span v-else>{{ tag.taggingCount }}</span>
      </template>
    </div>
    <div class="tag-row" v-if="!tags.results.length">
      {{ loaded ? 'No results ' : 'Loading...' }}
    </div>

    <pagination-nav class="light-mode" v-model="page" :page="tags.page" :perPage="tags.perPage" :total="tags.total"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { capitalize, debounce } from '../../lib/utils';

const tagOptions = {
  'All tags':      'tag',
  'Artwork tags':  'tag:ILLUSTRATION_TAG',
  'Card tags':     'tag:ORACLE_CARD_TAG',
  'Printing tags': 'tag:PRINTING_TAG',
};

export default {
  data() {
    const currentTag = this.$store.state.currentTag;
    return {
      tagOptions,
      loaded: false,
      mode: currentTag ? `tag:${ currentTag.type }` : Object.values(tagOptions)[0],
      page: 1,
      query: currentTag ? currentTag.name[0] : null,
    };
  },
  computed: {
    ...mapState(['tags', 'user']),
    modeInput: {
      get() {
        return this.mode;
      },
      set(val) {
        if (val === this.mode) return;
        this.page = 1;
        this.mode = val;
      }
    },
    queryInput: {
      get() {
        return this.query || '';
      },
      set(val) {
        val = val || null;
        if (val === this.query) return;
        this.page = 1;
        this.query = val;
      }
    },
    relOptions() {
      return this.$store.state.edgePairs.reduce((memo, pair) => {
        memo[capitalize(pair.label)] = `rel:${ pair.keys.join('/') }`;
        return memo;
      }, {});
    }
  },
  methods: {
    update: debounce(function() {
      const params = this.mode.split(':');
      if (params[0] === 'tag') {
        this.$store.dispatch('searchTags', {
          type: params[1],
          name: this.query,
          page: this.page,
        })
          .then(data => this.loaded = true)
          .catch(err => {});
      } else if (params[0] === 'rel') {
        this.$store.dispatch('searchEdges', {
          classifier: params[1].split('/'),
          type: 'RELATIONSHIP',
          name: this.query,
          page: this.page,
        })
          .then(data => this.loaded = true)
          .catch(err => {});
      }
    })
  },
  watch: {
    mode() {
      this.update();
    },
    page() {
      this.update();
    },
    query() {
      this.update();
    }
  },
  mounted() {
    this.update();
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.review-link {
  display: inline-block;
  margin-bottom: spacing(3);
}

.badge {
  background-color: $cGrape;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: font-size(-1);
  height: 18px;
  line-height: 18px;
  margin-right: spacing(2);
  padding: 0 spacing(2);
  position: relative;
  top: -0.1em;
}

.search-desc {
  margin: spacing(3) 0 spacing(2);
}

.divider {
  padding: 0 spacing(2);
}

.pagination {
  margin: spacing(3) 0;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
