let action;

export default {
  set(fn) {
    action = typeof fn === 'function' ? fn : null;
  },
  active() {
    return !!action;
  },
  perform() {
    if (action) {
      action();
      action = null;
      return true;
    }
    return false;
  }
};