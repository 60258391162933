import { createApp } from 'vue';

import App from '../../app.vue';
import store from '../../store';
import router from '../../router';

// plugins, directives & mixins
import QuerySync from '../../lib/query_sync';
import LazyLoad from '../../lib/lazyload';
import Helpers from '../../lib/helpers';

// components
import GuideLink from '../../components/ui/guide-link.vue';
import PaginationNav from '../../components/ui/pagination-nav.vue';
import PageStatus from '../../components/ui/page-status.vue';
import TaggingIcon from '../../components/ui/tagging-icon.vue';

// base styles
import '../../styles/main.scss';

const app = createApp(App);
app.mixin(Helpers);
app.directive('lazyload', LazyLoad);
app.component('guide-link', GuideLink);
app.component('pagination-nav', PaginationNav);
app.component('page-status', PageStatus);
app.component('tagging-icon', TaggingIcon);
app.use(store);
app.use(router);
app.use(QuerySync);
app.mount("#app");

export default app;
