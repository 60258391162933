<template>
  <div class="edge-settings-panel">
    <countdown-timer class="time-remaining" v-if="timeLimit"
      prefix="editable for"
      :timeout="limitTimeout"
      @timeout="accessUpdate=Date.now()"/>

    <revisable-header prefix="Manage" :revisableId="revisableId" :revisableType="revisableType"/>

    <form @submit.prevent="submit">
      <div v-if="hasRelevance" class="tag-form-row">
        <label>Relevance
          <select class="tag-input-field" v-model="weight">
            <option value="VERY_STRONG" v-if="$store.getters.mayManageEdges">Exemplary, best example of tagged subject</option>
            <option value="STRONG">Strong, describes a primary focal element</option>
            <option value="MEDIAN">Average, describes a secondary element</option>
            <option value="WEAK">Weak, extremely minor/background detail</option>
          </select>
        </label>
      </div>

      <div class="tag-form-row">
        <label>Annotation
          <input class="tag-input-field" v-model.trim="annotation" autocapitalize="none" autocorrect="off" autocomplete="off">
        </label>
        <p v-if="String(annotation || '').length > 150"><em>maximum 150 characters</em></p>
      </div>

      <template v-if="$store.getters.maySubmitAdvancedData">
        <div class="tag-form-row" v-if="!advancedForm">
          <a href="#" @click.prevent="openAdvancedForm">+ advanced</a>
        </div>
        <div class="tag-form-row" v-else>
          <label>JSON Metadata
            <input class="tag-input-field" ref="advancedFormInput" v-model.trim="metadata" placeholder='{"panoramaX": 3, "panoramaY": 1}' autocapitalize="none" autocorrect="off" autocomplete="off">
          </label>
        </div>
      </template>

      <div v-if="error" class="tag-form-row errors">{{ error }}</div>

      <div class="tag-form-row">
        <button class="button-action button-primary" type="submit" @click.prevent="performUpdate">Update {{ revisableLabel }}</button>
        <button class="button-action button-danger" type="button" @click.prevent="performDelete">Delete this {{ revisableLabel }}</button>
      </div>
    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mutableHasChanged } from '../../lib/utils';
import Revisable from '../mixins/revisable';
import RevisableHeader from '../ui/revisable-header.vue';
import CountdownTimer from '../ui/countdown-timer.vue';

export default {
  mixins: [Revisable],
  components: {
    CountdownTimer,
    RevisableHeader,
  },
  data() {
    return {
      advancedForm: false,
      annotation: '',
      busy: true,
      error: null,
      metadata: '',
      weight: 'MEDIAN',
    };
  },
  computed: {
    mutable() {
      return {
        annotation: this.annotation || null,
        metadata: this.metadata || null,
        weight: this.weight
      };
    },
    isChanged() {
      return !this.busy && mutableHasChanged(this.mutable, this.revisable);
    },
    hasRelevance() {
      const artworkTagging = this.revisable.type === 'ILLUSTRATION_TAG';
      const adminAccess = this.$store.getters.mayManageEdges;
      return this.isTagging && (artworkTagging || adminAccess);
    }
  },
  watch: {
    accessUpdate() {
      this.setUserAccess();
    }
  },
  methods: {
    ...mapActions([
      'setDialog',
      'fetchEdge',
      'updateEdge',
      'deleteEdge',
    ]),
    setUserAccess() {
      if (!this.userMayModify) {
        this.setDialog({
          as: 'edge-info',
          revisableId: this.revisableId,
          revisableType: this.revisableType
        });
      }
    },
    openAdvancedForm() {
      this.advancedForm = true;
      this.$nextTick(() => this.$refs.advancedFormInput.focus());
    },
    async performUpdate() {
      if (this.isChanged) {
        if (this.mutable.metadata) {
          try {
            JSON.parse(this.mutable.metadata);
          } catch (e) {
            this.error = 'invalid metadata JSON';
            return;
          }
        }

        this.error = '';
        this.busy = true;
        await this.updateEdge(Object.assign({ id: this.revisableId }, this.mutable));
      }
      this.close();
    },
    async performDelete() {
      if (!this.busy && confirm(`Delete this ${ this.revisableLabel }? This cannot be undone.`)) {
        this.busy = true;
        await this.deleteEdge({
          id: this.revisableId,
          beforeCommit: this.close,
        }).catch(() => this.busy = false);
      }
    }
  },
  beforeMount() {
    this.setUserAccess();
  },
  async mounted() {
    const data = await this.fetchEdge({ id: this.revisableId });
    this.annotation = data.annotation;
    this.metadata = data.metadata;
    this.weight = data.weight;
    this.busy = false;
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.edge-settings-panel {
  position: relative;
}

.tabbed-actions {
  margin-top: spacing(3);
}
</style>
