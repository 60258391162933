<template>
  <div class="revisable-header">
    <em>
      {{ headerText }}
      <span v-if="showAuthor">
        &mdash; <span v-if="!isTag">{{ relativeTime(revisable.createdAt) }}</span>
        by <router-link rel="nofollow" :to="toUser(createdBy)">{{ createdBy.username }}</router-link>
      </span>
      <action-select v-if="context" :revisableId="revisableId" :revisableType="revisableType" :context="context"/>
    </em>
    <div class="tag-row">
      <template v-if="isTag">
        <tagging-icon :code="relatedTag.namespace"/>
        <strong>{{ humanize(relatedTag.namespace) }} Tag</strong>
        <span class="divider">→</span>
        <router-link rel="nofollow" :to="toTag(relatedTag)">{{ relatedTag.name }}</router-link>
      </template>
      <template v-else-if="isTagging">
        <tagging-icon :code="relatedTag.namespace"/>
        <strong>{{ relatedCard.name }}</strong>
        <span class="divider">→</span>
        <router-link rel="nofollow" :to="toTag(relatedTag)">{{ relatedTag.name }}</router-link>
      </template>
      <template v-else-if="isRelationship">
        <tagging-icon :code="revisable.classifier"/>
        <router-link rel="nofollow" :to="toRelated(revisable, revisable.subjectId)">{{ revisable.subjectName }}</router-link>
        <span class="divider">→</span>
        <router-link rel="nofollow" :to="toRelated(revisable, revisable.relatedId)" :data-hovercard="toHover(revisable, revisable.relatedId)">{{ revisable.relatedName }}</router-link>
      </template>
    </div>
  </div>
</template>

<script>
import Revisable from '../mixins/revisable';
import ActionSelect from './action-select.vue';
import relativeTime from '../../lib/relative_time';
import { humanize } from '../../lib/utils';

export default {
  mixins: [Revisable],
  components: {
    ActionSelect,
  },
  props: {
    author: { type: Boolean, default: false },
    context: { type: String },
    postfix: { type: String },
    prefix: { type: String },
  },
  computed: {
    createdBy() {
      return this.$store.state.userModels[this.revisable.creatorId];
    },
    showAuthor() {
      return this.author && this.createdBy.username;
    },
    headerText() {
      return [this.prefix, humanize(this.revisableType), this.postfix].filter(label => !!label).join(' ');
    },
  },
  methods: {
    humanize,
    relativeTime,
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.revisable-header {
  > em {
    align-items: center;
    color: $c-gray-600;
    display: flex;
    font-size: font-size(-1);
    margin-bottom: spacing(1);
  }

  > strong {
    display: block;
    margin-bottom: spacing(1);
  }

  .divider {
    margin: 0 spacing(2);
  }
}

.action-select {
  margin-left: spacing(1);
}
</style>
