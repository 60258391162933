<template>
  <div class="app-status" :class="{ static }">
    <div v-if="notFoundError">
      <h1>Lost in the wild</h1>
      <p>Sorry, we couldn't find that one.</p>
    </div>
    <div v-else-if="notAuthorizedError">
      <h1>Arcane Denial</h1>
      <p>You require permission to access this content.</p>
    </div>
    <div v-else-if="otherError">
      <h1>Armageddon</h1>
      <p>Sorry, something went wrong. We'll be looking into it.</p>
    </div>
    <div v-else>
      <div class="sk-cube-grid" :class="{ overlay }">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
    </div>
  </div>
</template>

<script>
const statusImages = {
  '403': '/force_of_will_stewart.jpg',
  '404': '/evolving_wilds_rocha.jpg',
  '500': '/mizzium_mortars_bradley.jpg',
};

export default {
  props: {
    error: { type: String },
    static: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
  },
  computed: {
    notFoundError() {
      return /not found/i.test(this.error);
    },
    notAuthorizedError() {
      return /not authorized/i.test(this.error);
    },
    otherError() {
      return !!this.error;
    },
    statusCode() {
      if (this.notFoundError) return '404';
      if (this.notAuthorizedError) return '403';
      if (this.otherError) return '500';
      return null;
    }
  },
  methods: {
    setBg() {
      if (this.statusCode) {
        this.$store.dispatch('setBackground', {
          url: statusImages[this.statusCode],
          full: true,
        });
      }
    }
  },
  watch: {
    statusCode() {
      this.setBg();
    }
  },
  mounted() {
    this.setBg();
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.app-status {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: $header-height;
  right: 0;
  bottom: 0;

  &.static {
    position: static;
    height: calc(100vh - #{ $header-height });
    width: 100%;
  }
}

.sk-cube-grid {
  border-radius: $box-corner;
  height: 100px;
  margin: 100px auto;
  padding: spacing(2);
  width: 80px;

  &.overlay {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.sk-cube-grid .sk-cube {
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 1px;
  width: calc(33% - 4px);
  height: calc(33% - 4px);
  margin: 2px;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
</style>