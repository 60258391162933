window.dataLayer = window.dataLayer || [];
const GA_ID = 'G-XMVWH04BTD';

function gtag(){window.dataLayer.push(arguments);}

gtag('js', new Date());
gtag('config', GA_ID, {
  send_page_view: false,
  anonymize_ip: true,
  allow_google_signals: false, // No advertising or remarketing data
  allow_ad_personalization_signals: false, // No advertising or remarketing data
});

export function pageview() {
  requestAnimationFrame(() => {
    gtag('event', 'page_view', {
      page_title: document.title,
      page_location: location.pathname + location.search,
    });
  });
}